import { Autocomplete, Box, Card, Chip, Divider, FormControlLabel, IconButton, MenuItem, Switch, TablePagination, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { GroupFilters, GroupSearchResult, WhiteBlackListDisplay } from "src/@types/group";
import { OrganizationSearchResult } from "src/@types/organizations";
import InfiniteAutocomplete from "src/appComponents/InfiniteAutocomplete";
import { useLocales } from "src/locales";
import { groupOperations } from "src/redux/group";
import { dispatch, RootState } from "src/redux/store";
import { usersOperations } from "src/redux/users";
import { WhiteBlackList } from "../../group/newEditGroup/WhiteBlacklistStep";
import useTable from "src/appHooks/useTable";
import useResponsive from "src/hooks/useResponsive";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DeleteForever } from "@mui/icons-material";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { BlackWhiteList } from "src/@types/webshop";
import { UserSearchResult } from "src/@types/user";
import { GreyCounter, WebshopStepButtons } from "./WebshopUtilComponents";

interface CustomerStepProps {
    changeStep: Dispatch<SetStateAction<number>>
    onSubmit: (lists: {
        groupList: GroupSearchResult[],
        blackList: BlackWhiteList[],
        whiteList: BlackWhiteList[]
    }, showModal?: boolean) => void,
    groupList: GroupSearchResult[],
    blackWhiteLists: {
        white: BlackWhiteList[],
        black: BlackWhiteList[]
    }
}

const debounceGroupSearch = debounce((options: GroupFilters) => dispatch(groupOperations.searchGroups(options)), 500);

export default function CustomerStep({ changeStep, onSubmit, groupList: list, blackWhiteLists }: CustomerStepProps) {
    const { translate } = useLocales();

    const { groupsList, isLoading } = useSelector((state: RootState) => state.group);

    const { isShopCreatingLoading } = useSelector((state: RootState) => state.webshop);

    const [allElegibles, setAllElegibles] = useState(false);

    const [groups, setGroups] = useState<GroupSearchResult[]>(list);

    const [whiteBlackLists, setWhiteBlackLists] = useState(blackWhiteLists);

    useEffect(() => {
        searchGroups("");
        dispatch(usersOperations.searchUsers({ pageIndex: 0, pageSize: 5, onlyEnabled: true }));
    }, []);

    const searchGroups = (searchParam: string) => {

        let options: GroupFilters = {
            pageIndex: 0,
            pageSize: 5,
            all: searchParam,
            onlyEnabled: true
        };

        if (searchParam.length >= 3 || searchParam.length === 0)
            debounceGroupSearch(options);
    };

    const handleSubmit = (showModal?: boolean) => {
        onSubmit({
            groupList: groups,
            blackList: whiteBlackLists.black,
            whiteList: whiteBlackLists.white
        }, showModal);
    };

    const filterOptions = (options: GroupSearchResult[]): GroupSearchResult[] => {
        const ids = groups.map(v => v.groupId);

        return options.filter(v => !ids.includes(v.groupId));
    };

    const handleWhiteBlackList = (list: BlackWhiteList[], isWhite: boolean) => {

        if (isWhite)
            setWhiteBlackLists(p => ({ black: p.black, white: list }));

        else
            setWhiteBlackLists(p => ({ black: list, white: p.white }));

    };

    return (
        <Box >
            <Card sx={{ p: 3, display: 'flex', gap: 2, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', mb: { xs: 2, md: 0 } }}>
                    <Typography variant="h6" >{translate("webshop.form.eligibleCustomer")}</Typography>
                    <FormControlLabel
                        label={translate("webshop.form.allEligible")}
                        onChange={(_, check) => setAllElegibles(check)}
                        control={<Switch checked={allElegibles} />}
                    />
                </Box>
                {!allElegibles &&
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                        <Typography variant="subtitle2">{translate("webshop.form.selectCategory")}</Typography>
                        <InfiniteAutocomplete<GroupSearchResult>
                            searchCallBack={(params) => dispatch(groupOperations.searchGroups({ ...params, onlyEnabled: true })).unwrap()}
                            loading={isLoading}
                            isOptionEqualToValue={(option, value) => option.groupId === value.groupId}
                            startingValues={groupsList}
                            getOptionLabel={(option) => (typeof option !== 'string' && option.displayName) || ""}
                            filterOptions={(options) => filterOptions(options)}
                            onChange={(_, v) => {
                                if (v && typeof v !== "string")
                                    setGroups(prev => prev.concat([v]));
                            }}
                            renderOption={((props, option) => (
                                <Box {...props} component={'li'} key={option.groupId} >
                                    <Typography sx={{ mr: 2 }}>{option.displayName}</Typography>
                                    <Chip label={option.numberOfOrganizations + " " + translate('commons.customer_plural')} />
                                </Box>
                            ))}

                            renderInput={(params) =>
                                <TextField
                                    label={translate("user.form.searchIdName")}
                                    {...params}
                                    InputProps={{ ...params.InputProps }}

                                />}
                        />
                        {!groups.length ?
                            <GreyCounter label={translate("groups.messages.noSelected")} />
                            :
                            <GroupList list={groups} totalCount={groups.length} setList={(list) => setGroups(list)} />
                        }
                        <Divider sx={{ my: 3 }} />

                        <Typography variant="subtitle2">{translate("groups.form.selectBlacklist")}</Typography>

                        <WhitelistBlacklist list={whiteBlackLists.black} setList={(l) => handleWhiteBlackList(l, false)} />
                        <Divider sx={{ my: 3 }} />

                        <Typography variant="subtitle2">{translate("groups.form.selectWhitelist")}</Typography>
                        <WhitelistBlacklist list={whiteBlackLists.white} setList={(l) => handleWhiteBlackList(l, true)} />
                    </Box>}
                <Divider sx={{ my: 3 }} />
                <WebshopStepButtons changeStep={changeStep} onSave={handleSubmit} loading={isShopCreatingLoading} save />
            </Card>

        </Box>
    );
}

interface WhitelistBlacklistProps {
    list: BlackWhiteList[]
    setList: (list: BlackWhiteList[]) => void
}

function WhitelistBlacklist({ list, setList }: WhitelistBlacklistProps) {

    const { translate } = useLocales();

    const [blackWhiteList, setBlackWhiteList] = useState<WhiteBlackListDisplay>(() => {
        return {
            organizations: list.filter(item => item.entityType === "Organization").map((v) => ({
                id: v.entityId,
                name: v.name
            })) as any,
            users: list.filter(item => item.entityType === "User").map((v) => ({
                id: v.entityId,
                username: v.name
            })) as any,
        };
    });

    const [selectSearch, setSelectSearch] = useState<"Users" | "Organizations">("Users");

    const { userList, isLoading: userLoading } = useSelector((state: RootState) => state.user);

    const handleLists = (list: WhiteBlackListDisplay) => {

        const l: BlackWhiteList[] = list.users.map((item) => ({
            entityId: item.id,
            entityType: 'User',
            name: (item as UserSearchResult).username
        }));

        l.push(...list.organizations.map((item) => ({
            entityId: item.id,
            entityType: 'Organization',
            name: item.name
        })) as BlackWhiteList[]);

        setList(l);
    };

    return (
        <Box>
            <Box sx={{ justifyContent: 'center', display: 'flex', pb: 0, my: 3, gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <TextField
                    sx={{ width: '30%' }}
                    label={translate('commons.search')}
                    select
                    value={selectSearch}
                    onChange={(e) => setSelectSearch(e.target.value as ("Users" | "Organizations"))}
                >
                    <MenuItem value="Users">
                        {translate('commons.users')}
                    </MenuItem>
                    <MenuItem value="Organizations">
                        {translate('commons.organizations')}
                    </MenuItem>
                </TextField>
                {selectSearch === "Users" &&
                    <Autocomplete
                        fullWidth
                        id="userSelection"
                        freeSolo
                        filterOptions={(options) => options}
                        disabled={blackWhiteList.users.length >= 100}
                        loading={userLoading}
                        options={userList}
                        onChange={(_, v) => {
                            if (v && typeof v !== 'string' && !blackWhiteList.users.find(el => el.id === v.id))
                                setBlackWhiteList(p => {
                                    const list = { ...p, users: p.users.concat([v]) };

                                    handleLists(list);

                                    return list;
                                });
                            /*   setBlackWhiteList(p => {
                          return { ...p, users: p.users.concat([v]) }})); */
                        }}
                        getOptionLabel={(option) => typeof option !== "string" ? option.firstName + " " + option.lastName : ""}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                <Typography sx={{ mr: 2 }}>{option.firstName + " " + option.lastName}</Typography>
                                <Chip label={option.username} />
                            </li>
                        }
                        renderInput={(params) => (<TextField
                            {...params}
                            label={`${translate('user.form.searchIdName')}`}
                            helperText={blackWhiteList.users.length >= 100 && translate('groups.messages.maxNumber')}
                        />)}
                    />}
                {selectSearch === "Organizations" &&
                    <Autocomplete
                        fullWidth
                        id="organizationSelection"
                        freeSolo
                        disabled={blackWhiteList.organizations.length >= 100}

                        filterOptions={(options) => options}
                        options={[] as OrganizationSearchResult[]}
                        onChange={(_, v) => {
                            if (v && typeof v !== 'string' && !blackWhiteList.organizations.find(el => el.id === v.id))
                                setBlackWhiteList(p => {
                                    const list = { ...p, organizations: p.organizations.concat([v]) };

                                    handleLists(list);

                                    return list;
                                });
                            // setBlackWhiteList(p => ({ ...p, organizations: p.organizations.concat([v]) }));
                        }}
                        getOptionLabel={(option) => typeof option !== "string" ? option.name : ""}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                <Typography sx={{ mr: 2 }}>{option.externalId ? option.externalId + " - " : ""}{option.name}</Typography>
                                <Chip label={option.type} />
                            </li>
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                label={`${translate('user.form.searchIdName')}`}
                                helperText={blackWhiteList.organizations.length >= 100 && translate('groups.messages.maxNumber')}
                            />)}
                    />}
            </Box>
            <Box sx={{ mb: 3 }}>
                {["Users", "Organizations"].map((type) => {

                    const list = type === "Users" ? blackWhiteList.users : blackWhiteList.organizations;

                    if (selectSearch === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={selectSearch} list={blackWhiteList} setList={(v) => {
                                        setBlackWhiteList(v);
                                        handleLists(v);
                                    }} />
                                }
                            </Box>

                        );
                })}
            </Box>
        </Box>
    );
}

interface GroupListProps {
    list: GroupSearchResult[]
    setList?: (list: GroupSearchResult[]) => void,
    isLoading?: boolean,
    totalCount: number
}

function GroupList({ list, setList, isLoading, totalCount }: GroupListProps) {

    const { translate } = useLocales();

    const { dense, onChangeDense, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable();

    const isDesktop = useResponsive('up', 'lg');

    const handleDelete = useCallback((id: string) => {

        if (setList)
            setList(list.filter(el => el.groupId !== id));
    }, [list, setList]);

    const COLUMNS: GridColDef<GroupSearchResult>[] = useMemo(() => [
        {
            field: 'displayName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'groupType',
            headerName: `${translate('groups.list.columns.groupType')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            valueGetter: (_, param) => param.groupType === "None" ? "—" : translate('groups.list.type.' + param.groupType)
        },
        {
            field: 'numberOfOrganizations',
            headerName: `${translate('groups.list.columns.numberOfOrganizations')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        {
            field: 'numberOfUsers',
            headerName: `${translate('groups.list.columns.numberOfUsers')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [handleDelete, isDesktop, setList, translate]);

    const getHeight = () => {
        let height: string | number = "auto";

        const { length } = list;

        if (!totalCount) return 120 + 56 * length;

        if (!dense || length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        if (!totalCount) return "auto";

        return isDesktop ? 650 : 700;
    };

    return (
        <DataGrid
            rows={list}
            columns={COLUMNS}
            pagination
            getRowId={(row) => row.groupId}
            paginationMode="client"
            paginationModel={{
                page: page,
                pageSize: rowsPerPage
            }}
            disableColumnResize
            density={dense ? 'compact' : 'standard'}
            disableColumnMenu
            pageSizeOptions={[5, 10, 15, 30]}
            disableRowSelectionOnClick
            loading={isLoading}
            slots={{
                noRowsOverlay: noData,
                footer: () => (
                    <Box sx={{
                        position: 'relative',
                        width: { xs: "90vw", md: "auto" },
                    }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={totalCount ?? list.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                            sx={{
                                overflow: "hidden",
                                "& .MuiTablePagination-input": {
                                    ml: { xs: 0.5, md: "default" },
                                    mr: { xs: 3.5, md: "default" }
                                }
                            }}
                        />
                        <FormControlLabel
                            control={<Switch
                                checked={dense}
                                onChange={onChangeDense}
                            />}
                            label={`${translate('commons.dense')}`}
                            sx={{
                                py: { xs: 0, sm: 1.5 },
                                pb: { xs: 1.5, sm: 0 },
                                mx: 0,
                                top: 0,
                                justifyContent: "center",
                                width: { xs: "90vw", sm: "auto" },
                                position: { sm: 'absolute' }
                            }}
                        />
                    </Box>
                )
            }}
            sx={{
                ...DataGridStyle,
                cursor: "default",
                height: getHeight(),
                maxHeight: getMaxHeight()
            }}
        />
    );
}
