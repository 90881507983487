import { Box, Button, Card, Container, MenuItem, Modal, TextField, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import { isEqual, omit } from 'lodash';
import { SidebarListFilters } from 'src/@types/list';
import Iconify from 'src/components/iconify';
import { DEFAULT_DISCOUNT_MATRIX_FILTERS, DiscountMatrixFilters, DiscountMatrixSearchResult, EnableDisableDiscountMatrixProps } from 'src/@types/discountMatrix';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { setFiltersInUrl } from 'src/redux/discountMatrix/discountMatrix-slices';
import { discountMatrixOperations } from 'src/redux/discountMatrix';
import DateZone from 'src/appComponents/DateZone';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';
import { convertArrayToSelectOptions } from 'src/utils/list/utils/functions';

export default function DiscountMatrixList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, discountMatrixList, totalCount, filtersInUrl } = useSelector((state: RootState) => state.discountMatrix);

    const extraSearchFiltersChecks = useCallback((searchFilters: DiscountMatrixFilters) => {

        switch (searchFilters.statusSidebar) {
            case "all":
                searchFilters.enabled = undefined;
                break;
            case "Enabled":
                searchFilters.enabled = true;
                break;
            case "Disabled":
                searchFilters.enabled = false;
                break;
        };

        return searchFilters;
    }, []);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: DiscountMatrixFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_DISCOUNT_MATRIX_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: DiscountMatrixFilters, check: boolean }) => {
        const cleanedFilters: DiscountMatrixFilters = omit(filters.filters, "statusSidebar") as DiscountMatrixFilters;

        filters.filters = cleanedFilters;

        dispatch(discountMatrixOperations.searchDiscountMatrixes(filters));
    }, []);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "name",
            label: translate(`commons.name`),
            type: "TextField"
        },
        {
            name: "statusSidebar",
            label: translate('commons.status'),
            type: "Select",
            options: convertArrayToSelectOptions(["Enabled", "Disabled"], translate, "statuses.")
        }
    ], [translate]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const [openRename, setOpenRename] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.discountMatrix.detail(params.row.id));
        }
    };

    const handleEnableDisable = useCallback(async (id: string, action: "enable" | "disable") => {

        const options: EnableDisableDiscountMatrixProps = {
            id: id,
            action: action
        };

        let x = await dispatch(discountMatrixOperations.enableDisableDiscountMatrix(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<DiscountMatrixSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: true,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'enabled',
            headerName: translate('commons.enabled'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? translate("commons.enabled") : translate("commons.disabled")}
                    </Label>
                );
            }
        },
        {
            field: 'isDefault',
            headerName: translate('termsCondition.form.default'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={'eva:checkmark-fill'}
                        sx={{
                            width: 25,
                            height: 25,
                            color: 'success.main',
                            ...(!obj.row.default && { color: (theme) => theme.palette.grey[300] })
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisable}
                        setOpenRename={setOpenRename}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, handleEnableDisable]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.discountMatrix.title`)}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <RenameModal discountMatrix={actualRow?.row ?? null} isOpen={openRename} setIsOpen={(v) => setOpenRename(v)} />
                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.discountMatrix.title`)}`}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate(`discountMatrix.title`) }
                    ]}
                    action={
                        <Button
                            variant={"contained"}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                            onClick={() => navigate(PATH_DASHBOARD.discountMatrix.new)}
                            sx={{ borderRadius: "100px" }}
                        >
                            {translate('discountMatrix.upload')}
                        </Button>
                    }
                />

                <InfiniteScrollGenericList
                    list={discountMatrixList.map((matrix) => ({ ...matrix, id: matrix.discountMatrixId }))}
                    isLoading={isLoading}
                    totalCount={totalCount}
                    defaultFilters={DEFAULT_DISCOUNT_MATRIX_FILTERS}
                    filtersInSidebar={filtersInSidebar}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    context={"DiscountMatrix"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    customSearchFunc={customSearch}
                    filtersInUrl={filtersInUrl}
                    setFiltersInUrl={setFiltersInUrl}
                    listDescription={translate('discountMatrix.subtitle')}
                    resetList={() => { }}
                />

            </Container>
        </Page >
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: GridRenderCellParams<DiscountMatrixSearchResult, any, any, GridTreeNodeWithRender>,
    currentRow: any,
    handleEnableDisable: (id: string, action: "enable" | "disable") => Promise<void>,
    setOpenRename: (v: boolean) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable, setOpenRename }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && (object.id as string) === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (object.row.enabled) handleEnableDisable(object.id as string, "disable");
                                else handleEnableDisable(object.id as string, "enable");

                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                    <MenuItem
                        onClick={() => {
                            setOpenRename(true);
                        }}
                    >
                        {translate('discountMatrix.rename')}
                    </MenuItem>
                </>
            }
        />
    );
}

interface RenameModalProps {
    isOpen: boolean,
    setIsOpen: (v: boolean) => void,
    discountMatrix: DiscountMatrixSearchResult | null
}

function RenameModal({ discountMatrix, isOpen, setIsOpen }: RenameModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [name, setName] = useState(discountMatrix?.name ?? "");

    useEffect(() => {
        if (discountMatrix)
            setName(discountMatrix?.name);
    }, [discountMatrix]);

    const handleSubmit = async () => {
        if (discountMatrix) {
            await dispatch(discountMatrixOperations.renameDiscountMatrix({ id: discountMatrix.discountMatrixId, name })).unwrap();

            dispatch(setSuccessMessage({ text: translate('discountMatrix.messages.renameSuccess') }));
        }
    };

    return (
        <Modal
            open={isOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => setIsOpen(false)}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                minWidth: "25vw",
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography variant="h6" textAlign={'center'}>{translate('discountMatrix.rename')}</Typography>
                    <Typography variant="body1" textAlign={'center'}>{translate('discountMatrix.messages.renameMsg')}</Typography>
                </Box>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ my: 2, }}
                    required
                    fullWidth
                    variant="outlined"
                    autoFocus
                />
                <Box sx={{ mt: 2, justifyContent: 'space-between', ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'default' : 'column-reverse' }}>

                    <Button
                        variant={"outlined"}
                        color={"inherit"}
                        sx={{ borderRadius: '100px' }}
                        onClick={() => { setIsOpen(false); }}
                    >
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button
                        variant={"contained"}
                        disabled={!name}
                        color={"primary"}
                        sx={{ borderRadius: '100px' }}
                        onClick={() => { handleSubmit(); setIsOpen(false); }}
                    >
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}