import { Autocomplete, Box, Chip, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { Controller, UseFormReturn } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { OrganizationSearchResult, DEFAULT_PARENT, GenericOrganizationEditWithFather } from "src/@types/organizations";
import { useCallback, useEffect, useMemo, useState } from "react";
import AutocompleteField from "src/appComponents/AutocompleteField";
import RHFRadioGroup from "src/components/hook-form/RHFRadioGroup";
import { alpha } from '@mui/material/styles';
import LoadingScreen from "src/appComponents/loading-screen";
import { searchVendor } from "src/services/organizationsService";
import { typesOfOrganization } from "../Constants";
import GenericLocalizedInput from "src/appComponents/GenericLocalizedInput";
import { timezones } from "src/_mock/_timezones";
import CurrencySelect from "src/appComponents/CurrencySelect";

type Props = {
    outerRef: any,
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>,
    setOnEditForm: (value: boolean) => void,
    onEditForm: boolean,
    isEdit: boolean,
    setTagsFromForm: (value: any[]) => void,
    tagsFromForm: any[],
    setRegionsFromForm: (value: any[]) => void,
    regionsFromForm: any[],
    isDisabled: (name: string, other?: boolean, and?: boolean) => boolean
};

const typesOfStatus = ["Enabled", "Disabled", "Blocked"];

export default function OrganizationStep1({
    outerRef,
    formMethods,
    setOnEditForm,
    onEditForm,
    isEdit,
    setTagsFromForm,
    tagsFromForm,
    setRegionsFromForm,
    regionsFromForm,
    isDisabled
}: Props) {

    const { watch, getValues, trigger, setValue, control } = formMethods;

    const { translate } = useLocales();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            //clearErrors();

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };

    const [inEditTags, setInEditTags] = useState<boolean>(false);

    const organizationType = watch("type");

    const parentType = watch("parentType");

    const parent = watch("parent");

    const [organizations, setOrganizations] = useState<OrganizationSearchResult[]>([parent!]);

    const [isLoading, setIsLoading] = useState<boolean>(isEdit && organizations.length === 0); //CHECK

    const getCompanies = useCallback(async (name?: string) => {

        if (parentType) {
            if (isLoading) {
                setIsLoading(false);
            }

            try {
                const response = await searchVendor({
                    pageIndex: 0,
                    pageSize: 100,
                    sortField: "",
                    sortAscending: true,
                    type: parentType,
                    name: name
                });

                setOrganizations([...response.data.items, DEFAULT_PARENT]);
            } catch {
                if (parent) {
                    setOrganizations([parent, DEFAULT_PARENT]);
                } else {
                    setOrganizations([DEFAULT_PARENT]);
                }
            }
        } else if (organizationType === "Hub" && isLoading) {
            setIsLoading(false);
        }
    }, [isLoading, organizationType, parent, parentType]);

    useEffect(() => {
        getCompanies();
    }, [parentType, getCompanies]);

    const getParentType = useCallback((organizationType: String) => {

        switch (organizationType) {
            case "Company": {
                return ["Hub", "Holding"];
            }
            case "Branch": {
                return ["Company"];
            }
            case "Holding": {
                break;
            }
            case "Hub": {
                return ["Holding"];
            }
        }

        return [];
    }, []);

    const parentTypeList = useMemo(() => getParentType(organizationType), [organizationType, getParentType]);

    const handleChange = (organization: OrganizationSearchResult | string | null) => {

        if (typeof organization !== "string") {
            if (organization && organizations.find((value) => value.id === organization.id)) {
                setValue("parent", organization);
                setValue("parentId", organization.id);
            } else {
                setValue("parent", DEFAULT_PARENT);
                setValue("parentId", DEFAULT_PARENT.id);
            }
        }
    };

    const theme = useTheme();

    const getVisibility = (id: string) => {
        return id ? "block" : "none";
    };

    const isOptionEqualToVal = (option: OrganizationSearchResult, value: OrganizationSearchResult) => {
        return option.id === value.id;
    };

    const handleOnFocusParent = () => {
        setOnEditForm(true);
    };

    const handleOnBlurParent = () => {
        if (parent && parent.id === DEFAULT_PARENT.id) {
            setValue("parent", DEFAULT_PARENT);
            setValue("parentId", DEFAULT_PARENT.id);
        }

        onChangeValidate("parentId");
        setOnEditForm(false);
    };

    const customFieldsValues = watch("customFields");

    //-------------------------------------

    const note = watch("note");

    const [lang, setLang] = useState('en');

    return (
        <>
            {isLoading || (isEdit && !customFieldsValues) ? (
                <LoadingScreen />
            ) : (
                <Box sx={{ height: 500, overflowY: "scroll" }}>
                    <Typography variant="h5">
                        {`${translate('organization.form.steps.organizationInfo')}`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                        {`${translate('organization.form.description.organizationInfo')}`}
                    </Typography>

                    <RHFRadioGroup
                        name={"status"}
                        row
                        defaultValue={typesOfStatus[0]}
                        sx={{ mt: 2, mb: 3, ml: 1 }}
                        options={typesOfStatus.map((x) => { return { label: x, value: x }; })}
                        isDisabled={isDisabled}
                    />

                    <Box
                        sx={{ mb: 3, width: "98%" }}
                    >
                        <RHFTextField
                            name={"name"}
                            label={`${translate('organization.detail.organizationName')}`}
                            required
                            onChangeVal={onChangeValidate}
                            setOnEditForm={setOnEditForm}
                            onEditForm={onEditForm}
                            disabled={isDisabled("name")}
                        />

                    </Box>

                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            mb: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: '31.66% 31.67% 31.66%' } // repeat(3, 1fr)
                        }}
                    >
                        <RHFSelect
                            name={"type"}
                            label={`${translate('commons.type')}`}
                            required
                            onChangeVal={onChangeValidate}
                            setOnEditForm={setOnEditForm}
                            onEditForm={onEditForm}
                            disabled={isDisabled("type")}
                        >
                            {typesOfOrganization.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {`${translate(`organization.types.${option.toLowerCase()}`)}`}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFSelect
                            name={"parentType"}
                            label={`${translate('organization.detail.parentType')}`}
                            required={organizationType !== "Hub"}
                            onChangeVal={onChangeValidate}
                            setOnEditForm={setOnEditForm}
                            onEditForm={onEditForm}
                            disabled={isDisabled("parentId", organizationType === "" || organizationType === "Hub")}
                        >
                            {parentTypeList.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {`${translate(`organization.types.${option.toLowerCase()}`)}`}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <Controller
                            name={"parent"}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    {...field}

                                    onChange={(_, newValue) => handleChange(newValue)}
                                    onInputChange={(_, newValue) => getCompanies(newValue)}

                                    value={field.value}
                                    fullWidth
                                    freeSolo
                                    options={organizations}
                                    getOptionLabel={(option) => typeof option === "string" ? "" : option.name}
                                    disabled={isDisabled("parentId", parentType === "")}
                                    isOptionEqualToValue={(option, value) => isOptionEqualToVal(option, value)}

                                    renderOption={(props, option) =>
                                        <MenuItem {...props} key={option.id} style={{ display: getVisibility(option.id) }}>
                                            <Typography sx={{ pr: 1 }} >{option.name}</Typography>
                                            <Chip label={option.status} />
                                        </MenuItem>
                                    }

                                    onBlur={handleOnBlurParent}
                                    onFocus={handleOnFocusParent}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required={organizationType !== "Hub"}
                                            label={`${translate('organization.detail.parent')}`}
                                            sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: params.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                                        />
                                    )}
                                />
                            )
                            }
                        />

                    </Box>

                    <Box>

                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                mb: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: '48.2% 48.2%' },
                            }}
                        >
                            <RHFSelect
                                name="timeZoneId"
                                label={`${translate("commons.timezone")}`}
                                placeholder={`${translate("commons.timezone")}`}
                                onChangeVal={onChangeValidate}
                                setOnEditForm={setOnEditForm}
                                onEditForm={onEditForm}
                                required
                            >
                                {timezones.map((option) => (
                                    <MenuItem key={option.label} value={option.code}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>

                            <CurrencySelect
                                name='preferredCurrency'
                                onChangeVal={onChangeValidate}
                                setOnEditForm={setOnEditForm}
                                onEditForm={onEditForm}
                            />

                            <RHFTextField
                                name={"externalId"}
                                label={`${translate('commons.externalId')}`}
                                disabled={isDisabled("externalId")}
                            />

                            <RHFTextField
                                name={"vat"}
                                label={`${translate('organization.detail.vat')}`}
                                disabled={isDisabled("vat")}
                            />

                            <AutocompleteField
                                name={'tags'}
                                label={`${translate('commons.tags')}`}
                                context={'organizationTag'}
                                multiple={true}
                                freeSolo
                                setTagsFromForm={setTagsFromForm}
                                tagsFromForm={tagsFromForm}
                                onChangeVal={onChangeValidate}
                                setOnEditForm={setOnEditForm}
                                onEditForm={onEditForm}
                                inEditTags={inEditTags}
                                setInEditTags={setInEditTags}
                                disabled={isDisabled("tags")}
                            />

                            {(organizationType !== "Branch" && organizationType !== "Hub") &&
                                <AutocompleteField
                                    name={'regions'}
                                    label={`${translate('organization.detail.regions')}`}
                                    context={'organizationRegion'}
                                    multiple={true}
                                    freeSolo
                                    dependenciesNames={['country']}
                                    setTagsFromForm={setRegionsFromForm}
                                    tagsFromForm={regionsFromForm}
                                    onChangeVal={onChangeValidate}
                                    setOnEditForm={setOnEditForm}
                                    onEditForm={onEditForm}
                                    inEditTags={inEditTags}
                                    setInEditTags={setInEditTags}
                                    disabled={isDisabled("regions", !organizationType)}
                                />
                            }

                        </Box>

                        {/*                         {customFieldsProp && (
                            <CustomFieldFormRenderer
                                context={"organization"}
                                handleCustomFields={handleCustomFields}
                                values={customFieldsValues}
                                columnGap={2}
                                repeat={'31.66% 31.67% 31.66%'}
                                isDisabled={isDisabled}
                                section={["General"]}
                                onChangeValidate={onChangeValidate}
                                setOnEditForm={setOnEditForm}
                                onEditForm={onEditForm}
                                setErrorInCustom={setErrorInCustom}
                                setFieldsWithError={setFieldsWithError}
                            />
                        )} */}

                        <GenericLocalizedInput
                            name={'note'}
                            label={`${translate('commons.note')}`}
                            setValue={setValue}
                            tabs={note}
                            lang={lang}
                            onChangeLang={setLang}
                            disabled={isDisabled("note")}
                        />

                    </Box>
                </Box>
            )}
        </>
    );
}
