import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, MenuItem, Container, Typography, Tooltip } from '@mui/material';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTabs from 'src/appHooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { SparePartsFilters, SparePartSearchResult, DEFAULT_SPARE_PARTS_FILTERS } from 'src/@types/spareParts';
import { sparePartsOperations } from 'src/redux/spareParts';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import Iconify from 'src/components/iconify';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import useResponsive from 'src/hooks/useResponsive';
import { setFiltersInUrl } from 'src/redux/spareParts/spareParts-slices';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { SidebarListFilters, StatusFilters } from 'src/@types/list';
import sparePartsServices from 'src/services/sparePartsServices';
import { getPrice } from 'src/utils/currency';
import GenericList from 'src/utils/list/GenericList';
import { cloneDeep, isEqual } from 'lodash';
import { getSliderFilterNames } from 'src/utils/list/sidebar/SidebarSlider';
import { manageAmountFiltersBasic } from 'src/utils/list/utils/functions';

export default function SparePartsList() {

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const { isLoading, sparePartsList, totalCount, statistics, pageIndex, pageSize, filtersInUrl } = useSelector((state: RootState) => state.spareParts);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const statsKeysToRemove: string[] = ["onlyEnabled", "onlyDisabled", "onlyDeprecated"];

    //---- QUICKFILTERS START ----//
    const quickFilters: StatusFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'primary',
        },
        {
            key: 'enabled',
            label: `${translate('statuses.enabled')}`,
            color: 'success',
        },
        {
            key: 'disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'error',
        },
        {
            key: 'deprecated',
            label: `${translate('statuses.deprecated')}`,
            color: 'warning',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "enabled":
                    return statistics.enabled;
                case "disabled":
                    return statistics.disable;
                case "deprecated":
                    return statistics.deprecated;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "stockQuantity",
            label: `${translate('spareParts.list.filters.stockQuantity')}`,
            type: "InputRange"
        },
        {
            name: "reservationQuantity",
            label: `${translate('spareParts.list.filters.reservationQuantity')}`,
            type: "InputRange"
        },
        {
            name: "availableQuantity",
            label: `${translate('spareParts.list.filters.availableQuantity')}`,
            type: "InputRange"
        },
        {
            name: "returnQuantity",
            label: `${translate('spareParts.list.filters.returnQuantity')}`,
            type: "InputRange"
        },
        {
            name: "pendingReception",
            label: `${translate('spareParts.list.tableHeaders.pendingReception')}`,
            type: "InputRange"
        },
        {
            name: "tagsSection",
            label: `${translate("commons.tags")}`,
            type: "Section"
        },
        {
            name: "tags",
            label: `${translate("commons.tags")}`,
            type: "TagsAutocomplete",
            tagsService: sparePartsServices.searchTags
        }
    ], [translate]);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: SparePartsFilters) =>
        ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number") && field !== "customer")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SPARE_PARTS_FILTERS[field])
        , []);

    //---- EXTRA FUNCTION TO USE BEFORE SEARCH ----//
    const extraSearchFiltersChecks = useCallback((searchFilters: SparePartsFilters) => {

        switch (filterStatus) {
            case "enabled":
                searchFilters.onlyEnabled = true;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = undefined;
                break;
            case "disabled":
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = true;
                searchFilters.onlyDeprecated = undefined;
                break;
            case "deprecated":
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = true;
                break;
            default:
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = undefined;
        }

        return searchFilters;
    }, [filterStatus]);

    //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
    const updateInUrlFiltersChecks = useCallback((filters: SparePartsFilters) => {

        let newFilters = cloneDeep(filters);

        filtersInSidebar.forEach((filter) => {
            if (filter.type === "InputRange") {
                const [minName, maxName] = getSliderFilterNames(filter.name);

                if (filters[minName] && filters[maxName]) newFilters = manageAmountFiltersBasic(minName, maxName, newFilters);
            }
        });

        return newFilters;
    }, [filtersInSidebar]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const getStatusColor = useCallback((status: boolean): LabelColor => {
        let statusColor = "default";

        const check = status ? "enabled" : "disabled";

        quickFilters.forEach(object => {
            if (object.key.toLowerCase() === check) {
                statusColor = object.color;
            }
        });

        return statusColor as LabelColor;
    }, [quickFilters]);

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.spareParts.detail(params.id.toString()));
        }
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.spareParts.logs(id));
    }, [navigate]);

    const COLUMNS: GridColDef<SparePartSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: `${translate('spareParts.list.tableHeaders.partNr')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'name',
            headerName: `${translate('spareParts.list.tableHeaders.partDesc')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'customFields.stellantis-europe-spareparts-deposit-number',
            headerName: translate('spareParts.list.tableHeaders.depositRef'),
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            valueGetter: (_, row) => row.customFields["stellantis-europe-spareparts-deposit-number"] ?? "—"
        },
        {
            field: 'family',
            headerName: `${translate('spareParts.list.tableHeaders.supplierFamily')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '97%' }}>
                    <Typography variant='subtitle2'>
                        <b>{obj.row.family.code}</b>
                    </Typography>
                    <Tooltip title={obj.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {obj.row.family.name}
                        </Typography>
                    </Tooltip>
                </Box>
        },
        {
            field: 'sellAmount',
            headerName: `${translate('spareParts.list.tableHeaders.sellingPrice')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.sellAmount)}
                        currency={obj.row.sellAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.sellAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'stockQuantity',
            headerName: `${translate('spareParts.list.tableHeaders.stockQuantity')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center"
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.enabled)}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {`${translate(`statuses.${obj.row.enabled ? "enabled" : "disabled"}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'deprecated',
            headerName: `${translate('spareParts.list.tableHeaders.deprecated')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: 'center',
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={'eva:checkmark-fill'}
                        sx={{
                            width: 25,
                            height: 25,
                            color: 'success.main',
                            ...(!obj.row.deprecated && { color: (theme) => theme.palette.grey[300] })
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.3 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        object={obj}
                        currentRow={actualRow}
                    />
                );
            }
        }
    ], [actualRow, getStatusColor, handleLogs, isDesktop, openMenu, translate]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate('menu.management.spareParts.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.spareParts.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.spareParts.list')}` }
                    ]} />

                <GenericList
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    list={sparePartsList}
                    isLoading={isLoading}
                    defaultFilters={DEFAULT_SPARE_PARTS_FILTERS}
                    specificStatsKeysToDelete={statsKeysToRemove}
                    filtersInSidebar={filtersInSidebar}
                    toolbarFiltersList={[]}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    context={"SpareParts"}
                    quickFilters={quickFilters}
                    renderQuickFilters={renderQuickFilters}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    filterStatus={filterStatus}
                    onChangeFilterStatus={onChangeFilterStatus}
                    search={sparePartsOperations.searchSpareParts}
                    searchStatistics={sparePartsOperations.searchStatistics}
                    filtersInUrl={filtersInUrl}
                    setFiltersInUrl={setFiltersInUrl}
                    listDescription={translate("spareParts.detail.description")}
                    updateInUrlFiltersChecks={updateInUrlFiltersChecks}
                    showVatAdvice
                />

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string) => void,
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SpareParts_View]}>
                    <MenuItem
                        onClick={() => {
                            handleLogs(object.id);
                            handleCloseMenu();
                        }}
                    >
                        {`${translate("commons.logs")}`}
                    </MenuItem>
                </PermissionBasedGuard>
            }
        />
    );
}