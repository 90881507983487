import { useSelector } from 'react-redux';
import { dispatch, RootState } from '../redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { matrixOperations } from '../redux/matrix';
import usersOperations from '../redux/users/users-operations';
import vehicleOperations from '../redux/vehicle/vehicle-operations';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { organizationsOperations } from 'src/redux/organizations';
import { sparePartsOperations } from 'src/redux/spareParts';
import { hasLicense } from 'src/utils/tenant';
import useTenant from 'src/appHooks/useTenant';
import { LicenseTypes } from 'src/@types/tenant';

export type MatrixResult = {
  id: string;
  group: string;
  path: string;
  status?: string;
  title?: string;
  organizationName?: string;
  externalId?: string
  firstname?: string;
  lastname?: string;
  enabled?: string;
  brand?: string;
  version?: string;
  plate?: string;
  partsExternalId?: string;
  sparePartName?: string;
  orderName?: string;
};

export default function usePowerSeaarch() {
  const {
    powersearchMatrixResults,
    powersearchMatrixTemplateResults,
    isTemplatePowersearchLoading,
    isMatrixPowersearchLoading,
  } = useSelector((state: RootState) => state.matrix);

  const { usersPowersearch, isUserPowersearchLoading } = useSelector(
    (state: RootState) => state.user
  );

  const { vehiclesPowersearch, isVehiclePowersearchLoading } = useSelector(
    (state: RootState) => state.vehicle
  );

  const { vendorOrganizationsPowersearch, isMyOrganizationPowersearchLoading,
    isCustomerOrganizationPowersearchLoading, customerOrganizationsPowersearch } = useSelector((state: RootState) => state.organizations);

  const { sparePartsPowerSearch, isMySparePartPowersearchLoading, isCustomerSparePartPowersearchLoading, customerSparePartsPowerSearch } = useSelector((state: RootState) => state.spareParts);

  const { orderPowersearch, isOrderPowersearchLoading } = useSelector((state: RootState) => state.orders);

  const [items, setItems] = useState<MatrixResult[]>([]);

  const tenant = useTenant();

  const getIsLoading = useCallback(() => {
    return !![
      isTemplatePowersearchLoading,
      isMatrixPowersearchLoading,
      isUserPowersearchLoading,
      isVehiclePowersearchLoading,
      isMyOrganizationPowersearchLoading,
      isCustomerOrganizationPowersearchLoading,
      isCustomerSparePartPowersearchLoading,
      isMySparePartPowersearchLoading,
      isOrderPowersearchLoading
    ].filter((status) => status).length as boolean;
  }, [
    isTemplatePowersearchLoading,
    isMatrixPowersearchLoading,
    isUserPowersearchLoading,
    isVehiclePowersearchLoading,
    isMyOrganizationPowersearchLoading,
    isCustomerOrganizationPowersearchLoading,
    isCustomerSparePartPowersearchLoading,
    isMySparePartPowersearchLoading,
    isOrderPowersearchLoading
  ]);

  // Set matrix's
  useEffect(() => {
    if (powersearchMatrixResults && powersearchMatrixResults?.items) {
      const results = powersearchMatrixResults?.items?.map((matrix: any) => ({
        id: matrix?.id,
        group: 'matrix',
        title: matrix?.name,
        path: `/dashboard/rvMatrix/${matrix?.id}/view`,
        status: matrix?.status,
      }));

      setItems((prevState) => [...results, ...prevState.filter(({ group }) => group !== 'matrix')]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'matrix')]);
    }
  }, [powersearchMatrixResults]);

  // Set users
  useEffect(() => {
    if (usersPowersearch && usersPowersearch?.length) {
      const results = usersPowersearch?.map((user: any) => ({
        id: user?.id,
        group: 'user',
        firstname: user?.firstname,
        lastname: user?.lastname,
        path: `/dashboard/user/${user?.id}/detail/profile`,
      }));

      setItems((prevState) => [...results, ...prevState.filter(({ group }) => group !== 'user')]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'user')]);
    }
  }, [usersPowersearch]);

  // Set matrix's templates
  useEffect(() => {
    if (powersearchMatrixTemplateResults && powersearchMatrixTemplateResults?.items?.length) {
      const results = powersearchMatrixTemplateResults?.items?.map((template: any) => ({
        id: template?.id,
        group: 'template',
        title: template?.name,
        path: `/dashboard/matrix/${template?.id}/edit`,
        status: template?.status || template.desctiption,
        enabled: template?.enabled ? 'Enabled' : 'Disabled',
      }));

      setItems((prevState) => [
        ...results,
        ...prevState.filter(({ group }) => group !== 'template'),
      ]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'template')]);
    }
  }, [powersearchMatrixTemplateResults]);

  //set Organizations
  useEffect(() => {
    if (vendorOrganizationsPowersearch && vendorOrganizationsPowersearch?.length) {
      const results = vendorOrganizationsPowersearch.map((org) => ({
        id: org.id,
        group: 'myOrganization',
        organizationName: org.name,
        externalId: org.externalId,
        path: PATH_DASHBOARD.organizations.customer.detail(org.id, org.type)
      }));

      setItems((prevState) => [...results, ...prevState.filter(({ group }) => group !== 'myOrganization')]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'myOrganization')]);
    }
  }, [vendorOrganizationsPowersearch]);

  useEffect(() => {
    if (customerOrganizationsPowersearch && customerOrganizationsPowersearch?.length) {
      const results = customerOrganizationsPowersearch.map((org) => ({
        id: org.id,
        group: 'customerOrg',
        organizationName: org.name,
        externalId: org.externalId,
        path: PATH_DASHBOARD.organizations.customer.detail(org.id, org.type)
      }));

      setItems((prevState) => [...results, ...prevState.filter(({ group }) => group !== 'customerOrg')]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'customerOrg')]);
    }
  }, [customerOrganizationsPowersearch]);

  useEffect(() => {
    if (vehiclesPowersearch && vehiclesPowersearch?.length) {
      const results = vehiclesPowersearch?.map((vehicle: any) => ({
        id: vehicle?.id,
        group: 'vehicle',
        title: vehicle?.name,
        brand: vehicle?.brand,
        version: vehicle?.version,
        plate: vehicle?.plate,
        path: `/dashboard/vehicle/${vehicle?.id}/detail`,
      }));

      setItems((prevState) => [
        ...results,
        ...prevState.filter(({ group }) => group !== 'vehicle'),
      ]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'vehicle')]);
    }
  }, [vehiclesPowersearch]);

  //set SpareParts
  useEffect(() => {
    if (sparePartsPowerSearch && sparePartsPowerSearch.length) {
      const results = sparePartsPowerSearch.map((part) => ({
        id: part.id,
        group: 'mySpareParts',
        sparePartName: part.name,
        partsExternalId: part.externalId,
        path: PATH_DASHBOARD.spareParts.detail(part.id)
      }));

      setItems((prevState) => [
        ...results,
        ...prevState.filter(({ group }) => group !== 'mySpareParts')
      ]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'mySpareParts')]);
    }
  }, [sparePartsPowerSearch]);

  useEffect(() => {
    if (customerSparePartsPowerSearch && customerSparePartsPowerSearch.length) {
      const results = customerSparePartsPowerSearch.map((part) => ({
        id: part.id,
        group: 'customerSpareParts',
        sparePartName: part.name,
        partsExternalId: part.externalId,
        path: PATH_DASHBOARD.spareParts.detail(part.id)
      }));

      setItems((prevState) => [
        ...results,
        ...prevState.filter(({ group }) => group !== 'customerSpareParts')
      ]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'customerSpareParts')]);
    }
  }, [customerSparePartsPowerSearch]);

  //set Orders
  useEffect(() => {
    if (orderPowersearch && orderPowersearch.length) {
      const results = orderPowersearch.map((order) => ({
        id: order.id,
        group: 'orders',
        orderName: order.prettyName,
        path: PATH_DASHBOARD.orders.vendor.detail(order.id)
      }));

      setItems((prevState) => [
        ...results,
        ...prevState.filter(({ group }) => group !== 'orders')
      ]);
    } else {
      setItems((prevState) => [...prevState.filter(({ group }) => group !== 'orders')]);
    }
  }, [orderPowersearch]);

  const handleSearch = useMemo(() =>
    debounce((text) => {
      if (hasLicense(tenant, LicenseTypes.Rv)) {
        dispatch(
          matrixOperations.getPowesearchMatrixesTemplatesResults({
            pageIndex: 0,
            pageSize: 3,
            all: text,
          })
        );
        dispatch(
          matrixOperations.getPowesearchMatrixesResults({ pageIndex: 0, pageSize: 3, all: text })
        );
      }
      if (hasLicense(tenant, LicenseTypes.Core)) {
        dispatch(usersOperations.searchUsersPowersearch({ pageIndex: 0, pageSize: 3, all: text }));
        dispatch(organizationsOperations.searchVendorOrganizationsPowerSearch({ pageIndex: 0, pageSize: 3, all: text }));
        dispatch(organizationsOperations.searchCustomerOrganizationsPowerSearch({ pageIndex: 0, pageSize: 3, all: text }));
      }
      if (hasLicense(tenant, LicenseTypes.WebShop)) {
        // dispatch(ordersOperations.searchOrdersPowerSearch({ pageIndex: 0, pageSize: 3, prettyName: text }));
        dispatch(vehicleOperations.getVehiclesPowersearch({ pageIndex: 0, pageSize: 3, all: text }));
        dispatch(sparePartsOperations.sparePartsPowerSearch({ pageIndex: 0, pageSize: 3, all: text }));
      }
      if (hasLicense(tenant, LicenseTypes.ProductVehicle)) {
        dispatch(vehicleOperations.getVehiclesPowersearch({ pageIndex: 0, pageSize: 3, all: text }));
      }
      else if (hasLicense(tenant, LicenseTypes.ProductSparePart)) {
        dispatch(sparePartsOperations.sparePartsPowerSearch({ pageIndex: 0, pageSize: 3, all: text }));
      }
    }, 1000),
    [tenant]
  );

  return [items, handleSearch, getIsLoading()] as [MatrixResult[], any, boolean];
}
