import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { DEFAULT_DOCUMENTS_FILTERS, DocType, DocTypesArr, DocumentFilters, DocumentSearchResult } from 'src/@types/orders';
import { ordersOperations } from 'src/redux/orders';
import { isEqual } from 'lodash';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import useResponsive from 'src/hooks/useResponsive';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { getPrice } from 'src/utils/currency';
import DateZone from 'src/appComponents/DateZone';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Box, MenuItem, Typography } from '@mui/material';
import { setOrdersFiltersInUrl } from 'src/redux/orders/orders-slices';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { detailGeneric } from 'src/services/organizationsService';
import { fileThumb } from 'src/components/file-thumbnail';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { useSettingsContext } from 'src/components/settings';
import { InvoiceReportFilters } from 'src/@types/report';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { SidebarListFilters } from 'src/@types/list';
import { organizationsOperations } from 'src/redux/organizations';
import * as ordersServices from "src/services/ordersServices";
import { getFirstLowerCase } from 'src/appUtils/string';
import { convertArrayToSelectOptions, manageAmountFilters } from 'src/utils/list/utils/functions';

interface DocumentsListProps {
  isVendor: boolean
}

export default function DocumentsList({ isVendor }: DocumentsListProps) {

  const { translate } = useLocales();

  const { organizationId } = useUserOrganizationContext();

  const { currency: currentCurrency } = useSettingsContext();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const { documentList, isDocumentLoading, documentTotalCount, documentsFiltersInUrl } = useSelector((state: RootState) => state.orders);

  const [filters, setFilters] = useState<DocumentFilters>(DEFAULT_DOCUMENTS_FILTERS);

  const fullKeysToDelete: string[] = ["from", "to"];

  //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
  const updateCheckField = useCallback((field: string, filtersToCheck: DocumentFilters) =>
    ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number") && field !== "customer")
    &&
    !isEqual(filtersToCheck[field], DEFAULT_DOCUMENTS_FILTERS[field])
    , []);

  //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
  const updateInUrlFiltersChecks = useCallback((filters: DocumentFilters) => {
    if (filters.minAmount && filters.maxAmount) return manageAmountFilters("minAmount", "maxAmount", filters);

    return filters;
  }, []);

  //---- CUSTOM SEARCH FUNCTION ----//
  const customSearch = useCallback((filters: { filters: DocumentFilters & { sequenceToken: any }, check: boolean }) => {
    dispatch(ordersOperations.searchDocuments({ ...filters, isVendor: isVendor }));
  }, [isVendor]);

  //---- EXTRA FUNCTION IN SEARCH ----//
  const extraFunctionInSearch = useCallback(async (filters: DocumentFilters, getValues: any, setValue: any) => {
    if (filters.customerId && filters.customerId !== getValues("customer")?.id) {

      const { data } = await detailGeneric(filters.customerId);

      setValue("customer", data);
    }
  }, []);

  //---- SIDEBAR FILTERS ----//
  const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
    {
      name: "externalId",
      label: translate(`documents.filter.docRef`),
      type: "TextField"
    },
    {
      name: "documentType",
      label: translate('documents.filter.type'),
      type: "Select",
      options: convertArrayToSelectOptions(DocTypesArr.slice(1), translate, "documents.filter.")
    },
    ...(isVendor ?
      [{
        name: "",
        label: translate(`commons.customer`),
        type: "Section",
        permissions: [OrganizationPermissionTypes.Customer_View]
      },
      {
        name: "customer",
        label: translate(`commons.customer`),
        type: "Autocomplete",
        nameId: "customerId",
        service: organizationsOperations.searchCustomerOrganizations,
        permissions: [OrganizationPermissionTypes.Customer_View],
        renderOptionComponent: (props: any, option: any) => {
          return (
            <Box {...props} component={'li'} key={option.id} >
              <Typography sx={{ mr: 2 }}>{option.name}</Typography>
            </Box>
          );
        }
      }] : []) as SidebarListFilters[],
    {
      name: "amount",
      label: translate(`orders.tableHeaders.netAmount`),
      type: "InputRange"
    },
    {
      name: "",
      label: translate(`commons.tags`),
      type: "Section"
    },
    {
      name: "tags",
      label: `${translate("commons.tags")}`,
      type: "TagsAutocomplete",
      tagsService: ordersServices.searchDocumentsTags
    }
  ], [isVendor, translate]);

  //---- REPORT FILTERS ----//
  const reportFilters: InvoiceReportFilters = useMemo(() => ({
    all: filters.all || null,
    customFields: filters.customFields || null,
    externalId: filters.externalId || null,
    requestType: filters.requestType || null,
    customerId: filters.customerId || !isVendor ? organizationId : null,
    maxAmount: filters.maxAmount ? {
      withoutVat: filters.maxAmount,
      currentCurrency: currentCurrency.label
    } : null,
    minAmount: filters.minAmount ? {
      withoutVat: filters.minAmount,
      currentCurrency: currentCurrency.label
    } : null,
    vendorId: filters.organizationId || isVendor ? organizationId : null,
    tags: filters.tags || null
  }), [filters, currentCurrency, isVendor, organizationId]);

  //---- HANDLE TABLE START ----//
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const [actualRow, setActualRow] = useState<GridCellParams<DocumentSearchResult> | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleCellClick = (params: GridCellParams<DocumentSearchResult>) => {
    if (params.field !== 'options') {
      const { id, type } = params.row;

      isVendor ?
        navigate(PATH_DASHBOARD.orders.vendor.documents.detail(id, type)) :
        navigate(PATH_DASHBOARD.orders.customer.documents.detail(id, type));
    }
  };

  const handleLogs = useCallback((id: string, type: DocType) => {
    isVendor ?
      navigate(PATH_DASHBOARD.orders.vendor.documents.logs(id, type)) :
      navigate(PATH_DASHBOARD.orders.customer.documents.logs(id, type));
  }, [navigate, isVendor]);

  const COLUMNS: GridColDef<DocumentSearchResult>[] = [
    {
      field: 'externalId',
      headerName: translate('documents.tableHeaders.docReference'),
      flex: isDesktop ? 1.15 : undefined,
      minWidth: !isDesktop ? 185 : undefined,
      renderCell: (obj: any) => {
        return (
          <>
            <Box
              component="img"
              src={fileThumb(obj.row.type)}
              sx={{
                width: 32,
                height: 32,
                flexShrink: 0,
                mr: 1,
              }}
            />
            {obj.row.externalId}
          </>
        );
      }
    },
    {
      field: 'documentType',
      headerName: translate('documents.tableHeaders.docType'),
      flex: isDesktop ? 1.1 : undefined,
      minWidth: !isDesktop ? 150 : undefined,
      valueGetter: (_, param) => {
        return translate(`documents.filter.${getFirstLowerCase(param.type)}`);
      }
    },
    ...(isVendor ?
      [
        {
          field: 'customer.name',
          headerName: translate('documents.tableHeaders.customerName'),
          flex: isDesktop ? 1.5 : undefined,
          minWidth: !isDesktop ? 170 : undefined,
          renderCell: (obj) => {
            return (
              <Typography variant='body2' sx={{ wordWrap: 'break-word !important', whiteSpace: 'pre-line !important' }}>
                {obj.row.customerName}
              </Typography>
            );
          }
        }
      ] : [
        {
          field: 'vendor.name',
          headerName: translate('documents.tableHeaders.vendorName'),
          flex: isDesktop ? 1.5 : undefined,
          minWidth: !isDesktop ? 170 : undefined,
          renderCell: (obj) => {
            return (
              <Typography variant='body2' sx={{ wordWrap: 'break-word !important', whiteSpace: 'pre-line !important' }}>
                {obj.row.vendorName}
              </Typography>
            );
          }
        },
      ]) as GridColDef<DocumentSearchResult>[],
    {
      field: 'createdOn',
      headerName: translate('documents.tableHeaders.docDate'),
      flex: isDesktop ? 1.2 : undefined,
      minWidth: !isDesktop ? 180 : undefined,
      renderCell: (obj: any) => {
        return <DateZone date={obj.row.createdOn} noSeconds shortMonth variant={'body2'} />;
      }
    },
    {
      field: 'grossAmount.withoutVat',
      headerName: translate('orders.tableHeaders.grossAmount'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.grossAmount)}
            currency={obj.row.grossAmount.currentCurrency}
            noVatAsterisk={!!obj.row.grossAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );
      }
    },
    {
      field: 'totalDiscountAmount.withoutVat',
      headerName: translate('orders.tableHeaders.discountAmount'),
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.totalDiscountAmount)}
            currency={obj.row.totalDiscountAmount.currentCurrency}
            noVatAsterisk={!!obj.row.totalDiscountAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );

      }
    },
    {
      field: 'netAmount.withoutVat',
      headerName: `${translate('orders.tableHeaders.netAmount')}`,
      flex: isDesktop ? 1 : undefined,
      minWidth: !isDesktop ? 130 : undefined,
      renderCell: (obj) => {
        return (
          <CurrencyAdapter
            value={getPrice(obj.row.netAmount)}
            currency={obj.row.netAmount.currentCurrency}
            noVatAsterisk={!!obj.row.netAmount.withoutVat}
            fontSize={'0.875rem'}
          />
        );
      }
    },
    {
      field: 'options',
      headerName: ``,
      flex: isDesktop ? 0.2 : undefined,
      maxWidth: !isDesktop ? 50 : undefined,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (obj) => {
        return (
          <OptionsComponent
            openMenu={openMenu}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            handleLogs={handleLogs}
            object={obj}
            currentRow={actualRow}
          />
        );
      }
    }
  ];
  //---- HANDLE TABLE END ----//

  return (
    <InfiniteScrollGenericList
      list={documentList}
      isLoading={isDocumentLoading}
      totalCount={documentTotalCount}
      defaultFilters={DEFAULT_DOCUMENTS_FILTERS}
      specificFullKeysToDelete={fullKeysToDelete}
      filtersInSidebar={filtersInSidebar}
      datagridColumns={COLUMNS}
      updateCheckField={updateCheckField}
      updateInUrlFiltersChecks={updateInUrlFiltersChecks}
      context={'document'}
      customfieldContext={["invoice", "deliverynote", "creditnote"]}
      setActualRow={setActualRow}
      handleCellClick={handleCellClick}
      setFiltersCallback={setFilters}
      resetList={() => { }}
      customSearchFunc={customSearch}
      filtersInUrl={documentsFiltersInUrl}
      setFiltersInUrl={setOrdersFiltersInUrl}
      listDescription={translate('documents.messages.subtitle')}
      datesGeneralFilter={{
        showDates: true
      }}
      reportFilters={reportFilters}
      showVatAdvice
      //----------------
      extraFunctionForRequest={extraFunctionInSearch}
    />
  );
}

//-------------------------------------------------------------------------------------------------------------------------------------------------

type OptionsComponentProps = {
  openMenu: HTMLElement | null,
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
  handleCloseMenu: () => void,
  object: GridCellParams<DocumentSearchResult>,
  currentRow: GridCellParams<DocumentSearchResult> | null,
  handleLogs: (id: string, type: DocType) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleLogs }: OptionsComponentProps) {

  const { translate } = useLocales();

  const { id, type } = object.row;

  return (
    <TableMoreMenu
      showMenu={!!currentRow && object.id === currentRow.id}
      open={openMenu}
      onOpen={(event) => handleOpenMenu(event)}
      onClose={() => handleCloseMenu()}
      actions={
        <>
          <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs, OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs]}>
            <MenuItem
              onClick={() => {
                handleLogs(id, type);
                handleCloseMenu();
              }}
            >
              {`${translate("commons.logs")}`}
            </MenuItem>
          </PermissionBasedGuard>
        </>
      }
    />
  );
}