import { Box, Button, Card, Divider, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, Stack, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import BoxSwitch from "src/components/custom-input/BoxSwitch";
import { Add, DeleteForever } from "@mui/icons-material";
import { useLocales } from "src/locales";
import { DEFAULT_DISCOUNT_MODEL, DEFAULT_LEVEL, DiscountType, Level, NewShop } from "src/@types/webshop";
import { FormikProps, useFormik } from "formik";
import { useSettingsContext } from "src/components/settings";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import Label from "src/components/label";
import { DiscountMatrixSearchResult } from "src/@types/discountMatrix";
import { AltInfiniteAutocomplete } from "src/appComponents/InfiniteAutocomplete";
import { discountMatrixOperations } from "src/redux/discountMatrix";
import { WebshopStepButtons } from "./WebshopUtilComponents";

interface DiscountModelStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    onSubmit: (shop: Partial<NewShop>, showModal?: boolean) => void;
    state: NewShop
}

export default function DiscountModelStep({ changeStep, onSubmit, state }: DiscountModelStepProps) {

    const { translate } = useLocales();

    const { discountMatrixList } = useSelector((state: RootState) => state.discountMatrix);

    const { isShopCreatingLoading } = useSelector((state: RootState) => state.webshop);

    const [showModal, setShowModal] = useState(false);

    const [matrixDiscount, setMatrixDiscount] = useState<DiscountMatrixSearchResult | null>(() => {

        if (state.discountModel?.discountTemplateId)
            return discountMatrixList.find((v) => v.discountMatrixId === state.discountModel?.discountTemplateId) ?? null;

        return null;
    });

    const [button, setButton] = useState<"" | "FixedPrice" | "MatrixDiscount">(() => {
        if (!state.discountModel)
            return "";
        if (state.discountModel.discountType.includes("Fixed"))
            return "FixedPrice";
        if (state.discountModel.discountType.includes("Matrix"))
            return "MatrixDiscount";

        return "";

    });

    const formik = useFormik({
        initialValues: state,
        enableReinitialize: true,
        onSubmit: (values) => {
            onSubmit(values, showModal);
            setShowModal(false);
        }
    });

    const handlePopulate = () => {
        formik.setValues({ ...state, discountModel: DEFAULT_DISCOUNT_MODEL });
    };

    return (
        <Card sx={{ p: 3 }}>
            <Box>
                <Typography variant="h6">
                    {translate('webshop.form.step.discountModels')}
                </Typography>
                <ToggleButtonGroup
                    sx={{
                        gridTemplateColumns: '1fr 1fr',
                        display: 'grid',
                        border: 'none',
                        m: 2,
                        gap: 3,
                    }}
                    onChange={(_, v) => {
                        setButton(v);
                        if (!state.discountModel)
                            handlePopulate();
                    }}
                    value={button}
                    exclusive
                    size="large"
                    color="primary"
                >
                    <ToggleButton
                        value={"FixedPrice"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <DoNotDisturbAltIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('webshop.form.fixedPrice')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                    <ToggleButton
                        value={"MatrixDiscount"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <GridOnIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('webshop.form.matrixDiscount')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {button === "FixedPrice" && <FixedPrice formik={formik} />}
            {button === "MatrixDiscount" &&
                <Box>
                    <Box sx={{ my: 2 }}>
                        <Typography sx={{ mb: 1 }} variant="subtitle2">{translate('webshop.form.selectMatrix')}</Typography>
                        <AltInfiniteAutocomplete<DiscountMatrixSearchResult>
                            searchCallBack={(params) => dispatch(discountMatrixOperations.searchDiscountMatrixes({ filters: { name: params.all, ...params }, check: true })).unwrap()}
                            getOptionLabel={(option) => option.name ?? ""}
                            value={matrixDiscount}
                            filterOptions={(options, state) => options.filter(matrix => matrix.name?.includes(state.inputValue) ?? true)}
                            onChange={(_, value) => {
                                setMatrixDiscount(value);
                                formik.setFieldValue('discountModel.discountTemplateId', value?.discountMatrixId).then(() => void formik.setFieldTouched('discountModel.discountTemplateId', true));
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <MenuItem
                                        {...props}
                                        key={option.discountMatrixId}
                                    >
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            {option.name}
                                            {option.default && <Label variant="soft" color="warning">{translate('termsCondition.form.default')}</Label>}
                                        </Box>
                                    </MenuItem>
                                );
                            }}
                            renderInput={(params) => <TextField label={translate('webshop.form.matrixDiscount')} {...params} />}
                        />
                    </Box>
                    <MatrixDiscount formik={formik} />
                </Box>
            }
            <Divider sx={{ my: 3 }} />
            <WebshopStepButtons
                changeStep={changeStep}
                onSave={() => {
                    setShowModal(true);
                    formik.handleSubmit();
                }}
                loading={isShopCreatingLoading}
                save
            />
        </Card>
    );
}

const boxStyle = {
    p: 3,
    width: '100%',
    border: "1px solid #E2E2E2",
    borderRadius: '8px',
    ":focus-within": {
        borderColor: (theme: Theme) => theme.palette.primary.main
    }
};

function FixedPrice({ formik }: { formik: FormikProps<NewShop> }) {

    const { translate } = useLocales();

    const handleDiscountType = (v: boolean, name: DiscountType) => {

        formik.setValues({
            ...formik.values,
            discountModel: {
                discountType: v ? name : "None",
                discountTemplateId: formik.values.discountModel?.discountTemplateId ?? null,
                flat: null,
                multiLevel: null
            }
        });

    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">{translate('webshop.form.selectOption')}</Typography>

            <Box sx={boxStyle} >
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "FixedPriceOnly"}
                    onChange={(v) => handleDiscountType(v, "FixedPriceOnly")}
                    label={translate('webshop.form.fixedPriceOnly')}
                />
            </Box>

            <Box sx={boxStyle}>
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "FixedPriceFlat"}
                    onChange={(v) => handleDiscountType(v, "FixedPriceFlat")}
                    label={translate('webshop.form.fixedPriceFlat')}
                />
                {formik.values.discountModel?.discountType === "FixedPriceFlat" &&
                    <TextField
                        sx={{ mt: 2 }}
                        label={translate('webshop.form.additionalDiscount')}
                        value={formik.values.discountModel?.flat?.additionalDiscountPercentage ?? ""}
                        onChange={(e) => {
                            if (!isNaN(+e.target.value))
                                formik.setFieldValue('discountModel.flat', {
                                    additionalDiscountPercentage: +e.target.value,
                                    appliesToNetAmount: false
                                });
                        }}
                    />
                }
            </Box>

            <Box sx={boxStyle} >
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "FixedPriceMultiLevel"}
                    onChange={(v) => handleDiscountType(v, "FixedPriceMultiLevel")}
                    label={translate('webshop.form.fixedPriceMultiLevel')}
                />

                {formik.values.discountModel?.discountType === "FixedPriceMultiLevel" &&
                    <RangesRadio formik={formik} />
                }

            </Box>
        </Box>
    );
}

function MatrixDiscount({ formik }: { formik: FormikProps<NewShop> }) {

    const { translate } = useLocales();

    const handleDiscountType = (v: boolean, name: DiscountType) => {

        formik.setValues({
            ...formik.values,
            discountModel: {
                discountType: v ? name : "None",
                discountTemplateId: formik.values.discountModel?.discountTemplateId ?? null,
                flat: null,
                multiLevel: null
            }
        });

    };

    const [radio, setRadio] = useState<number | null>(null);

    //check if matrix flat gross price is disabled
    const disabledGross = formik.values.discountModel?.flat?.appliesToNetAmount ?? true;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">{translate('webshop.form.selectOption')}</Typography>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "MatrixGrossOnly"}
                    onChange={(v) => handleDiscountType(v, 'MatrixGrossOnly')}
                    label={translate('webshop.form.matrixGrossOnly')}
                    subtitle={translate('webshop.messages.grossDiscountSubtitle')} />

                {formik.values.discountModel?.discountType === "MatrixGrossOnly" &&
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                        <FormControlLabel
                            label={translate('webshop.form.withoutThreshold')}
                            onChange={() => {
                                setRadio(0);
                                formik.setFieldValue('discountModel.multiLevel', null);
                            }}
                            control={<Radio checked={radio === 0} />}
                        />
                        <FormControlLabel
                            label={translate('webshop.form.withThreshold')}
                            onChange={() => {
                                setRadio(1);
                                formik.setFieldValue('discountModel.multiLevel', {
                                    isMultiOrder: false,
                                    thresholds: []
                                });
                            }}
                            control={<Radio checked={radio === 1} />}
                        />
                        {radio === 1 &&
                            <Box>
                                <Ranges formik={formik} />
                            </Box>
                        }
                    </Box>
                }
            </Box>
            <Box sx={boxStyle}>
                <BoxSwitch
                    label={translate('webshop.form.matrixFlat')}
                    subtitle={translate('webshop.messages.discountFlatSubtitle')}

                    value={formik.values.discountModel?.discountType === "MatrixFlat"}
                    onChange={(v) => handleDiscountType(v, 'MatrixFlat')}

                />
                {formik.values.discountModel?.discountType === "MatrixFlat" &&
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>{translate('webshop.form.additionalDiscountCalc')} <b>{translate('webshop.form.netPrice')}</b></span>}
                                value={!!formik.values.discountModel?.flat?.appliesToNetAmount}
                                checked={!!formik.values.discountModel?.flat?.appliesToNetAmount}

                                onChange={() => formik.setFieldValue('discountModel.flat', {
                                    appliesToNetAmount: true,
                                    additionalDiscountPercentage: 0
                                })}
                                control={<Radio />}
                            />
                            <TextField
                                disabled={!formik.values.discountModel?.flat?.appliesToNetAmount}
                                label={translate('webshop.form.additionalDiscount')}
                                value={formik.values.discountModel?.flat?.appliesToNetAmount ? formik.values.discountModel.flat.additionalDiscountPercentage : ""}
                                onChange={(e) => {
                                    if (!isNaN(+e.target.value))
                                        formik.setFieldValue('discountModel.flat.additionalDiscountPercentage', +e.target.value);
                                }}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => !formik.values.discountModel?.flat?.appliesToNetAmount ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>{translate('webshop.form.additionalDiscountCalc')} <b>{translate('webshop.form.grossPrice')}</b></span>}
                                onChange={() => formik.setFieldValue('discountModel.flat', {
                                    appliesToNetAmount: false,
                                    additionalDiscountPercentage: 0
                                })}
                                value={formik.values.discountModel?.flat?.appliesToNetAmount === false || false}
                                checked={formik.values.discountModel?.flat?.appliesToNetAmount === false || false}

                                control={<Radio />}
                            />
                            <TextField
                                disabled={disabledGross}
                                label={translate('webshop.form.additionalDiscount')}
                                value={!disabledGross ? formik.values.discountModel.flat?.additionalDiscountPercentage : ""}
                                onChange={(e) => {
                                    if (!isNaN(+e.target.value))
                                        formik.setFieldValue('discountModel.flat.additionalDiscountPercentage', +e.target.value);
                                }}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => disabledGross ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>

                    </Box>
                }
            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "MatrixFlexible"}
                    onChange={(v) => handleDiscountType(v, 'MatrixFlexible')}
                    label={translate('webshop.form.matrixFlexible')}
                    subtitle={translate('webshop.messages.discountFlexibleSubtitle')} />

            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch
                    value={formik.values.discountModel?.discountType === "MatrixMultiLevel"}
                    onChange={(v) => handleDiscountType(v, 'MatrixMultiLevel')}

                    label={translate('webshop.form.matrixMultiLevel')} />

                {formik.values.discountModel?.discountType === "MatrixMultiLevel" && <RangesRadio formik={formik} />}
            </Box>
        </Box>
    );
}

function Ranges({ formik }: { formik: FormikProps<NewShop> }) {

    const { translate } = useLocales();

    const handleAddRange = () => {
        const arr = formik.values.discountModel?.multiLevel?.thresholds;

        if (arr) {
            if (!arr.length)
                formik.setFieldValue('discountModel.multiLevel.thresholds', [{ ...DEFAULT_LEVEL }]);
            else {

                formik.setFieldValue('discountModel.multiLevel.thresholds', arr.concat([{ ...DEFAULT_LEVEL, fromAmount: arr[arr.length - 1].toAmount, level: arr[arr.length - 1].level + 1 }]));
            }
        }

    };

    const handleChangeRange = (index: number, name: string, value: number) => {
        const arr = formik.values.discountModel?.multiLevel?.thresholds;

        if (arr) {
            arr[index][name as keyof Level] = value;

            formik.setFieldValue('discountModel.multiLevel.thresholds', arr);
        }
    };

    const handleDelete = (index: number) => {
        let arr = formik.values.discountModel?.multiLevel?.thresholds;

        if (arr) {

            arr = arr.filter((_, i) => i !== index);

            arr.forEach((l, i) => l.level = i);

            formik.setFieldValue('discountModel.multiLevel.thresholds', arr);
        }

    };

    return (
        <Box>
            {formik.values.discountModel?.multiLevel?.thresholds.map((r, index) =>
                <RangeRow
                    handleChangeRange={(n, v) => handleChangeRange(index, n, v)}
                    handleDelete={() => handleDelete(index)}
                    row={r}
                    key={index}
                />

            )}
            <Button
                variant="soft"
                size={"large"}
                fullWidth

                startIcon={<Add />}
                onClick={() => handleAddRange()}
            >
                {translate('webshop.form.addRange')}

            </Button>
        </Box>
    );

}

function RangesRadio({ formik }: { formik: FormikProps<NewShop> }) {

    const { translate } = useLocales();

    const setMultiOrder = (v: boolean) => {
        formik.setFieldValue('discountModel.multiLevel', {
            isMultiOrder: v,
            thresholds: []
        });
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1 }}>
                <FormControlLabel
                    label={translate('webshop.form.singleOrder')}
                    value={formik.values.discountModel?.multiLevel?.isMultiOrder === false || false}
                    checked={formik.values.discountModel?.multiLevel?.isMultiOrder === false || false}
                    onChange={() => setMultiOrder(false)}
                    control={<Radio />}
                />
                {
                    formik.values.discountModel?.multiLevel?.isMultiOrder === false &&
                    <Ranges formik={formik} />
                }
                <FormControlLabel
                    label={translate('webshop.form.multipleOrder')}
                    value={!!formik.values.discountModel?.multiLevel?.isMultiOrder}
                    checked={!!formik.values.discountModel?.multiLevel?.isMultiOrder}
                    onChange={() => setMultiOrder(true)}
                    control={<Radio />}
                />
                {
                    !!formik.values.discountModel?.multiLevel?.isMultiOrder &&
                    <Ranges formik={formik} />
                }
            </Box>
        </Box>
    );
}

interface RangeRowProps {
    row: Level,
    handleChangeRange: (name: string, value: number) => void,
    handleDelete: VoidFunction
}

function RangeRow({ handleChangeRange, row, handleDelete }: RangeRowProps) {

    const { translate } = useLocales();

    const { currency } = useSettingsContext();

    return (
        <Box sx={{
            my: 2,
            width: '100%',
            display: 'flex',
            mt: 2, gap: 2,
            alignSelf: 'stretch',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' }
        }}>

            <TextField onChange={(e) => {
                if (!isNaN(+e.target.value))
                    handleChangeRange('fromAmount', +e.target.value);
            }}
                value={row.fromAmount}
                fullWidth
                label={translate('commons.from')}
                inputProps={{ min: 0, max: 100 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>
                }}
            />

            <TextField
                onChange={(e) => {
                    if (!isNaN(+e.target.value))
                        handleChangeRange('toAmount', +e.target.value);
                }}
                value={row.toAmount}
                fullWidth
                label={translate('commons.to')}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>
                }}

            />

            <TextField
                onChange={(e) => {
                    if (!isNaN(+e.target.value) && +e.target.value < 100 && +e.target.value > 0)
                        handleChangeRange('additionalDiscountPercentage', +e.target.value);
                }}
                value={row.additionalDiscountPercentage}
                fullWidth
                label={translate('documents.documentHeaders.discount')}
                InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
            />
            <Button
                size={"medium"}
                startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                onClick={() => handleDelete()}
            />

        </Box>
    );
}