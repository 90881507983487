import { Autocomplete, AutocompleteInputChangeReason, AutocompleteProps, Box, Chip, TextField, useTheme } from '@mui/material';
import { isArray, isObject, debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { Suggest, Tag } from 'src/@types/commons';
import useIsMountedRef from 'src/appHooks/useIsMountedRef';
import matrixService from 'src/services/matrixService';
import vehicleService from 'src/services/vehicleService';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';
import userService from 'src/services/userService';
import { suggestVendorRegion, searchCustomerTags } from 'src/services/organizationsService';
import { searchOrdersTags as searchOrdersTags, searchDocumentsTags } from "src/services/ordersServices";

export type ResponseTypes = (Tag | string | Suggest);

export type OptionResult = { option: ResponseTypes, key: string, label: string };

//-----------------------------------------------------------

const formTags = ['vehicleTag', 'matrixTag', 'userTag', 'organizationTag'] as const;

export type FormTags = typeof formTags[number];

export const filterTags = ['vehicleFilterTag', 'matrixFilterTag', 'userFilterTag', 'organizationFilterTag', "reportFilterTag", `sparePartsFilterTag`, 'ordersFilterTag', "documentsFilterTag",
    "documentsCustomerIds", "documentsVendorIds", "ordersCustomerId", "containeTemplateFilterTag", "containerFilterTag"] as const;

export type FilterTags = typeof filterTags[number];

const others = ['vehicleBrand', 'vehicleModelCode', 'vehicleModel', 'vehicleVersion', 'vehicleCarline', 'organizationRegion'] as const;

export type Others = typeof others[number];

//-----------------------------------------------------------

interface Props<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<OptionResult, Multiple, DisableClearable, FreeSolo>,
    'options' | 'onChange' | 'renderOption' | 'renderInput'> {
    name: string;
    context: FormTags | FilterTags | Others;
    label?: string;
    dependenciesNames?: string[];
    required?: boolean;
    onChangeVal?: (value: any) => void;
    setOnEditForm?: (value: boolean) => void;
    onEditForm?: boolean;
    setTagsFromForm?: (value: any[]) => void;
    tagsFromForm?: any[];
    handleChangeValues?: (newVal: any, type: string, resetModel?: boolean) => void;
    handleChangeValuesF?: (values: any, newVal: any, type: string, resetModel?: boolean) => void; //TODO: fix momentanea, andrebbe fatto da tutte le sidebar (vedere veicoli) - conseguente al mettere callback sulla funzione in questione
    valuesFromForm?: any,
    inEditTags?: boolean;
    setInEditTags?: (value: boolean) => void;
}

const check = (matrix: any, context: FormTags | FilterTags | Others, user: any, vehicle: any, organization: any, tagsFromForm?: any[]) => {
    if (matrix && context === "matrixTag") {
        return (matrix!.tags);
    } else if (user && context === "userTag") {
        return (user.tags);
    } else if (context === "vehicleTag") {
        return (tagsFromForm || vehicle.tags);
    } else if (context === "organizationTag") {
        return (tagsFromForm || organization.tags);
    } else if (context === "organizationRegion") {
        return (tagsFromForm || organization.regions);
    } else if (filterTags.includes(context as FilterTags)) {
        return (tagsFromForm);
    } else return [];
};

export default function AutocompleteField<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({ context, name, label, multiple, freeSolo, dependenciesNames, required, onChangeVal, setOnEditForm, onEditForm, setTagsFromForm, tagsFromForm, handleChangeValues, handleChangeValuesF, valuesFromForm, inEditTags, setInEditTags, ...others }: Props<OptionResult, Multiple, DisableClearable, FreeSolo>) {

    const isMountedRef = useIsMountedRef();

    const { control, getValues, watch, setValue } = useFormContext();

    const theme = useTheme();

    const { translate } = useLocales();

    const [options, setOptions] = useState<OptionResult[]>([]);

    const inputValue = watch(name);

    const getOptionsDelayed = useCallback(
        debounce((text) => {
            setOptions([]);
            handleChangeSearch(text, null, null);
        }, 600), []);

    const { matrix } = useSelector((state: RootState) => state.matrix);

    const { user } = useSelector((state: RootState) => state.user);

    const { vehicle } = useSelector((state: RootState) => state.vehicle);

    const { organization } = useSelector((state: RootState) => state.organizations);

    const [fixedChips, setFixedChips] = useState<any>(check(matrix, context, user, vehicle, organization, tagsFromForm));

    useEffect(() => {
        if (matrix && context === "matrixTag") {
            setFixedChips(matrix!.tags);
        } else if (user && context === "userTag") {
            setFixedChips(user.tags);
        } else if (context === "vehicleTag") {
            setFixedChips(tagsFromForm || vehicle.tags);
        } else if (context === "organizationTag") {
            setFixedChips(tagsFromForm || organization?.tags);
        } else if (context === "organizationRegion") {
            setFixedChips(tagsFromForm || organization?.regions);
        } else if (filterTags.includes(context as FilterTags)) {
            setFixedChips(tagsFromForm);
        }
    }, []);

    useEffect(() => {
        getOptionsDelayed(inputValue);
    }, [inputValue, getOptionsDelayed]);

    const handleChangeSearch = async (
        value: string,
        reason: AutocompleteInputChangeReason | null,
        field: ControllerRenderProps<FieldValues, string> | null,
        allowEmptySearch = false
    ) => {
        if (!!reason && reason === 'reset') {
            return;
        }
        let fixedResults: OptionResult[] = [];

        switch (context) {
            case 'vehicleTag': {
                if (value && isArray(value) && !value.length) return;
                vehicleService.searchTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }
            case 'vehicleFilterTag': {
                if (value && isArray(value) && !value.length) return;
                vehicleService.searchTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }
            case 'matrixTag': {
                if (value && isArray(value) && !value.length) return;
                matrixService.searchTemplateTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));

                break;
            }
            case 'matrixFilterTag': {
                if (value && isArray(value) && !value.length) return;
                matrixService.searchMatrixTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));

                break;
            }
            case 'userTag': {
                if ((value && !value.length)) return;

                userService.searchTags(value, 3).then(response => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));

                break;
            }
            case 'userFilterTag': {
                if ((value && !value.length)) return;

                break;
            }
            case 'vehicleBrand': {
                const response = await vehicleService.suggestBrand(!!value && value !== '' ? value : null);

                fixedResults = response.data.map(el => ({
                    key: el.text,
                    label: `${el.text} (${el.numberOfTimesUsed})`,
                    option: el
                }));
                break;
            }
            case 'vehicleModel': {
                const dep = getValues((dependenciesNames || [''])[0]);

                if (!dep) break;

                const response = await vehicleService.suggestModel(
                    getValues((dependenciesNames || [''])[0]),
                    !!value && value !== '' ? value : null
                );

                fixedResults = response.data.map(el => ({
                    key: el.text,
                    label: `${el.text} (${el.numberOfTimesUsed})`,
                    option: el
                }));
                break;
            }
            case 'vehicleVersion': {
                const dep = getValues((dependenciesNames || ['', ''])[1]);

                if (!dep) break;
                vehicleService.suggestVersion(
                    getValues((dependenciesNames || [''])[0]),
                    getValues((dependenciesNames || ['', ''])[1]),
                    !!value && value !== '' ? value : null
                ).then(response => {
                    fixedResults = response.data.map(el => ({
                        key: el.text,
                        label: `${el.text} (${el.numberOfTimesUsed})`,
                        option: el
                    }));
                }).catch(e => console.error(e));

                break;
            }
            case 'vehicleModelCode': {
                const dep = getValues((dependenciesNames || [''])[0]);

                if (!dep) break;

                const response = await vehicleService.suggestModelCode(
                    getValues((dependenciesNames || [''])[0]),
                    !!value && value !== '' ? value : null
                );

                fixedResults = response.data.map(el => ({
                    key: el.text,
                    label: `${el.text} (${el.numberOfTimesUsed})`,
                    option: el
                }));

                break;
            }
            case 'vehicleCarline': {
                const dep = getValues((dependenciesNames || [''])[0]);

                if (!dep) break;
                vehicleService.suggestCarLine(
                    getValues((dependenciesNames || [''])[0]),
                    !!value && value !== '' ? value : null
                ).then((response) => {
                    fixedResults = response.data.map(el => ({
                        key: (el as Tag).text,
                        label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                        option: el
                    }));
                }).catch(e => console.error(e));;

                break;
            }
            case "organizationFilterTag": case 'organizationTag': {
                if (value && isArray(value) && !value.length) return;
                searchCustomerTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }
            case 'organizationRegion': {

                const dep = getValues((dependenciesNames || [''])[0]);

                if (!dep) break;
                if (value && isArray(value) && !value.length) return; //REMOVE ?!

                const response = await suggestVendorRegion(
                    getValues((dependenciesNames || [''])[0]),
                    !!value && value !== '' ? 49 : 1
                );

                fixedResults = response.data.map(el => ({
                    key: (el as Suggest).field,
                    label: `${(el as Suggest).field} (${(el as Suggest).count})`,
                    option: el
                }));
                break;
            }
            case 'ordersFilterTag': {
                if (value && isArray(value) && !value.length) return;
                searchOrdersTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }
            case 'documentsFilterTag': {
                if (value && isArray(value) && !value.length) return;
                searchDocumentsTags(value, 3).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }
            case 'ordersCustomerId': {
                if (value && isArray(value) && !value.length) return;
                searchDocumentsTags(value, 7).then((response) => {
                    fixedResults = response.data.map(el => {
                        const res: OptionResult = {
                            key: (el as Tag).text,
                            label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                            option: el
                        };

                        return res;
                    });
                }).catch(e => console.error(e));
                break;
            }

            default:
                const response = await new Promise((resolve, reject) => { reject('Autocomplete field context not set'); });

                console.error(response);
        }

        if (isMountedRef.current) {
            setOptions(fixedResults);
        }
    };

    const handleChange = (
        //event: SyntheticEvent,
        newValue: OptionResult | null | string,
        field: ControllerRenderProps<FieldValues, string> | null
    ) => {

        if (isArray(newValue)) {
            const values = newValue.map(v => {
                if (isObject(v)) {
                    return (v as OptionResult).key;
                }

                return v;
            });

            const uniqueValues = values.filter((element, index) => {
                return values.indexOf(element) === index;
            });

            //field?.onChange(uniqueValues);
            if (setTagsFromForm) {
                setTagsFromForm(uniqueValues);
            }
            if (handleChangeValues) {
                handleChangeValues(uniqueValues, name);
            }
            if (handleChangeValuesF) {
                handleChangeValuesF(valuesFromForm, uniqueValues, name);
            }
        } else if (isObject(newValue)) {
            //const value = (newValue as OptionResult).key;

            //field?.onChange(value);
        } else {
            //field?.onChange(newValue);
        }
    };

    const handleIsOptionEqualToValue = (option: OptionResult, value: OptionResult) => {
        const res = option.key === (isObject(value) ? value.key : value as any);

        return res;
    };

    const handleOnFocus = (field: ControllerRenderProps<FieldValues, string>) => {
        handleChangeSearch(field?.value, null, field);
        if (setOnEditForm) {
            setOnEditForm(true);
        }
        if (setInEditTags) { // && (name === "tags" || name === "regions")
            setInEditTags(true);
        }
    };

    const handleOnBlur = () => {
        if (onChangeVal) {
            onChangeVal(name);
            if (setOnEditForm) {
                setOnEditForm(false);
            }
        }
        if (setInEditTags && inEditTags) {

            if (filterTags.includes(context as FilterTags) && !isArray(getValues(name)) && multiple) {
                setValue(name, []);
            }

            setInEditTags(false);
        }
    };

    const renderChips = (values: (string | OptionResult)[], getTagProps?: any) => {
        var chips: any;

        let checkArray = getValues(name);

        if (isArray(values) && values.length) {
            if (filterTags.includes(context as FilterTags)) {
                checkArray = [...values];
            }
        }

        /*         if (!onEditForm && !isUndefined(onEditForm) && !inEditTags) {
                    checkArray = [...values];
                } */

        if (isArray(checkArray) && checkArray.length) {

            const uniqueValues = checkArray.filter((element, index) => {
                return values.indexOf(element) === index;
            });

            chips = uniqueValues.map((option: OptionResult | string, index: number) => (
                <Chip
                    {...getTagProps({ index })}
                    key={isObject(option) ? option.key : option as string}
                    size="small"
                    label={isObject(option) ? option.key : option as string}
                />
            ));
        }

        return chips;
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete

                    multiple={multiple}
                    freeSolo={freeSolo}
                    inputValue={multiple ? undefined : getValues(name) || ''}
                    clearOnBlur={true}
                    options={options}
                    defaultValue={fixedChips || []}
                    getOptionLabel={(option) => (option as OptionResult).key || ""}
                    renderOption={((props: object, option: OptionResult, state: object) => (
                        <Box component="li" {...props}>
                            {option.label}
                        </Box>
                    ))}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => handleIsOptionEqualToValue(option, value)}

                    onChange={(event, newValue, reason) => {
                        handleChange(newValue as OptionResult, field);
                    }}
                    onInputChange={(event: any, value, reason) => {
                        if (value && event && event.target.value.length >= 1) {
                            handleChange(value, field);
                        }
                    }}

                    renderTags={(values: (OptionResult | string)[], getTagProps) => {
                        return renderChips(values, getTagProps);
                    }}

                    onBlur={handleOnBlur}
                    onFocus={() => { handleOnFocus(field); }}

                    //value={getValues(name)}

                    renderInput={(params) =>
                        <TextField
                            {...params}
                            required={required ?? false}
                            error={!!error}
                            helperText={error && `${translate(error?.message)}`}
                            InputProps={{ ...params.InputProps }}
                            label={label}
                            sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: others.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                        />
                    }
                    {...others}
                />
            )}
        />
    );
}

