import { Tooltip, MenuItem, Typography, IconButton } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import MenuPopover from "src/components/menu-popover";
import { useLocales } from "src/locales";
import { alpha } from '@mui/material/styles';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { ToolbarActionButtonStyle } from "../utils/style";
import { InfiniteScrollListFilters, INFINITE_SCROLL_GENERIC_LIST_FILTERS } from "src/@types/list";

interface SortingComponentProps<Q extends InfiniteScrollListFilters<Z>, Z> {
    columns: Record<string, string>,
    filterValues: Q,
    handleSort: (sortModel: GridSortModel) => void
}

export default function SortingComponent<Q extends InfiniteScrollListFilters<Z>, Z>({ columns, filterValues, handleSort }: SortingComponentProps<Q, Z>) {

    const { translate } = useLocales();

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const [hoveredKey, setHoveredKey] = useState<string | null>(null);

    const { sortField, sortDirection } = filterValues;

    const [fieldState, setFieldState] = useState<string>(sortField);

    const [directionState, setDirectionState] = useState<"Ascending" | "Descending">(sortDirection);

    useEffect(() => {
        if (fieldState !== sortField || directionState !== sortDirection) {
            const sortOptions: GridSortItem[] = [];

            if (fieldState !== INFINITE_SCROLL_GENERIC_LIST_FILTERS.sortField || directionState !== INFINITE_SCROLL_GENERIC_LIST_FILTERS.sortDirection) {
                sortOptions.push({ field: fieldState, sort: directionState === "Descending" ? "desc" : "asc" });
            }

            handleSort(sortOptions);
        }
    }, [fieldState, directionState, handleSort]);

    return (
        <>
            <Tooltip title={`${translate('list.tooltip.sorting')}`}>
                <IconButton
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    size={'large'}
                    sx={{ ...ToolbarActionButtonStyle }}
                >
                    <NorthIcon sx={{ fontSize: 22, mb: 0.5, mr: -1.25, ml: 0.4 }} />
                    <SouthIcon sx={{ fontSize: 22, mt: 0.5, mr: 0.4 }} />
                </IconButton>
            </Tooltip>

            <MenuPopover
                open={openPopover}
                onClose={() => setOpenPopover(null)}
                sx={{ maxHeight: '40vh', overflowY: 'auto' }}
            >
                {Object.entries(columns).map(([key, val], index) => (
                    <MenuItem
                        key={"sortable.column." + key + "." + index}
                        onMouseEnter={() => setHoveredKey(key)}
                        onMouseLeave={() => setHoveredKey(null)}
                        onClick={() => {
                            if (fieldState !== key) {
                                setFieldState(key);
                                setDirectionState("Ascending");
                            }
                            else {
                                if (directionState === "Descending") setFieldState(INFINITE_SCROLL_GENERIC_LIST_FILTERS.sortField);
                                setDirectionState((prev) => prev === "Descending" ? "Ascending" : "Descending");
                            }
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: "space-between",
                            py: 1.5, my: 0.75,
                            ... (fieldState === key && {
                                backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.35)
                            })
                        }}
                    >
                        <Typography sx={{ mr: 2 }} variant='body2'>
                            {val}
                        </Typography>

                        {(directionState === "Descending" && fieldState === key) ?
                            <SouthIcon sx={{ ml: 1, mr: 0, color: (theme) => (hoveredKey === key && hoveredKey !== fieldState) ? theme.palette.primary.light : theme.palette.primary.main }} />
                            :
                            <NorthIcon sx={{ ml: 1, mr: 0, color: (theme) => (hoveredKey === key && hoveredKey !== fieldState) ? theme.palette.primary.light : (fieldState === key ? theme.palette.primary.main : theme.palette.grey[400]) }} />
                        }

                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    );
}