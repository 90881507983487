import { useDropzone } from 'react-dropzone';
import { styled, alpha } from '@mui/material/styles';
import { UploadProps } from './types';
import UploadInputBoxPlaceholder from './UploadInputBoxPlaceholder';

// ----------------------------------------------------------------------

const BigStyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(3, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

export default function UploadInputBox({ placeholder, error, disabled, onChange, sx, ...other }: UploadProps) {

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone(
    {
      disabled,
      accept: { 'image/*': [] },
      maxSize: 5000000,
      minSize: 102400,
      ...other
    }
  );

  const isError = isDragReject || !!error;

  return (
    <BigStyledDropZone {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
      }}
    >
      <input type="file" multiple {...getInputProps()} onChange={onChange} />

      <UploadInputBoxPlaceholder />

    </BigStyledDropZone>
  );
}
