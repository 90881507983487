import { INFINITE_SCROLL_GENERIC_LIST_FILTERS, InfiniteScrollListFilters, SequenceToken } from 'src/@types/list';
import { PriceAmount } from "./currency";
import { DEFAULT_PARENT, GenericOrganization, OganizationType, OrganizationSearchResult } from "./organizations";

export type Priority = "High" | "Medium" | "Low";

export const OrderTypesArr = ["Vehicle", "SparePart"] as const;

export type OrderType = typeof OrderTypesArr[number];

export const DocTypesArr = ["", "Invoice", "CreditNote", "DeliveryNote"] as const;

export type DocType = typeof DocTypesArr[number];

export type OrderSearchItem = {
    itemId: string,
    itemExternalId: string,
    type: OrderType,
    priority: Priority,
    name: string,
    quantity: number
};

export type OrderDetailItem = {
    [key: string]: any
    id: string,
    externalId: string,
    itemExternalId: string,
    quantity: number,
    itemIndex: number,
    deprecated: boolean,
    type: OrderType,
    priority: Priority,
    name: string,
    purchasePrice: PriceAmount,
    cataloguePrice: PriceAmount,
    grossAmount: PriceAmount,
    regularDiscountPercentage: number,
    extraDiscountPercentage: number,
    extraDiscountAmount: PriceAmount,
    regularDiscountAmount: PriceAmount,
    forcedDiscount: boolean,
    specialPrice: boolean,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    family: {
        name: string,
        code: string
    },
    category: {
        name: string,
        code: string
    },
    class: {
        name: string,
        code: string
    },
    customFields: Record<string, string>,
};

export type VendorAndCustomerInOrders = {
    id: string,
    status: boolean,
    type: OganizationType,
    name: string,
    externalId: string,
    vat: string,
    address: {
        address: string,
        zipCode: string,
        district: string,
        country: string,
        city: string
    }
};

//------------------------------------------------------------------

export interface DocumentSearchResult extends SequenceToken {
    id: string,
    score: number,
    createdOn: Date,
    type: DocType,
    prettyName: string,
    externalId: string,
    vendorId: string,
    vendorName: string,
    customerId: string,
    customerName: string,
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[]
}

export interface DocumentFilters extends InfiniteScrollListFilters {
    [key: string]: any
    all?: string,
    externalId?: string,
    customerId?: string,
    customer?: OrganizationSearchResult | GenericOrganization,
    from?: string,
    to?: string
    minAmount?: number,
    maxAmount?: number,
    documentType?: DocType | "all",
    tags?: string[],
    customFields?: Record<string, string>
}

//------------------------------------------------------------------

export interface OrderSearchResult extends SequenceToken {
    id: string,
    score: number,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendorId: string,
    vendorName: string,
    customerId: string,
    customerName: string,
    invoiceId?: string,
    invoiceExternalId?: string,
    deliveryNoteId?: string,
    deliveryNoteExternalId?: string,
    items: OrderSearchItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    totalQuantity: number,
    customFields: Record<string, string>,
    tags: string[]
}

export type OrderSuggestFilters = {
    vendorIds?: string[],
    customerIds?: string[],
    startDate?: string,
    endDate?: string,
    text?: string,
    size?: number,
    tags?: string[]
};

export interface OrderFilters extends InfiniteScrollListFilters {
    [key: string]: any
    customer?: OrganizationSearchResult | GenericOrganization,
    customerId?: string,
    vendorId?: string,
    prettyName?: string,
    documentExternalId?: string,
    from?: string,
    to?: string,
    minAmount?: number,
    maxAmount?: number,
    itemAll?: string,
    itemType?: OrderType | "All",
    itemName?: string,
    itemExternalId?: string,
    tags?: string[],
    customFields?: Record<string, string>,
    itemCustomFields?: Record<string, string>
}

//------------------------------------------------------------------

export type Order = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    invoiceId: string | null,
    invoiceExternalId?: string | null,
    deliveryNoteId: string | null,
    deliveryNoteExternalId?: string | null,
    creditNodeIds: string[],
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[]
};

//------------------------------------------------------------------

export type Invoice = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    hasDeliveryNote: boolean,
    customFields: Record<string, string>,
    tags: string[],
};

export type DeliveryNote = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    hasDeliveryNote?: boolean,
    hasInvoice: boolean,
    customFields: Record<string, string>,
    tags: string[]
};

export type CreditNote = {
    id: string,
    createdOn: Date,
    prettyName: string,
    externalId: string,
    vendor: VendorAndCustomerInOrders,
    customer: VendorAndCustomerInOrders,
    items: OrderDetailItem[],
    grossAmount: PriceAmount,
    totalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    customFields: Record<string, string>,
    tags: string[],
    orderIds: string[],
    hasDeliveryNote?: boolean,
};

//------------------------------------------------------------------

export type DocData = {
    partExternalId: string,
    documentId: string,
    documentExternalId: string
};

//------------------------------------------------------------------
export const DEFAULT_ORDERS_FILTERS: OrderFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    customer: DEFAULT_PARENT,
    customerId: "",
    vendorId: "",
    prettyName: "",
    documentExternalId: "",
    from: "",
    to: "",
    itemAll: "",
    itemType: "All",
    itemName: "",
    itemExternalId: "",
    tags: [],
    customFields: {},
    itemCustomFields: {}
};

export const STEP_PRICE_DOC_FILTER = 100;

export const DEFAULT_DOCUMENTS_FILTERS: DocumentFilters = {
    ...INFINITE_SCROLL_GENERIC_LIST_FILTERS,
    all: "",
    externalId: "",
    customerId: "",
    customer: DEFAULT_PARENT,
    from: "",
    to: "",
    minAmount: undefined,
    maxAmount: undefined,
    documentType: "all",
    tags: [],
    customFields: {}
};