import { Typography, Box, FormControlLabel, Switch, TablePagination, Stack, TextField, MenuItem, Radio, Button, Tooltip, Checkbox, Divider } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Item, ItemFilters, ItemSearchResult, RequestNew } from "src/@types/request";
import Iconify from "src/components/iconify";
import { useTable } from "src/components/table";
import { useLocales } from "src/locales";
import { RootState, dispatch } from "src/redux/store";
import { useAuth } from "src/appHooks/useAuth";
import DeleteIcon from '@mui/icons-material/Delete';
import { requestOperations } from "src/redux/request";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Newspaper } from "@mui/icons-material";
import DateZone from "src/appComponents/DateZone";
import { SparePartsFilters, SparePartSearchResult } from "src/@types/spareParts";
import { sparePartsOperations } from "src/redux/spareParts";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { debounce } from "lodash";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import useTenant from "src/appHooks/useTenant";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";
import useLocalStorage from "src/hooks/useLocalStorage";
import VisibilityModelComponent from "src/utils/list/toolbar/VisibilityModelComponent";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { getPrice } from "src/utils/currency";
import GenericListFooter from "src/utils/list/utils/GenericListFooter";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import useResponsive from "src/hooks/useResponsive";

type SparePartSearchResultQty = SparePartSearchResult & { quantity: number }

interface SearchProductProps {
    onChange: (name: string, value: any, items?: boolean) => void,
    request: RequestNew,
    isVendor: boolean,
    organization: string,
    ar: boolean,
    arParts: ItemSearchResult[],
    changeArParts: (parts: ItemSearchResult[]) => void,
    exceededItems: SparePartSearchResultQty[]
    changeExceedItems: (parts: SparePartSearchResultQty[]) => void
}

const getConcatId = (item: ItemSearchResult) => item.orderId + item.itemId + item.itemIndex;

export default function SearchProduct({ onChange, request, isVendor, organization, ar, changeArParts, arParts, exceededItems, changeExceedItems }: SearchProductProps) {

    const apiRef = useGridApiRef();

    const { customFields, id: tenantId } = useTenant();

    const isDesktop = useResponsive('up', 'md');

    const { requestItems, isItemsLoading } = useSelector((state: RootState) => state.request);

    const { sparePartsList, isLoading, totalCount } = useSelector((state: RootState) => state.spareParts);

    const { organizationId } = useUserOrganizationContext();

    const [searchBy, setSearchBy] = useState('part');

    const [search, setSearch] = useState('');

    const [isFiltered, setIsFiltered] = useState(false);

    const { translate, currentLang } = useLocales();

    const [selectionModel, setSelectionModel] = useState<string | null>(request.items[0]?.id || null);

    const [WrongShipmentSelection, setWrongShipmentSelection] = useState<Item[]>(request.items);

    const [warrantyArItems, setWarrantyArItems] = useState<ItemSearchResult[]>(arParts);

    const [surplusExceededItems, setSurplusExceededItems] = useState<SparePartSearchResultQty[]>(exceededItems);

    const { user } = useAuth();

    const [orderId, setOrderId] = useState(request.items[0]?.orderId || "");

    const [skipRules, setSkipRules] = useState(false);

    const [isScrolling, setIsScrolling] = useState(false);

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const {
        page,
        rowsPerPage,
        onChangeDense,
        dense,
        onChangePage,
        onChangeRowsPerPage,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        setPage
    } = useTable();

    const filters: ItemFilters = useMemo(() => {

        const val = (search && search.length > 3) ? search : undefined;

        return {
            orderId: (request.requestType === "WrongShipment" && orderId) ? orderId : undefined,
            pagination: "After",
            sequenceToken: isScrolling ? requestItems.results[requestItems.results.length - 1]?.searchSequenceToken ?? null : null,
            size: 12,
            sortDirection: order === "asc" ? "Ascending" : "Descending",
            requestType: request.requestType,
            customerId: isVendor ? organization : undefined,
            vendorId: !isVendor ? organization : undefined,
            itemName: searchBy === "part" ? val : undefined,
            documentExternalId: searchBy === "document" ? val : undefined,
            sortField: orderBy ? orderBy : "createdOn",
            skipRules: skipRules
        };
    }, [isScrolling, isVendor, order, orderBy, orderId, organization, request.requestType, requestItems.results, search, searchBy, skipRules]);

    const searchItems = (options: ItemFilters, clear?: boolean, value?: string, scrolling?: boolean) => {

        if (clear) {
            isVendor ?
                dispatch(requestOperations.searchVendorReturnItems({ options: { ...options, documentExternalId: undefined, itemName: undefined }, isScrolling: scrolling })) :
                dispatch(requestOperations.searchCustomerReturnItems({ options: { ...options, documentExternalId: undefined, itemName: undefined }, isScrolling: scrolling }));
            setSearch("");

            return;
        }

        isVendor ?
            dispatch(requestOperations.searchVendorReturnItems({ options, isScrolling: scrolling })) :
            dispatch(requestOperations.searchCustomerReturnItems({ options, isScrolling: scrolling }));

        if (search || value)
            setIsFiltered(true);
        else
            setIsFiltered(false);
    };

    const searchSpareParts = (options: SparePartsFilters, clear?: boolean, value?: string) => {

        if (clear) {

            dispatch(sparePartsOperations.searchSpareParts(options));
            setSearch("");

            return;
        }

        let all: string | undefined = "";

        if (value && value.length > 3)
            all = value;
        else if (search.length > 3)
            all = search;
        else
            all = undefined;

        dispatch(sparePartsOperations.searchSpareParts({ ...options, all }));

        if (search || value)
            setIsFiltered(true);
        else
            setIsFiltered(false);
    };

    const debouncedSparePartsSearch = useRef(debounce(searchSpareParts, 500));

    const debouncedSearch = useRef(debounce(searchItems, 500));

    const handleSearch = (clear?: boolean, value?: string, skip?: boolean, scrolling?: boolean) => {

        let val = value !== undefined ? value : (clear ? "" : search);

        if (request.requestReason === "SurplusDelivered") {
            let options: SparePartsFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                sortField: orderBy || undefined,
                organizationId: isVendor ? organizationId : organization
            };

            if (clear || val.length > 3 || val.length === 0 || skip)
                debouncedSparePartsSearch.current(options, clear, val);
        }
        else {

            let options: ItemFilters = {
                orderId: (request.requestType === "WrongShipment" && orderId) ? orderId : undefined,
                pagination: "After",
                sequenceToken: !scrolling ? null : (requestItems.results[requestItems.results.length - 1]?.searchSequenceToken ?? null),
                size: 12,
                sortDirection: order === "asc" ? "Ascending" : "Descending",
                requestType: request.requestType,
                customerId: isVendor ? organization : undefined,
                vendorId: !isVendor ? organization : undefined,
                itemName: searchBy === "part" ? val : undefined,
                documentExternalId: searchBy === "document" ? val : undefined,
                sortField: orderBy ? orderBy : "createdOn",
                skipRules: skipRules
            };

            if (tenantId === "stellantis-europe")
                options = {
                    ...options,
                    "customFields.stellantis-europe-spareparts-deposit-number": searchBy === "deposit" ? (val || undefined) : undefined,
                    "customFields.stellantis-europe-spareparts-is-deposit": false,
                    "customFields.stellantis-europe-spareparts-has-deposit": request.requestType === "Core" || undefined
                };

            if (clear || val.length > 3 || val.length === 0 || skip) {

                if (scrolling) {
                    searchItems(options, clear, val, true);
                    setIsScrolling(false);
                }
                else
                    debouncedSearch.current(options, clear, val);
            }

        }

    };

    useEffect(() => {

        let val = (search && search.length > 3) ? search : undefined;

        if (!isScrolling && request.requestReason !== "SurplusDelivered") {

            let options: ItemFilters = filters;

            if (tenantId === "stellantis-europe")
                options = {
                    ...options,
                    "customFields.stellantis-europe-spareparts-deposit-number": searchBy === "deposit" ? (val || undefined) : undefined,
                    "customFields.stellantis-europe-spareparts-is-deposit": false,
                    "customFields.stellantis-europe-spareparts-has-deposit": request.requestType === "Core" || undefined
                };

            searchItems(options);
        }
    }, [user, order, orderBy, isVendor, orderId, skipRules, tenantId]);

    useEffect(() => {

        if (request.requestReason === "SurplusDelivered") {
            let options: SparePartsFilters = {

                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                sortField: orderBy || undefined,
                organizationId: isVendor ? organizationId : organization
            };

            searchSpareParts(options);
        }
    }, [order, orderBy, page, rowsPerPage, searchBy, tenantId]);

    useEffect(() => {
        if (requestItems.totalCount <= rowsPerPage * page) {
            setPage(0);
        }
    }, [page, rowsPerPage, setPage, requestItems.totalCount]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            if (apiRef.current?.scroll) {
                apiRef.current.scroll({ left: 0, top: 0 });
                setIsScrolling(false);
            }
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleChange = (value: GridRenderCellParams<ItemSearchResult> | GridRowParams<ItemSearchResult>) => {

        setSelectionModel(value.id.toString());
        onChange('document', [value.row.orderId, value.row.itemId, (value.row.orderId + value.row.itemId + value.row.itemIndex), value.row.itemIndex]);
    };

    const handleMissing = (value: GridRowParams<ItemSearchResult>, e: boolean) => {

        if (e) {
            if (WrongShipmentSelection.length === 0 && request.requestReason === "MissingParts")
                setOrderId(value.row.orderId);

            let item: Item = {
                id: getConcatId(value.row),
                productId: value.row.itemId,
                orderItemIndex: value.row.itemIndex,
                orderId: value.row.orderId,
                quantity: 0,
                bigAndHeavy: false
            };

            if (WrongShipmentSelection.find(v => v.id === getConcatId(value.row)))
                return;

            setWrongShipmentSelection(prev => prev.concat([item]));

            if (request.requestType === "Warranty" && ar) {
                setWarrantyArItems(prev => prev.concat([value.row]));
            }

        }
        else {
            if (WrongShipmentSelection.length === 1 && request.requestReason === "MissingParts")
                setOrderId("");
            setWrongShipmentSelection(prev => prev.filter(item => item.id !== getConcatId(value.row)));

            if (request.requestType === "Warranty" && ar)
                setWarrantyArItems(prev => prev.filter(i => getConcatId(value.row) !== getConcatId(value.row)));
        }
    };

    const handleSurplus = (value: GridRowParams<SparePartSearchResult>, e: boolean) => {
        if (e) {

            let item: Item = {
                id: value.row.id,
                productId: value.row.id,
                orderItemIndex: null,
                orderId: null,
                quantity: 0,
                bigAndHeavy: false
            };

            if (WrongShipmentSelection.find(v => v.id === value.row.id))
                return;

            setWrongShipmentSelection(prev => prev.concat([item]));

            setSurplusExceededItems(prev => prev.concat([{ ...value.row, quantity: 0 }]));
        }
        else {

            setWrongShipmentSelection(prev => prev.filter(item => item.id !== value.row.id));

            setSurplusExceededItems(prev => prev.filter(i => i.id !== value.row.id));
        }
    };

    useEffect(() => {

        if (request.requestReason === "MissingParts" || (request.requestType === "Warranty" && ar)) {

            let items: Item[] = WrongShipmentSelection.map(item => ({
                id: item.id,
                bigAndHeavy: false,
                productId: item.productId,
                orderItemIndex: item.orderItemIndex,
                quantity: 0,
                orderId: item.orderId
            }));

            //onChange("order", orderId);
            if (request.requestType === "Warranty" && ar)
                changeArParts(warrantyArItems);

            onChange("", items, true);

        }

        if (request.requestReason === "SurplusDelivered") {

            let items: Item[] = WrongShipmentSelection.map(item => ({
                id: item.id,
                bigAndHeavy: false,
                productId: item.productId,
                orderItemIndex: null,
                quantity: 0,
                orderId: null
            }));

            changeExceedItems(surplusExceededItems);

            onChange("", items, true);
        }
    }, [WrongShipmentSelection]);

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense
            || ((request.requestReason !== "SurplusDelivered" && !requestItems.results.length)
                || (request.requestReason === "SurplusDelivered" && !sparePartsList.length))) {
            height = isDesktop ? 632 : 649;
        }

        return height;
    };

    const COLUMNS: GridColDef<ItemSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.20,
            sortable: false,
            align: 'left',
            renderCell: (v) => {
                if (request.requestType === "WrongShipment" || (request.requestType === "Warranty" && ar))
                    return <Checkbox
                        checked={!!WrongShipmentSelection.find(item => item.id === getConcatId(v.row))}
                    />;

                else
                    return <Radio
                        checked={selectionModel === v.id.toString()}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleChange(v);
                        }}
                    />;
            }
        },
        ...(skipRules ? [{
            field: 'skipped',
            headerName: '',
            align: 'center',
            flex: 0.10,
            renderCell: (params) => {
                if (params.row.skippedRule)
                    return <Tooltip title={`${translate('request.form.skippedRules')}`} >
                        <SearchOffIcon />
                    </Tooltip>;
            }
        }] : []
        ) as GridColDef[],
        {
            field: 'productExternalId',
            headerName: translate('request.form.partN'),
            flex: 0.5,
            valueGetter: (_, row) => row.itemExternalId
        },
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: 0.75
        },
        {
            field: 'customFields.stellantis-europe-spareparts-deposit-number',
            headerName: translate('spareParts.list.tableHeaders.depositRef'),
            flex: 0.6,
            valueGetter: (_, row) => row.customFields["stellantis-europe-spareparts-deposit-number"] ?? "—"
        },
        ...(request.requestType !== "WrongShipment" ? [
            {
                field: 'availability',
                headerName: `${translate('request.form.avQuantity')}`,
                flex: 0.45,
                headerAlign: 'center',
                align: "center",
                valueGetter: (_, row: ItemSearchResult) => row.quantity
            }] as GridColDef[] : []),
        {
            field: 'family.code',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: 0.9,
            renderCell: (params) =>
            (params.row.family ?
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <Typography variant="subtitle2"><b>{params.row.family ? params.row.family.code : "—"}</b></Typography>
                    <Tooltip title={params.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row.family ? params.row.family.name : "—"}
                        </Typography>
                    </Tooltip>

                </Box>
                :
                <Typography variant="body2">{"—"}</Typography>
            )
        },
        {
            field: 'invoiceExternalId',
            headerName: `${translate('orders.tableHeaders.invoice')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.invoiceExternalId)
                    return <Typography variant="body2">{p.row.invoiceExternalId}</Typography>;
                else if (p.row.invoiceId)
                    return <ReceiptIcon />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'deliveryNoteExternalId',
            headerName: `${translate('orders.tableHeaders.deliveryNote')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.deliveryNoteExternalId)
                    return <Typography variant="body2">{p.row.deliveryNoteExternalId}</Typography>;
                else if (p.row.deliveryNoteId)
                    return <Newspaper />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: 0.5,
            renderCell: (params: any) => {
                if (!params.value) {
                    return '—';
                }

                return <DateZone variant="body2" date={params.value} noSeconds shortMonth />;
            }
        },
        {
            field: 'unitGrossAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.grossAmount')}`,
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.unitGrossAmount)}
                        currency={obj.row.unitGrossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.unitGrossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        }
    ];

    const SPARE_PART_COLUMNS: GridColDef<SparePartSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) => {
                return <Checkbox
                    checked={!!WrongShipmentSelection.find(item => item.id === v.row.id)}
                />;

            }
        },
        {
            field: 'externalId',
            headerName: `${translate('request.form.partN')}`,
            flex: 0.5,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        ...(request.requestType !== "WrongShipment" ? [{
            field: 'quantity',
            headerName: `${translate('request.form.avQuantity')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        }] : []),
        {
            field: 'family',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: 0.9,
            renderCell: (params) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <Typography variant="subtitle2"><b>{params.row.family ? params.row.family.code : "—"}</b></Typography>
                    <Tooltip title={params.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row.family ? params.row.family.name : "—"}
                        </Typography>
                    </Tooltip>

                </Box>

        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: 0.5,
            renderCell: (params: any) => {
                if (!params.value) {
                    return '—';
                }

                return <DateZone variant="body2" date={params.value} noSeconds shortMonth />;
            },
        },
        {
            field: 'sellAmount',
            headerName: `${translate('orders.tableHeaders.netAmount')}`,
            flex: 0.5,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.sellAmount)}
                        currency={obj.row.sellAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.sellAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
    ];

    //---- HANDLE COLUMN VISIBILITY - START -----//
    const [columns, setColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(
        columns[request.requestReason !== "SurplusDelivered" ? "searchProduct" : "searchProductSpareParts"]
        ||
        (() => (request.requestReason !== "SurplusDelivered" ? COLUMNS.filter((header) => header.field) : SPARE_PART_COLUMNS.filter((header) => header.field)).reduce((prev, curr) => {
            Object.assign(prev, { [curr.field]: true });

            return prev;
        }, {})));

    useEffect(() => {
        setColumns({ ...columns, [request.requestReason !== "SurplusDelivered" ? "searchProduct" : "searchProductSpareParts"]: visibility });
    }, [visibility]);
    //---- HANDLE COLUMN VISIBILITY - END -----//

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mx: 3, mt: 2, mb: 1 }}>
                <Typography variant="h6">
                    {`${translate('request.research')}`}
                </Typography>

                <VisibilityModelComponent
                    columns={(request.requestReason !== "SurplusDelivered" ? COLUMNS : SPARE_PART_COLUMNS) as GridColDef<any>[]}
                    model={visibility}
                    onChangeModel={setVisibility}
                />
            </Box>

            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>

                <TextField
                    fullWidth
                    label={`${translate("returns.searchType")}`}
                    value={searchBy}
                    onChange={(e) => { setSearchBy(e.target.value); }}
                    select
                    SelectProps={{
                        MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                    }}
                    sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                    }}
                >
                    <MenuItem
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                        value={'part'} >
                        {`${translate('request.partReference')}`}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                        value={'document'} >
                        {`${translate('request.documentReference')}`}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                        value={'deposit'}
                    >
                        {customFieldName("stellantis-europe-spareparts-deposit-number")}
                    </MenuItem>
                </TextField>

                <TextField
                    fullWidth
                    sx={{ m: 2 }}
                    id="orderSelect"
                    label={`${translate('commons.search')}`}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setIsScrolling(false);
                        handleSearch(false, e.target.value);
                    }}
                />

                <Button
                    variant="text"
                    sx={{ borderRadius: '8px' }}
                    onClick={() => handleSearch(undefined, undefined, true)}
                >
                    <Iconify
                        icon={'eva:search-fill'}
                        sx={{ width: 20, height: 20 }}
                    />
                </Button>

                {
                    isFiltered &&
                    <Button
                        variant="text"
                        onClick={() => {
                            setSearch('');
                            setIsFiltered(false);
                            handleSearch(true);
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                }

                <PermissionBasedGuard permissions={isVendor ? [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorSkipRules] : [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerSkipRules]}>
                    <FormControlLabel
                        sx={{ minWidth: 210 }}
                        onChange={(_, checked) => {
                            setSkipRules(checked);
                            setIsScrolling(false);
                        }}
                        label={`${translate('request.form.disableRules')}`}
                        labelPlacement="start"
                        control={<Switch checked={skipRules} />}
                    />
                </PermissionBasedGuard>
            </Stack>

            {request.requestReason === "SurplusDelivered" &&
                <DataGridPro
                    columns={SPARE_PART_COLUMNS}
                    rows={sparePartsList}
                    loading={isLoading}
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick
                    pagination
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={dense ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    onSortModelChange={handleSort}
                    columnVisibilityModel={visibility}
                    onRowClick={(p) => {
                        let found = WrongShipmentSelection.find(item => item.id === p.row.id.toString());

                        handleSurplus(p, !found);

                    }}
                    sx={{
                        ...DataGridStyle,
                        //cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: 650,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        }
                    }}
                    pageSizeOptions={[5, 10, 15, 30]}
                    slots={{
                        noRowsOverlay: noData,
                        noResultsOverlay: noData,
                        footer: () => (
                            <Box sx={{ position: 'relative' }} >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                            </Box>
                        )
                    }}
                />
            }

            {request.requestReason !== "SurplusDelivered" &&
                <DataGridPro
                    apiRef={apiRef}
                    rowCount={100}
                    columns={COLUMNS}
                    rows={requestItems.results}
                    loading={isItemsLoading}
                    getRowId={getConcatId}
                    disableColumnResize
                    disableColumnMenu
                    disableRowSelectionOnClick
                    pagination={false}
                    getRowClassName={(params) => `skippedRules--${params.row.skippedRule}`}
                    density={(dense && requestItems.results.length > 0) ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    paginationMode={"server"}
                    onSortModelChange={handleSort}
                    columnVisibilityModel={visibility}
                    scrollEndThreshold={20}
                    onRowsScrollEnd={() => {

                        if (requestItems.results.length < requestItems.totalCount && requestItems.results.length >= 12) {
                            handleSearch(false, undefined, skipRules, true);
                        }
                    }}
                    onRowClick={(p) => {
                        if (request.requestType === "WrongShipment" || (request.requestType === "Warranty" && ar)) {

                            const rowId = getConcatId(p.row);

                            let found = WrongShipmentSelection.find(item => item.id === rowId);

                            handleMissing(p, !found);
                        }
                        else {
                            handleChange(p);
                        }

                    }}
                    sx={{
                        ...DataGridStyle,
                        //cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: isDesktop ? 632 : 649,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        },
                        '& .skippedRules--true': {
                            backgroundColor: (theme) => theme.palette.action.selected,
                            '&:hover': {
                                backgroundColor: (theme) => theme.palette.action.disabledBackground
                            }
                        }
                    }}
                    slots={{
                        noRowsOverlay: noData,
                        noResultsOverlay: noData,
                        footer: () => (
                            <>
                                {requestItems.results.length === 0 && <Divider />}
                                <GenericListFooter
                                    dense={dense}
                                    onChangeDense={onChangeDense}
                                    totalCount={requestItems.totalCount}
                                />
                            </>
                        )
                    }}
                />
            }

            <Typography sx={{ textAlign: 'right', mb: 1.5, mr: 3 }} fontSize={'13px'}>
                {`${translate('orders.messages.vatAdvice')}`}
            </Typography>
        </Box>
    );
}
