import { alpha, Box, Card, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ShopDisplay } from "src/@types/webshop";
import { useLocales } from "src/locales";
import { RootState } from "src/redux/store";
import { MarkdownStyle } from "../../inbox/detail/InboxDetail";
import Markdown from "src/components/markdown";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import useResponsive from "src/hooks/useResponsive";

const tabSx = {
    justifyContent: 'flex-start',
    py: '13px',
    px: 2,
    m: 1,
    borderRadius: '8px',
    '&:not(:last-of-type)': { mr: 1 },
    "&.Mui-selected": {
        color: "primary.main",
        bgcolor: "primary.lighter"
    }
};

export default function GeneralDetail({ isPublic }: { isPublic?: boolean }) {

    const { displayShop } = useSelector((state: RootState) => state.webshop);

    const { translate } = useLocales();

    const [currentTab, setCurrentTab] = useState(0);

    const isDesktop = useResponsive("up", 'lg');

    console.log(isPublic);

    const TABS = [
        <MoreDetails shop={displayShop} key={displayShop?.id} />,
        <Duration shop={displayShop} key={displayShop?.id} />,
        ...(!isPublic ? [<InternalNote shop={displayShop} key={displayShop?.id} />] : [])
    ];

    return (
        <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
            <Tabs
                orientation={isDesktop ? "vertical" : 'horizontal'}
                value={currentTab}
                onChange={(_, tab) => setCurrentTab(tab)}
                TabIndicatorProps={{
                    style: { display: 'none' }
                }}
                sx={{
                    bgcolor: theme => alpha(theme.palette.grey[500], 0.12),
                    minWidth: "20%",
                    maxHeight: "20%"
                }}
            >
                <Tab
                    value={0}
                    sx={tabSx}
                    id='0'
                    label={translate('commons.moreDetail')}
                />
                <Tab
                    value={1}
                    sx={tabSx}
                    id='1'
                    label={translate('webshop.form.duration')}
                />
                {!isPublic &&//webshop?.internalNote && webshop.internalNote.length > 0 && 
                    <Tab
                        value={2}
                        sx={tabSx}
                        id='2'
                        label={translate('webshop.form.internalNote')}
                    />}
            </Tabs>
            {TABS[currentTab]}
        </Card>
    );
}

function MoreDetails({ shop }: { shop: ShopDisplay | null }) {

    const { translate } = useLocales();

    return (
        <Box sx={{ p: 3, width: "100%", height: '100%' }}>
            <Typography sx={{ mb: 3 }} variant="h6">{translate('commons.moreDetail')}</Typography>
            <Box>
                <Grid container sx={{ mb: 3, width: '100%' }}>
                    <Grid item xs={126} md={3}>
                        <Typography variant="subtitle1" sx={{ mt: 1.5, color: theme => theme.palette.text.secondary }}>
                            {translate('webshop.form.shortDesc')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                            <MarkdownStyle>
                                <Markdown children={(shop && shop.shortDescription) ? shop.shortDescription : "–"} />
                            </MarkdownStyle>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" sx={{ mt: 1.5, color: theme => theme.palette.text.secondary }}>
                            {translate('webshop.form.longDesc')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                            <MarkdownStyle>
                                <Markdown children={(shop && shop.longDescription) ? shop.longDescription : "–"} />

                            </MarkdownStyle>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {shop?.backorderAllowed && <Box>
                <Divider />
                <Grid container sx={{ mt: 3, alignItems: 'center' }}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" sx={{ color: theme => theme.palette.text.secondary }}>
                            {translate('webshop.form.maxBackorderQty')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="subtitle1" >
                            {shop?.backorderQuantity ?? "—"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>}

        </Box>
    );
}

function Duration({ shop }: { shop: ShopDisplay | null }) {

    const { translate } = useLocales();

    return (
        <Box sx={{ p: 3, width: "100%", height: '100%' }}>
            <Typography sx={{ mb: 3 }} variant="h6">{translate('webshop.form.duration')}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
                <GridLabeledText
                    left={3}
                    right={9}
                    label={translate('webshop.detail.previewDate')}
                    valueAsDate
                    date={new Date(shop?.previewDate ?? "")}
                    shortMonth noSeconds
                />
                <GridLabeledText
                    left={3}
                    right={9}
                    label={translate('webshop.form.startDate')}
                    date={new Date(shop?.startDate ?? "")}
                    valueAsDate shortMonth noSeconds
                />
                <GridLabeledText
                    left={3}
                    right={9}
                    label={translate('webshop.form.endDate')}
                    date={new Date(shop?.endDate ?? "")}
                    valueAsDate shortMonth noSeconds
                />
            </Box>
        </Box>
    );
}

function InternalNote({ shop }: { shop: ShopDisplay | null }) {

    const { translate } = useLocales();

    return (
        <Box sx={{ p: 3, width: "100%", height: '100%' }}>
            <Typography sx={{ mb: 3 }} variant="h6">{translate('commons.moreDetail')}</Typography>
            <Box>
                <Grid container sx={{ mb: 1 }}>
                    <Grid item sm={3}>
                        <Typography variant="subtitle1" sx={{ mt: 1.5, color: theme => theme.palette.text.secondary }}>
                            {translate('webshop.form.internalNote')}
                        </Typography>
                    </Grid>
                    <Grid item sm={9}>
                        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                            <MarkdownStyle>
                                <Markdown children={(shop && shop.internalNote) ? shop.internalNote : ""} />
                            </MarkdownStyle>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}