import { LocalizedString } from "./commons";
import { PriceAmount } from "./currency";
import { Media } from "./request";

export const DeliveryTypesArr = ["Fast", "Standard", ""] as const;

export type DeliveryTypes = typeof DeliveryTypesArr[number];

export type BasketParams = {
    bundleId: string,
    shopId: string,
    quantity: number
}

export interface BasketPrice {
    quantity: number,
    grossAmount: PriceAmount,
    regularDiscountAmount: PriceAmount,
    additionalDiscountAmount: PriceAmount,
    netAmount: PriceAmount,
    discountAmount: PriceAmount,
    discountPercentage: {
        withVat: number,
        withoutVat: number,
        withVatInEuro: number,
        withoutVatInEuro: number
    }
}

export interface BasketItem extends BasketPrice {
    id: string
    name: LocalizedString[],
    family: {
        supplier: {
            code: string,
            description: string
        }
    },
    media: Media[],
    unitDiscountedAmount: PriceAmount,
    unitGrossAmount: PriceAmount
}

export type BasketShop = {
    shopId: string,
    shopName: string,
    price: BasketPrice,
    items: BasketItem[]
}

export type Basket = {
    isExpired: boolean,
    expiresAt: string,
    price: BasketPrice,
    items: BasketShop[]
}

export type CheckoutParams = {
    customerOrderReference: string,
    customerId: string | null,
    deliveryOptions: {
        deliveryAddressId: string,
        deliveryType: DeliveryTypes
    }
};

export const DEFAULT_CHECKOUT_PARAMS: CheckoutParams = {
    customerId: null,
    customerOrderReference: "",
    deliveryOptions: {
        deliveryAddressId: "",
        deliveryType: ""
    }
};