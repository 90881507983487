import { ClusterPermissionTypes, OrganizationPermissionTypes } from "./permissions";
import { AxiosResponse } from "axios";
import { SelectItem, Tag } from "./commons";
import { LabelColor } from "src/components/label";
import { Lang } from "src/locales";
import { NavigateFunction } from "react-router";
import { AutocompleteRenderOptionState } from "@mui/material";
import { AsyncThunkAction } from "@reduxjs/toolkit";

const filterTypes = ['Section', 'TextField', 'Select', 'MultiSelect', 'TagsAutocomplete', 'Autocomplete', 'Slider', 'SingleSwitch', 'SwitchGroup', 'InputRange'] as const;

export type FilterTypes = typeof filterTypes[number];

const viewTypes = ['Grid', 'List'] as const;

export type ViewTypes = typeof viewTypes[number];

//------------------------------------------------------------------------------------

export type GenericPagedResponse<T> = {
    hasNextPage?: boolean,
    hasPreviousPage?: boolean,
    pageIndex: number,
    pageSize: number,
    items: T[],
    totalCount: number,
    totalPages?: number
}

export type GenericListFilters<T = string> = {
    [key: string]: any
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
    status?: T,
    customFields?: Record<string, string>
}

export const GENERIC_LIST_FILTERS: GenericListFilters = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "createdOn",
    sortAscending: false
};

//------------------------------------------------------------------------------------

export interface SequenceToken {
    searchSequenceToken: string | null
}

export type InfiniteScrollResponse<T extends SequenceToken> = {
    results: T[],
    totalCount: number
}

export type InfiniteScrollListFilters<T = string> = {
    [key: string]: any
    size: number,
    sequenceToken: string | null,
    pagination: "After" | "Before",
    sortField: string,
    sortDirection: "Ascending" | "Descending",
    status?: T
}

export const INFINITE_SCROLL_GENERIC_LIST_FILTERS: InfiniteScrollListFilters = {
    size: 12,
    sequenceToken: null,
    pagination: "After",
    sortField: "createdOn",
    sortDirection: "Descending"
};

//------------------------------------------------------------------------------------

export type ToolbarListFilters = {
    [key: string]: any
    key: string,
    label: string,
    isCustomfield?: string
};

export type SidebarListFilters = {
    name: string,
    label: string,
    type: FilterTypes,
    toParse?: boolean,
    //---- Permissions
    permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[],
    //---- Tags Autocomplete
    tagsService?: (tag: string, size: number) => Promise<AxiosResponse<Tag[]>>,
    //---- Autocomplete 
    nameId?: string,
    service?: (filters: any) => AsyncThunkAction<GenericPagedResponse<any>, any, any>,
    renderOptionComponent?: ((
        props: React.HTMLAttributes<HTMLLIElement> & { key: any; },
        option: any,
        state: AutocompleteRenderOptionState,
        ownerState: any
    ) => React.ReactNode),
    //---- Select
    options?: SelectItem[],
    //---- Slider
    stepValue?: number,
    stepsNumber?: number,
    //---- Switch
    switchesInfo?: {
        name: string,
        label: string,
        permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
    }[]
}

export type StatusFilters = {
    key: string,
    label: string,
    color: LabelColor
};

//------------------------------------------------------------------------------------

export type ToolbarDateErrors = {
    [key: string]: any
    fromFormat: boolean,
    fromMissing: boolean,
    toFormat: boolean,
    toMissing: boolean,
    toInvalid: boolean
}

export const DEFAULT_DATE_ERRORS: ToolbarDateErrors = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

//------------------------------------------------------------------------------------

export type MenuOptions<T> = {
    actualCardItem: T | null,
    setActualCardItem: (params: T) => void,
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: VoidFunction
}

export type SingleGridElementProps<T> = {
    key: string,
    item: T,
    isDesktop: boolean,
    translate: (text: unknown, options?: any) => string,
    navigate: NavigateFunction,
    currentLang?: Lang,
    extraProps?: any,
    menuOptions?: MenuOptions<T>
}

export type MultipleViewProps<T> = {
    renderGridElement: (props: SingleGridElementProps<T>) => JSX.Element,
    rowNumber?: number,
    extraProps?: any,
    menuOptions?: MenuOptions<T>
}

//-------------------------------------------------------------------

export type DatesGeneralFilterProps = {
    showDates?: boolean,
    datesNames?: [string, string],
    dateSearchBy?: [string, string]
}

export type ListProps = {

}