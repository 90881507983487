import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router";
import { FileWithSection } from "src/@types/media";
import { Upload } from "src/components/upload";
import { useLocales } from "src/locales";
import ProductsList from "../detail/ProductsList";
import { WebshopStepButtons } from "./WebshopUtilComponents";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

interface StockAvailabilityStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    onFileChange: (f: FileWithSection[]) => void,
    stock: File[],
    onSubmit: (showModal?: boolean) => void
}

export default function StockAvailabilityStep({ changeStep, onFileChange, stock, onSubmit }: StockAvailabilityStepProps) {

    const { translate } = useLocales();

    const [file, setFile] = useState<File | null>(stock[0] ?? null);

    const { isShopCreatingLoading } = useSelector((state: RootState) => state.webshop);

    const { id } = useParams();

    return (
        <Card sx={{ p: 3 }}>
            <Stack sx={{ my: 2 }}>
                <Typography variant="h6">{translate("webshop.form.stockAvailability")}</Typography>
                <Typography variant="body2">{translate("webshop.messages.stockSubtitle")}</Typography>
            </Stack>
            <Upload
                accept={{
                    'text/csv': ['.csv'],
                }}
                maxFiles={1}
                onDropAccepted={(f) => {
                    setFile(f[0] ?? null);
                    onFileChange(f);
                }}
                file={file}
            />
            <Divider sx={{ my: 3 }} />
            {id &&
                <Box>
                    <Typography variant="h5">{translate('webshop.detail.tabs.products')}</Typography>
                    <ProductsList />
                </Box>}
            <WebshopStepButtons
                changeStep={changeStep}
                onSave={onSubmit}
                loading={isShopCreatingLoading}
                save
            />
        </Card>
    );
}