import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, FormControlLabel, Switch, TablePagination, Divider, Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { DataGrid, GridCellParams, GridColDef, GridColumnVisibilityModel, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { cloneDeep, isArray, isEqual, isObject, omitBy, remove } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import useLocalStorage from 'src/hooks/useLocalStorage';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { GENERIC_LIST_FILTERS, GenericListFilters, GenericPagedResponse, ToolbarListFilters, DatesGeneralFilterProps, SidebarListFilters, StatusFilters } from 'src/@types/list';
import GenericFilterSummary from 'src/utils/list/summary/GenericFilterSummary';
import GenericFilterToolbar from 'src/utils/list/toolbar/GenericFilterToolbar';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import VisibilityModelComponent from './toolbar/VisibilityModelComponent';
import { ReportTypeData, ReportTypeDataArr } from 'src/@types/report';
import LicenseGuard from 'src/guards/LicenseGuard';
import { LicenseTypes } from 'src/@types/tenant';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import ReportCreationComponent from './toolbar/ReportCreationComponent';
import { getSliderFilterNames } from './sidebar/SidebarSlider';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10
};

type GenericListProps<T extends GridValidRowModel, Q extends GenericListFilters<Z>, Z = string> = {
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    list: T[],
    isLoading: boolean,
    defaultFilters: Q,
    specificStatsKeysToDelete?: string[],
    specificFullKeysToDelete?: string[],
    quickFilters?: StatusFilters[],
    renderQuickFilters?: (key: string) => any,
    toolbarFiltersList?: ToolbarListFilters[],
    filtersInSidebar?: SidebarListFilters[],
    updateCheckField: (field: string, filters: Q) => boolean,
    extraSearchFiltersChecks?: (searchFilters: Q, firstRender?: boolean) => Q,
    updateInUrlFiltersChecks?: (searchFilters: Q) => Q,
    context: string,
    customfieldContext?: string[],
    datagridColumns: GridColDef<T>[],
    setActualRow: Dispatch<any>,
    handleCellClick?: (params: GridCellParams<T>) => void,
    setFiltersCallback?: Dispatch<SetStateAction<Q>>,
    filterStatus?: string,
    onChangeFilterStatus?: (event: React.SyntheticEvent<Element, Event> | null, newValue: string) => void,
    search?: (filters: Q) => AsyncThunkAction<GenericPagedResponse<T>, any, any>,
    customSearchFunc?: (filters: Q) => void,
    searchStatistics?: (filters: Q) => AsyncThunkAction<any, any, any>,
    customSearchStatistics?: (filters: Q) => void,
    filtersInUrl?: string,
    setFiltersInUrl?: any,
    listDescription: string,
    datesGeneralFilter?: DatesGeneralFilterProps,
    reportFilters?: any,
    showVatAdvice?: boolean,
    //NON-GENERIC SECTION ---------
    extraFunctionForRequest?: (filters: Q, getValues: any, setValue: any) => Promise<void>,
    isStatusArray?: boolean
}

export default function GenericList<T extends GridValidRowModel, Q extends GenericListFilters<Z>, Z = string>({
    pageIndex,
    pageSize,
    totalCount,
    list,
    isLoading,
    defaultFilters,
    specificStatsKeysToDelete = [],
    specificFullKeysToDelete = [],
    quickFilters,
    renderQuickFilters,
    toolbarFiltersList,
    filtersInSidebar,
    datagridColumns,
    updateCheckField,
    extraSearchFiltersChecks,
    updateInUrlFiltersChecks,
    context,
    customfieldContext = [],
    setActualRow,
    handleCellClick,
    setFiltersCallback,
    filterStatus,
    onChangeFilterStatus,
    search,
    searchStatistics,
    customSearchFunc,
    customSearchStatistics,
    filtersInUrl,
    setFiltersInUrl,
    listDescription,
    datesGeneralFilter,
    reportFilters,
    showVatAdvice,
    //NON-GENERIC SECTION ---------
    extraFunctionForRequest,
    isStatusArray
}: GenericListProps<T, Q, Z>) {

    const {
        page,
        setPage,
        order,
        setOrderBy,
        orderBy,
        setOrder,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage
    } = useTable({ defaultOrder: "desc" });

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const [filters, setFilters] = useState(defaultFilters);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const methods = useForm<GenericListFilters<Z>>({ defaultValues: defaultFilters });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected
    const statsKeysToDelete: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "status", ...specificStatsKeysToDelete], []);

    const fullKeysToDelete: string[] = useMemo(() => ["all", "from", "to", ...specificFullKeysToDelete], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key));
    }, [statsKeysToDelete]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToDelete.includes(key) || fullKeysToDelete.includes(key));
    }, [fullKeysToDelete, statsKeysToDelete]);

    const isDefault = useCallback((filter: GenericListFilters<Z>, controller?: GenericListFilters<Z>, forStats?: boolean) => {

        const cleanedFilter = remove(Object.keys(filter), forStats ? statsKeyRemover : fullKeyRemover);

        const cleanedController = remove(Object.keys(controller ? controller : defaultFilters), forStats ? statsKeyRemover : fullKeyRemover);

        const checkKeys = cleanedFilter.length > cleanedController.length ? cleanedFilter : cleanedController;

        const checkObject = controller ? controller : defaultFilters;

        const found = checkKeys.find((element) => !isEqual(filter[element], checkObject[element]));

        return (!found);
    }, [defaultFilters, statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR FUNC ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = useCallback(() => {
        if (resetForm) {
            reset(defaultFilters);
        };
        setOpenSidebar(false);
    }, [defaultFilters, reset, resetForm]);

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = useCallback((section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    }, [formValues, isDefault]);

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const updateFiltersInUrl = useCallback((filters: Q) => {

        let queryString = Object.keys(filters).filter((field) => updateCheckField(field, filters))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        if (setFiltersInUrl) dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate, updateCheckField, setFiltersInUrl]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) {
            return [pageSize, pageIndex];
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    /*
    const getPageAndSize = useCallback((filtersInUrl?: string) => {
        if (firstRender) {
            let returnValues = [0, 0];

            if (filtersInUrl) {
                const cleanedHash = filtersInUrl.slice(1);

                const decodedQuery = decodeURIComponent(cleanedHash);

                const searchParams = new URLSearchParams(decodedQuery);

                returnValues = [
                    searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : pageSize,
                    searchParams.get('pageIndex') ? Number(searchParams.get('pageIndex')) : pageIndex
                ];
            } else {
                returnValues = [pageSize, pageIndex];
            }

            return returnValues;
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);
    */

    const getFiltersFromUrl = useMemo(() => {

        const { hash } = location;

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        let searchFilters: GenericListFilters<Z> = {
            pageIndex: pageIndexFunc,
            pageSize: pageSizeFunc,
            sortField: orderBy || undefined,
            sortAscending: orderBy ? (order === 'desc' ? true : false) : undefined,
            status: filterStatus as Z || undefined
        };

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                ...(Object.fromEntries(searchParams.entries())),
                ...searchFilters,
                status: searchParams.get('status') ? (isStatusArray ? JSON.parse(searchParams.get('status')!) : searchParams.get('status')) : null
            };

            if (filtersInSidebar) filtersInSidebar.filter((field) => field.toParse && searchFilters[field.name]).forEach((field) => searchFilters[field.name] = JSON.parse(searchFilters[field.name]));

            if (searchParams.get('customFields')) searchFilters["customFields"] = JSON.parse(searchParams.get('customFields')!);

        }

        searchFilters = omitBy(searchFilters, (x) => x === undefined || x === null) as GenericListFilters<Z>;

        return searchFilters as Q;

    }, [location, getPageAndSize, orderBy, order]); //isStatusArray
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: Q) => {

        var searchFilters: any = {};

        if (isEqual(values, defaultFilters)) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {
            Object.keys(values).forEach((field) => searchFilters[field] = values[field] ?? defaultFilters[field]);

            searchFilters = {
                ...searchFilters,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        if (updateInUrlFiltersChecks) {
            searchFilters = updateInUrlFiltersChecks(searchFilters);
            reset(searchFilters);
        }

        updateFiltersInUrl(searchFilters);
    }, [defaultFilters, order, orderBy, page, rowsPerPage, updateFiltersInUrl, reset, updateInUrlFiltersChecks]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Users list and statistics using filters
    const adjustLastFiltered = useCallback((values: Q) => {

        const result = Object.entries(values).reduce((acc, [key, value]) => {

            if (!isEqual(value, defaultFilters[key as keyof Q])) acc[key as keyof Q] = value;

            return acc;
        }, {} as Partial<Q>);

        return {
            ...result,
            ...GENERIC_LIST_FILTERS
        };
    }, [defaultFilters]);

    const onSearch = useCallback((filtersFromUrl: Q) => {

        setFilters(filtersFromUrl);

        if (setFiltersCallback) setFiltersCallback(filtersFromUrl);

        updateFiltersInUrl(filtersFromUrl);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrl.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrl, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        if (customSearchFunc) customSearchFunc(updatedFiltersFromUrl);
        else if (search) dispatch(search(updatedFiltersFromUrl));

        if ((firstRender
            || isEqual(filtersFromUrl, BASIC_FILTER_OPTIONS)
            || !isDefault(filtersFromUrl, lastStatsFilters as Q, true)) && (searchStatistics || customSearchStatistics)
        ) {
            if (customSearchStatistics) customSearchStatistics(updatedFiltersFromUrl);
            else if (searchStatistics) dispatch(searchStatistics(updatedFiltersFromUrl));

            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        //NON-GENERIC SECTION ---------
        if (extraFunctionForRequest) extraFunctionForRequest(filtersFromUrl, getValues, setValue);

        if (firstRender) setFirstRender(false);

    }, [setFiltersCallback, updateFiltersInUrl, extraFunctionForRequest, getValues, setValue, customSearchFunc, search,
        firstRender, isDefault, lastStatsFilters, searchStatistics, adjustLastFiltered, customSearchStatistics]);
    //
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: Q, olderFilters: Q) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortAscending")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortAscending");

    }, []);

    useEffect(() => {

        let searchFilters = cloneDeep(getFiltersFromUrl);

        if (extraSearchFiltersChecks)
            searchFilters = extraSearchFiltersChecks(searchFilters, firstRender);

        if (!isEqual(searchFilters, filters) || lastUsedLang !== currentLang.label) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            onSearch(searchFilters as Q);

            if (lastUsedLang !== currentLang.label) setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [location, page, rowsPerPage, orderBy, order, firstRender, filterStatus, currentLang, lastUsedLang,
        filters, setPage, setRowsPerPage, pageIndex, pageSize, isOrderDiff, extraSearchFiltersChecks, onSearch, getFiltersFromUrl]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = useCallback(() => {
        fetchData(getValues() as Q);
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    }, [fetchData, getValues, setPage]);

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = useCallback(() => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        setShowSummary(false);
        fetchData(defaultFilters);
        reset(defaultFilters);
    }, [defaultFilters, fetchData, handleCloseSidebar, openSidebar, reset, setPage]);

    const handleResetSingleFilter = useCallback((fieldName: string, value?: any, isRange?: boolean) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues as Q);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);

        } else if (isRange) {
            const [minLabel, maxLabel] = getSliderFilterNames(fieldName);

            formValues[minLabel] = defaultFilters[minLabel];
            setValue(minLabel, defaultFilters[minLabel]);

            formValues[maxLabel] = defaultFilters[maxLabel];
            setValue(maxLabel, defaultFilters[maxLabel]);

            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        } else {
            formValues[fieldName] = defaultFilters[fieldName];
            setValue(fieldName, defaultFilters[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        }
    }, [defaultFilters, fetchData, formValues, handleClearFromSummary, setValue]);
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const getHeight = useCallback(() => {
        let height: string | number = "auto";

        if (!dense || list.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 373 : 633;
            else height = rowsPerPage === 5 ? 422 : 681;
        }

        return height;
    }, [dense, isDesktop, list, rowsPerPage]);

    const getMaxHeight = useCallback(() => {
        return isDesktop ? 633 : 681;
    }, [isDesktop]);

    const handleSort = useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("desc");
        }
    }, [setOrder, setOrderBy]);

    useEffect(() => {
        const [rowsFromFunc, pageFromFunc] = getPageAndSize();

        if (totalCount <= rowsFromFunc * pageFromFunc) onChangePage(null, 0);

    }, [onChangePage, totalCount, getPageAndSize]);

    useEffect(() => {
        if (pageIndex !== page) setPage(pageIndex);
    }, [pageIndex, setPage]);
    //---- HANDLE TABLE END ----//

    //---- HANDLE COLUMN VISIBILITY - START -----//
    const [columns, setColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(columns[context] || (() => datagridColumns.reduce((prev, curr) => {
        Object.assign(prev, { [curr.field]: true });

        return prev;
    }, {})));

    useEffect(() => {
        setColumns({ ...columns, [context]: visibility });
    }, [visibility]);
    //---- HANDLE COLUMN VISIBILITY - END -----//

    return (
        <>
            <Card>

                {(filtersInSidebar || listDescription) &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: { xs: 2, md: 3.5 },
                            pt: 1.5,
                            pb: (quickFilters || !(toolbarFiltersList || datesGeneralFilter?.showDates)) ? 1.5 : 0
                        }}
                    >
                        {listDescription ?
                            <Box>
                                <Typography variant="body2">
                                    {listDescription}
                                </Typography>
                            </Box>
                            :
                            <Box />
                        }

                        <FormProvider methods={methods}>
                            <Box sx={{ display: 'flex', alignItems: "center", gap: 1 }}>

                                {(reportFilters && ReportTypeDataArr.includes(context as ReportTypeData)) &&
                                    <LicenseGuard license={LicenseTypes.Report}>
                                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Report_View]}>
                                            <ReportCreationComponent allFilters={reportFilters} reportType={context as ReportTypeData} />
                                        </PermissionBasedGuard>
                                    </LicenseGuard>
                                }

                                <VisibilityModelComponent
                                    columns={datagridColumns}
                                    model={visibility}
                                    onChangeModel={setVisibility}
                                />

                                {filtersInSidebar &&
                                    <GenericFilterSidebar
                                        isOpen={openSidebar}
                                        onOpen={handleOpenSidebar}
                                        onClose={handleCloseSidebar}
                                        onFilter={handleSearchFilters}
                                        onResetAll={handleResetAllFilter}
                                        defaultFilters={defaultFilters}
                                        resetTrigger={resetForm}
                                        filterValues={{ ...formValues, ...(getFiltersFromUrl) }}
                                        filterList={filtersInSidebar}
                                        isDefault={isDefault}
                                        setShowSummary={setShowSummary}
                                        customfieldContext={customfieldContext ? customfieldContext : [context]}
                                        resetFormElement={resetFormElement}
                                        setResetFormElement={setResetFormElement}
                                    />
                                }
                            </Box>
                        </FormProvider>

                    </Box>
                }

                {(quickFilters && renderQuickFilters) &&
                    <>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons={!isDesktop}
                            value={filterStatus}
                            onChange={(e, value) => {
                                setPage(0);
                                if (onChangeFilterStatus) onChangeFilterStatus(e, value);
                            }}
                            sx={{
                                px: { xs: 0, md: 2 },
                                bgcolor: 'background.neutral'
                            }}
                        >
                            {quickFilters.map((tab) => (
                                <Tab
                                    disableRipple
                                    key={tab.key}
                                    label={tab.label}
                                    value={tab.key}
                                    icon={
                                        <Label color={tab?.color} sx={{ mr: 1 }}>
                                            {renderQuickFilters(tab.key)}
                                        </Label>
                                    }
                                />
                            ))}
                        </Tabs>

                        <Divider />
                    </>
                }

                {(toolbarFiltersList || datesGeneralFilter?.showDates) &&
                    <FormProvider methods={methods}>
                        <GenericFilterToolbar
                            filterValues={{ ...formValues, ...getFiltersFromUrl }}
                            defaultFiltersValues={defaultFilters}
                            onSearch={handleSearchFilters}
                            onResetAll={handleResetAllFilter}
                            optionsFields={(toolbarFiltersList && toolbarFiltersList.length > 0) ? toolbarFiltersList : undefined}
                            datesGeneralFilter={datesGeneralFilter}
                            showSummary={showSummary}
                            setShowSummary={setShowSummary}
                            propFullKeysToRemove={statsKeysToDelete}
                        />
                    </FormProvider>}

                {filtersInSidebar &&
                    <GenericFilterSummary
                        showSummary={showSummary && !openSidebar && !isDefault({ ...formValues, ...(getFiltersFromUrl) })}
                        defaultFilters={defaultFilters}
                        filterValues={{ ...formValues, ...getFiltersFromUrl }}
                        filterList={filtersInSidebar}
                        onResetFilter={handleResetSingleFilter}
                        onResetAll={handleResetAllFilter}
                        customfieldContext={customfieldContext ? customfieldContext : [context]}
                        isToolbarShown={!!(toolbarFiltersList || datesGeneralFilter?.showDates)}
                    />
                }

                <Divider />

                <Box>
                    <DataGrid
                        rows={list}
                        columns={datagridColumns}
                        pagination
                        disableColumnResize
                        paginationModel={{
                            page: page,
                            pageSize: rowsPerPage
                        }}
                        density={(dense && list.length > 0) ? 'compact' : 'standard'}
                        sortingMode={"server"}
                        onSortModelChange={handleSort}
                        loading={isLoading}
                        columnVisibilityModel={visibility}
                        slots={{
                            noRowsOverlay: noData,
                            footer: () => (
                                <>
                                    {list.length === 0 && <Divider />}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: { xs: "column", sm: "row" },
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            height: { xs: "auto", sm: 56 },
                                            px: { xs: 0, sm: 4 },
                                            backgroundColor: "background.neutral"
                                        }}
                                    >
                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={translate('commons.dense')}
                                        />
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={translate('commons.rowsPerPage')}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                </>
                            )
                        }}
                        disableColumnMenu
                        pageSizeOptions={[5, 10, 15, 30]}
                        onCellClick={(params) => {
                            setActualRow(params);

                            if (!window.getSelection()?.toString() && handleCellClick)
                                handleCellClick(params);
                        }}
                        sx={{
                            ...DataGridStyle,
                            height: getHeight(),
                            maxHeight: getMaxHeight(),
                            '& .MuiDataGrid-cell': {
                                cursor: handleCellClick ? 'pointer' : 'default'
                            }
                        }}
                    />
                </Box>

            </Card>

            {showVatAdvice &&
                <Typography sx={{ textAlign: 'right', mt: 2, mr: 3 }} fontSize={'13px'}>
                    {translate('orders.messages.vatAdvice')}
                </Typography>
            }
        </>
    );
} 