import { Box, Divider, IconButton, InputAdornment, Stack, Switch, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useLocales } from 'src/locales';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { brandOperations } from 'src/redux/brand';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { OrganizationSearchResult } from 'src/@types/organizations';
import { DeliveryAddressAutocomplete } from './DetailNewParts';
import { DEFAULT_WARRANTY_AR, DEFAULT_WARRANTY_NAR, ItemSearchResult, RequestNew, Warranty, WarrantyInvoice } from 'src/@types/request';
import { useSettingsContext } from 'src/components/settings';
import { debounce } from 'lodash';
import { Settings } from '@mui/icons-material';
import IncrementalInput from 'src/utils/IncrementalInput';
import { maxQuantity } from 'src/services/requestsServices';
import { fortyAlertCheck, FortyDaysAlert } from 'src/components/request-utils/FeesAndNotes';
import { FileWithSection } from 'src/@types/media';

export const WarrantyDetailStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  p: 2
};

interface DetailWarrantyProps {
  onChange: (name: string, value: any, item?: boolean) => void,
  available: number,
  defaultAddress: OrganizationSearchResult,
  request: RequestNew,
  files: FileWithSection[],
  ar: boolean
  parts: ItemSearchResult[],
  organization: string,
  isVendor: boolean
}

export default function DetailWarranty({ onChange, available, defaultAddress, request, files, ar, parts, isVendor, organization }: DetailWarrantyProps) {

  const { translate } = useLocales();

  const { currency: currentCurrency, metricSystem: currentMetricSystem } = useSettingsContext();

  const { publicBrandList } = useSelector((state: RootState) => state.brand);

  const [quantity, setQuantity] = useState(request.items[0]?.quantity || 0);

  const [warranty, setWarranty] = useState<Warranty>(request.warranty || (ar ? DEFAULT_WARRANTY_AR : DEFAULT_WARRANTY_NAR));

  const [showCurrency, setShowCurrency] = useState(!!(request.warranty?.ratePerHour?.withoutVat));

  const [showMileage2, setShowMileage2] = useState(!!(request.warranty?.invoices[2]?.mileage));

  const [showMileage3, setShowMileage3] = useState(!!(request.warranty?.invoices[3]?.mileage));

  const [availableQty, setAvailableQty] = useState<{ id: string, qty: number }[]>([]);

  const checkRerender = useRef(true);

  const [file, setFile] = useState<Record<string, (File & { section?: string } | null)>>(files.reduce((rec, current) => {
    rec[current.section!] = current;

    return rec;

  }, {} as Record<string, File & { section?: string }>));

  useEffect(() => {

    if (checkRerender.current) {

      request.items.forEach((i) => maxQuantity({ orderId: i.orderId!, productId: i.productId })
        .then(v => setAvailableQty(prev => prev.concat([{ id: i.productId, qty: v.data.quantity }]))));

      checkRerender.current = false;
    }
  }, []);

  useEffect(() => {
    onChange('ar', ar);

    if (!publicBrandList) dispatch(brandOperations.getBrandList(false));

  }, [publicBrandList, ar]);

  const handleDrop = (acceptedFiles: File[], inputName: string) => {
    setFile((prevFileObjects) => ({
      ...prevFileObjects,
      [inputName]: acceptedFiles[0],
    }));
  };

  useEffect(() => {

    const files = Object.entries(file).filter(([_, v]) => !!v).map(([k, v]) => {

      return Object.assign(v!, { section: k });
    });

    onChange('images', files);
  }, [file]);

  useEffect(() => {

    onChange('warranty', warranty);

  }, [warranty]);

  const handleDelete = (name: string) => {
    setFile((prevFileObjects) => ({
      ...prevFileObjects,
      [name]: null,
    }));
  };

  const onChangeDate = (value: Date | null, indexToCheck: number) => {
    if (value) {
      const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], date: format(value, 'yyyy-MM-dd') };

      setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
    }
  };

  const onChangeInvoiceNum = (value: string, indexToCheck: number) => {
    if (/^[0-9]*$/.test(value) || ar) {
      const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], number: value };

      setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
    }
  };

  const onChangeMileage = (value: number, indexToCheck: number) => {
    if (!isNaN(value)) {
      const newInvoice: WarrantyInvoice = { ...warranty.invoices[indexToCheck], mileage: value };

      setWarranty({ ...warranty, invoices: warranty.invoices.map((invoice, index) => index === indexToCheck ? newInvoice : invoice) });
    }
  };

  useEffect(() => {
    if (!ar)
      setWarranty(prev => ({ ...prev, ratePerHour: { ...prev.ratePerHour!, currentCurrency: currentCurrency.label } }));
  }, [currentCurrency, ar]);

  const setWarrantyDelayed = debounce((obj) => setWarranty(obj), 300);

  const handleQuantity = (ind: number, quantity: number) => {

    let { items } = request;

    items[ind].quantity = quantity;

    onChange("", items, true);
  };

  const showFortyAlert = useMemo(() => {

    if (warranty.invoices[0].date) return fortyAlertCheck(new Date(warranty.invoices[0].date), new Date());

    return false;
  }, [warranty.invoices]);

  return (
    <>
      {!ar ? (
        <Box sx={{ ...WarrantyDetailStyle }}>

          <Typography variant="h6">{`${translate('request.form.complete')}`}</Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: '#E2E2E2',
            }}
          >
            <Stack sx={{ m: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('commons.quantity')}`}</Typography>
              <Typography variant="body2">{`${translate('request.selectQty')}`}</Typography>
            </Stack>

            <Box
              sx={{
                m: 2, mr: 2, ml: 'auto',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
                {`${translate('statuses.available')}`}: {available}
              </Typography>
              <TextField
                sx={{ width: '40%' }}
                type="number"
                value={quantity + ''}
                onChange={(e) => {
                  let val = +e.target.value < 0 || +e.target.value > available ? 0 : +e.target.value;
                  setQuantity(val);
                  onChange('quantity', val, true);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
                          onChange('quantity', quantity > 0 ? quantity - 1 : 0, true);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setQuantity((prev) => (prev < available ? prev + 1 : available));
                          onChange('quantity', quantity < available ? quantity + 1 : available, true);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box>
            <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} isVendor={isVendor} organization={organization} />
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: '#E2E2E2',
            }}
          >
            <Stack sx={{ m: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('request.big')}`}</Typography>
              <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
            </Stack>
            <Box sx={{ m: 2, ml: 'auto' }}>
              <Switch
                checked={request.items[0]?.bigAndHeavy || false}
                name="bigAndHeavy"
                onChange={(e) => onChange(e.target.name, e.target.checked, true)}
              />
            </Box>
          </Box>

          <Box sx={{ my: 2 }}>

            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.firstInvoice')}`}</Typography>

              {!file.firstInvoice ? (
                <Drop inputName="firstInvoice" onDrop={(files) => handleDrop(files, 'firstInvoice')} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    background: '#F4F6F8',
                    p: 2,
                    borderRadius: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                    <InsertDriveFileIcon />
                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                      {file.firstInvoice.name}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ ml: 'auto', color: 'inherit' }}
                    onClick={() => handleDelete('firstInvoice')}
                  >
                    <DeleteForeverIcon color="inherit" />
                  </IconButton>
                </Box>
              )}

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                <TextField
                  required
                  label={`${translate('request.invoiceNum')}`}
                  onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 0)}
                  value={warranty.invoices[0].number || ""}
                />
                <DatePicker
                  disableFuture
                  label={`${translate('request.invoiceDate')}`}
                  views={['year', 'month', 'day']}
                  format={'dd/MM/yyyy'}
                  value={warranty.invoices[0].date ? new Date(warranty.invoices[0].date) : null}
                  onAccept={(value) => onChangeDate(value, 0)}
                  onChange={(fromDateValue, inputValue) => {
                    if (!inputValue.validationError) {
                      onChangeDate(fromDateValue, 0);
                    }
                  }}
                  slotProps={{ textField: { required: true } }}
                />
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.secondInvoice')}`}</Typography>

              {!file.secondInvoice ? (
                <Drop inputName="secondInvoice" onDrop={(files) => handleDrop(files, 'secondInvoice')} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    background: '#F4F6F8',
                    p: 2,
                    borderRadius: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                    <InsertDriveFileIcon />
                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                      {file.secondInvoice.name}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ ml: 'auto', color: 'inherit' }}
                    onClick={() => handleDelete('secondInvoice')}
                  >
                    <DeleteForeverIcon color="inherit" />
                  </IconButton>
                </Box>
              )}

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                <TextField
                  required
                  label={`${translate('request.invoiceNum')}`}
                  onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 1)}
                  value={warranty.invoices[1].number || ""}
                />
                <DatePicker
                  disableFuture
                  label={`${translate('request.invoiceDate')}`}
                  views={['year', 'month', 'day']}
                  format={'dd/MM/yyyy'}
                  value={warranty.invoices[1].date ? new Date(warranty.invoices[1].date) : null}
                  onAccept={(value) => onChangeDate(value, 1)}
                  onChange={(fromDateValue, inputValue) => {
                    if (!inputValue.validationError) {
                      onChangeDate(fromDateValue, 1);
                    }
                  }}
                  slotProps={{ textField: { required: true } }}
                />
              </Box>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.vehicleInfo')}`}</Typography>

              {!file.vehicleInfo ? (
                <Drop
                  inputName="vehicleInfo"
                  onDrop={(files) => handleDrop(files, 'vehicleInfo')}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    background: '#F4F6F8',
                    p: 2,
                    borderRadius: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                    <InsertDriveFileIcon />
                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                      {file.vehicleInfo.name}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ ml: 'auto', color: 'inherit' }}
                    onClick={() => handleDelete('vehicleInfo')}
                  >
                    <DeleteForeverIcon color="inherit" />
                  </IconButton>
                </Box>
              )}

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>

                <TextField
                  label={`${translate('request.form.vehicleBrand')}`}
                  required
                  value={warranty.vehicle?.brand}
                  onChange={(e) => {
                    setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, brand: e.target.value } });
                  }}
                />

                <TextField
                  name="model"
                  required
                  label={`${translate('commons.model')}`}
                  value={warranty.vehicle?.model}
                  onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, model: e.target.value } })}
                />

                <TextField
                  required
                  label={`${translate('commons.vin')}`}
                  onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, vin: e.currentTarget.value } })}
                  value={warranty.vehicle?.vin}
                />

                <TextField
                  required
                  label={`${translate('commons.plate')}`}
                  onChange={(e) => setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, plate: e.currentTarget.value } })}
                  value={warranty.vehicle?.plate}
                />

                <DatePicker
                  disableFuture
                  label={`${translate('request.form.dateAdmission')}`}
                  views={['year', 'month', 'day']}
                  format={'dd/MM/yyyy'}
                  value={warranty.vehicle?.registrationDate ? new Date(warranty.vehicle.registrationDate) : null}
                  onAccept={(value) => {
                    if (value) setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, registrationDate: format(value, 'yyyy-MM-dd') } });
                  }}
                  onChange={(fromDateValue, inputValue) => {
                    if (!inputValue.validationError && fromDateValue) setWarranty({ ...warranty, vehicle: { ...warranty.vehicle!, registrationDate: format(fromDateValue, 'yyyy-MM-dd') } });
                  }}
                  slotProps={{ textField: { required: true } }}
                />
              </Box>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('request.form.invoiceCustomer')}`}</Typography>

            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>{`${translate('request.form.invoiceRepair')}`}

              </Typography>
              {!file.firstRepairInvoice ? (
                <Drop inputName="firstRepairInvoice" onDrop={(file) => handleDrop(file, 'firstRepairInvoice')} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    background: '#F4F6F8',
                    p: 2,
                    borderRadius: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                    <InsertDriveFileIcon />
                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                      {file.firstRepairInvoice.name}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ ml: 'auto', color: 'inherit' }}
                    onClick={() => handleDelete('firstRepairInvoice')}
                  >
                    <DeleteForeverIcon color="inherit" />
                  </IconButton>
                </Box>
              )}

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2, "& MuiOutlinedInput-root": { pr: 0 } }}>

                <TextField
                  required
                  label={`${translate('request.invoiceNum')}`}
                  onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 2)}
                  value={warranty.invoices[2].number || ""}
                />
                <DatePicker
                  disableFuture
                  label={`${translate('request.invoiceDate')}`}
                  views={['year', 'month', 'day']}
                  format={'dd/MM/yyyy'}
                  value={warranty.invoices[2].date ? new Date(warranty.invoices[2].date) : null}
                  onAccept={(value) => onChangeDate(value, 2)}
                  onChange={(fromDateValue, inputValue) => {
                    if (!inputValue.validationError) {
                      onChangeDate(fromDateValue, 2);
                    }
                  }}
                  slotProps={{ textField: { required: true } }}
                />
                <TextField
                  required
                  label={`${translate('commons.mileage')}`}
                  value={warranty.invoices[2].mileage || ""}
                  onChange={(e) => onChangeMileage(+e.currentTarget.value, 2)}
                  InputProps={{ endAdornment: showMileage2 ? <InputAdornment position="start">{currentMetricSystem ? "KM" : "MI"}</InputAdornment> : null }}
                  onFocus={() => { if (!showMileage2) setShowMileage2(true); }}
                  onBlur={() => { if (showMileage2 && !warranty.invoices[2].mileage) setShowMileage2(false); }}
                />
              </Box>
            </Box>

            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>{`${translate('request.form.invoiceSecondRepair')}`}</Typography>

              {!file.secondRepairInvoice ? (
                <Drop
                  inputName="secondRepairInvoice"
                  onDrop={(file) => handleDrop(file, 'secondRepairInvoice')}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    background: '#F4F6F8',
                    p: 2,
                    borderRadius: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                    <InsertDriveFileIcon />
                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                      {file.secondRepairInvoice.name}
                    </Typography>
                  </Box>

                  <IconButton
                    sx={{ ml: 'auto', color: 'inherit' }}
                    onClick={() => handleDelete('secondRepairInvoice')}
                  >
                    <DeleteForeverIcon color="inherit" />
                  </IconButton>
                </Box>
              )}

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>

                <TextField
                  required
                  label={`${translate('request.invoiceNum')}`}
                  onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 3)}
                  value={warranty.invoices[3].number || ""}
                />
                <DatePicker
                  disableFuture
                  label={`${translate('request.invoiceDate')}`}
                  views={['year', 'month', 'day']}
                  format={'dd/MM/yyyy'}
                  value={warranty.invoices[3].date ? new Date(warranty.invoices[3].date) : null}
                  onAccept={(value) => onChangeDate(value, 3)}
                  onChange={(fromDateValue, inputValue) => {
                    if (!inputValue.validationError) {
                      onChangeDate(fromDateValue, 3);
                    }
                  }}
                  slotProps={{ textField: { required: true } }}
                />
                <TextField
                  required
                  label={`${translate('commons.mileage')}`}
                  value={warranty.invoices[3].mileage || ""}
                  onChange={(e) => onChangeMileage(+e.currentTarget.value, 3)}
                  InputProps={{ endAdornment: showMileage3 ? <InputAdornment position="start">{currentMetricSystem ? "KM" : "MI"}</InputAdornment> : null }}
                  onFocus={() => { if (!showMileage3) setShowMileage3(true); }}
                  onBlur={() => { if (showMileage3 && !warranty.invoices[3].mileage) setShowMileage3(false); }}
                />

              </Box>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>{`${translate('commons.moreDetail')}`}</Typography>

              <Box sx={{ my: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">{`${translate('request.issueDescription')}`}</Typography>
                  <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={`${translate('request.form.notePlaceholder')}`}
                  defaultValue={warranty.note}
                  onChange={(e) => setWarrantyDelayed({ ...warranty, note: e.currentTarget.value })}
                  error={warranty.note!.length > 72}
                  helperText={warranty.note!.length > 72 && `${translate('request.noteErrorText')}`}
                  sx={{ mt: 1 }}
                />
              </Box>

              <Box sx={{ my: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">{`${translate('request.diagnosis')}`}</Typography>
                  <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={`${translate('request.form.notePlaceholder')}`}
                  defaultValue={warranty.diagnosis}
                  onChange={(e) => setWarrantyDelayed({ ...warranty, diagnosis: e.currentTarget.value })}
                  error={warranty.diagnosis!.length > 72}
                  helperText={warranty.diagnosis!.length > 72 && `${translate('request.noteErrorText')}`}
                  sx={{ mt: 1 }}
                />
              </Box>

              <Box sx={{ my: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">{`${translate('request.remedy')}`}</Typography>
                  <Typography variant="subtitle1" sx={{ color: 'error.main' }}>*</Typography>
                </Box>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  placeholder={`${translate('request.form.notePlaceholder')}`}
                  defaultValue={warranty.solution}
                  onChange={(e) => setWarrantyDelayed({ ...warranty, solution: e.currentTarget.value })}
                  error={warranty.solution!.length > 72}
                  helperText={warranty.solution!.length > 72 && `${translate('request.noteErrorText')}`}
                  sx={{ mt: 1 }}
                />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, my: 2 }}>
                <TextField
                  label={`${translate('request.timeRepair')}`}
                  type={"number"}
                  value={warranty.hoursSpent || ""}
                  onChange={(e) => setWarranty({ ...warranty, hoursSpent: Number(e.currentTarget.value) })}
                />
                <TextField
                  label={`${translate('request.hourRateRepair')}`}
                  type={"number"}
                  value={warranty.ratePerHour!.withoutVat || ""}
                  onChange={(e) => setWarranty({ ...warranty, ratePerHour: { ...warranty.ratePerHour!, withoutVat: Number(e.currentTarget.value) } })}
                  InputProps={{ startAdornment: showCurrency ? <InputAdornment position="start">{currentCurrency.symbol}</InputAdornment> : null }}
                  onFocus={() => { if (!showCurrency) setShowCurrency(true); }}
                  onBlur={() => { if (showCurrency && !warranty.ratePerHour!.withoutVat) setShowCurrency(false); }}
                />

              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ ...WarrantyDetailStyle }}>

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {`${translate('request.form.attachDoc')}`}
            </Typography>

            {!file.maddDoc ? (
              <Drop inputName="madd" onDrop={(files) => handleDrop(files, 'maddDoc')} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  background: '#F4F6F8',
                  p: 2,
                  borderRadius: '8px',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                  <InsertDriveFileIcon />
                  <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                    {file.maddDoc.name}
                  </Typography>
                </Box>
                <IconButton
                  sx={{ ml: 'auto', color: 'inherit' }}
                  onClick={() => handleDelete('maddDoc')}
                >
                  <DeleteForeverIcon color="inherit" />
                </IconButton>
              </Box>
            )}

            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, mt: 2, mb: showFortyAlert ? 0 : 2 }}>

              <TextField
                required
                label={`${translate('request.form.maddNum')}`}
                onChange={(e) => onChangeInvoiceNum(e.currentTarget.value.toString(), 0)}
                value={warranty.invoices[0].number || ""}
              />
              <DatePicker
                disableFuture
                label={`${translate('request.form.maddDate')}`}
                views={['year', 'month', 'day']}
                format={'dd/MM/yyyy'}
                value={warranty.invoices[0].date ? new Date(warranty.invoices[0].date) : null}
                onAccept={(value) => onChangeDate(value, 0)}
                onChange={(fromDateValue, inputValue) => {
                  if (!inputValue.validationError) {
                    onChangeDate(fromDateValue, 0);
                  }
                }}
                slotProps={{ textField: { required: true } }}
              />
            </Box>
          </Box>

          {showFortyAlert && <FortyDaysAlert />}

          <Divider />

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {`${translate('request.form.searchMadd')}`}
            </Typography>

            {parts && parts.map((p, ind) => (
              <Box key={ind} sx={{ m: 3, display: 'flex', width: '100%', flexDirection: 'row' }}>
                <Box sx={{ mr: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Settings />
                  <Typography>
                    {p.itemExternalId ? p.itemExternalId + ' - ' : ''}
                    {p.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mr: 3,
                    ml: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'end',
                  }}
                >

                  <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>{`${translate(
                    'request.form.qtyReceived'
                  )}`}: {availableQty.find(i => i.id === p.itemId)?.qty || 0}</Typography>

                  <IncrementalInput
                    maxValue={availableQty.find(i => i.id === p.itemId)?.qty || 0}
                    onChange={(_, val) => handleQuantity(ind, +val)}
                    defaultValue={
                      request.items.find(v => v.productId === p.itemId)?.quantity || 0
                    }
                    disabled={request.requestType === 'Core'}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          <Divider sx={{ mb: 1 }} />

          <Box>
            <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} organization={organization} isVendor={isVendor} />
          </Box>

        </Box>
      )}
    </>
  );
}

function Drop(props: { inputName: string; onDrop: (file: File[]) => void }) {
  const { translate } = useLocales();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.jpg', '.jpeg', '.png']
    },
    maxSize: 5000000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      props.onDrop(
        acceptedFiles
      );
    },
  });

  return (
    <div
      {...getRootProps({
        style: {
          display: 'flex',
          padding: '20px',
          height: '150px',
          width: '100%',
          borderRadius: '8px',
          background: '#F4F6F8',
          color: '#6D6D6D',
          outline: 'none',
          cursor: 'pointer',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
      })}
    >
      <input {...getInputProps()} name={props.inputName} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <UploadFileRoundedIcon />
        <Typography color="inherit" variant="caption">
          {' '}
          {`${translate('request.form.uploadFile')}`}
        </Typography>
      </Box>
    </div>
  );
}
