import { Box, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { GridCellParams, GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { SingleGridElementProps, ToolbarListFilters } from 'src/@types/list';
import { ShopBundleFilters, DEFAULT_SHOP_BUNDLE_FILTERS, ShopBundleSearchResult, ShopBundleAvailability } from 'src/@types/webshop';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { CurrencyTypes } from 'src/@types/vehicle';
import { webshopOperations } from 'src/redux/webshop';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import SvgColor from 'src/components/svg-color';
import { ThemeModeValue, useSettingsContext } from 'src/components/settings';
import { getPrice } from 'src/utils/currency';
import Label from 'src/components/label';
import { getFirstLowerCase } from 'src/appUtils/string';
import IncrementerButton from 'src/components/custom-input/IncrementerButton';
import { basketOperations } from 'src/redux/basket';
import { getQuantityInBasket } from 'src/utils/basket';
import CheckIcon from '@mui/icons-material/Check';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MenuPopover from 'src/components/menu-popover';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { LoadingButton } from '@mui/lab';

interface ProductsListDetailProps {
    isPublic?: boolean
}

export default function ProductsList({ isPublic }: ProductsListDetailProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const navigate = useNavigate();

    const { themeMode } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { id: webshopId } = useParams();

    const { isBundleLoading: isProductLoading, bundleList: productList, bundleTotalCount: productTotalCount } = useSelector((state: RootState) => state.webshop);

    const { basket } = useSelector((state: RootState) => state.basket);

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: ShopBundleFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_SHOP_BUNDLE_FILTERS[field])
        , []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((filters: { filters: ShopBundleFilters & { sequenceToken: any }, check: boolean }) => {
        if (webshopId) dispatch(webshopOperations.searchShopBundles({ ...filters, shopId: webshopId, isPublic: isPublic }));
    }, [isPublic, webshopId]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'name', label: translate('spareParts.list.tableHeaders.partDesc') },
            { key: 'externalId', label: translate('spareParts.list.tableHeaders.partNumber') },
            { key: 'familySupplier', label: translate('webshop.detail.products.familySupplier') },
        ], [translate]);

    //---- HANDLE TABLE START ----//
    const [actualItem, setActualItem] = useState<GridCellParams<ShopBundleSearchResult> | null>(null);

    const [actualCardItem, setActualCardItem] = useState<ShopBundleSearchResult | null>(null);

    const [openCartMenu, setOpenCartMenu] = useState<HTMLElement | null>(null);

    const hasPermissionToBuy = hasPermissions([OrganizationPermissionTypes.WebShop_Buy], organization.roles);

    const handleOpenCartMenu = (event: React.MouseEvent<HTMLElement>) => setOpenCartMenu(event.currentTarget);

    const handleCloseCartMenu = () => setOpenCartMenu(null);

    const handleCellClick = (params: GridCellParams<ShopBundleSearchResult>) => {

        setActualItem(params);

        if (params.field !== "addToCart" && params.field !== "availability.stockQuantity" && hasPermissionToBuy) {
            navigate(PATH_DASHBOARD.webshopPublic.productDetail(webshopId ?? "", params.row.bundleId));
        }
    };

    const [quantitiesRecord, setQuantitiesRecord] = useState<Record<string, number>>({});

    const [addedQuantitiesRecord, setAddedQuantitiesRecord] = useState<Record<string, number>>({});

    const handleAddQuantity = useCallback((bundleId: string) => ({
        ...quantitiesRecord,
        [bundleId]: 1
    }), [quantitiesRecord]);

    const handleRemoveQuantity = useCallback((bundleId: string) => {
        const updatedRecord = cloneDeep(quantitiesRecord);

        delete updatedRecord[bundleId];

        return updatedRecord;
    }, [quantitiesRecord]);

    const handleChangeQuantity = useCallback((bundleId: string, action: "increase" | "decrease") => {
        setQuantitiesRecord((prev) => {
            const updatedRecord = { ...prev };

            if (action === "increase") {
                if (updatedRecord[bundleId]) updatedRecord[bundleId] += 1;
                else return handleAddQuantity(bundleId);
            } else {
                updatedRecord[bundleId] -= 1;
                if (!updatedRecord[bundleId]) return handleRemoveQuantity(bundleId);
            }

            return updatedRecord;
        });
    }, [handleAddQuantity, handleRemoveQuantity]);

    const handleAddToCart = useCallback(async (bundleId: string, quantity: number) => {
        try {
            await dispatch(basketOperations.addItemPublicBasket({
                bundleId,
                shopId: webshopId!,
                quantity
            })).unwrap();

            setQuantitiesRecord(handleRemoveQuantity(bundleId));

            setAddedQuantitiesRecord((prev) => ({
                ...prev,
                [bundleId]: quantity
            }));
        } catch (e) {
            console.error(e);
        }
    }, [handleRemoveQuantity, webshopId]);

    const getQuantityInBasketPrefilled = useCallback((bundleId: string) => getQuantityInBasket(basket, webshopId!, bundleId), [basket, webshopId]);

    const COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', alignItems: "center" }}>

                    {obj.row.items[0].media[0] ?
                        <img
                            alt={""}
                            src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, flexGrow: 1 }}>
                        <Typography variant='subtitle2' sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {obj.row.items[0].family && <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            <b>{"[" + obj.row.items[0].family.supplier.code + "] - "}</b>{obj.row.items[0].family.supplier.description}
                        </Typography>}
                    </Box>
                </Box>
        },
        {
            field: 'externalId',
            headerName: translate('spareParts.list.tableHeaders.partNr'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.grossAmount)}
                        currency={obj.row.price.grossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.grossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    obj.row.price.unitDiscountedAmount ?
                        <CurrencyAdapter
                            value={getPrice(obj.row.price.unitDiscountedAmount)}
                            currency={obj.row.price.unitDiscountedAmount?.currentCurrency}
                            noVatAsterisk={!!obj.row.price.unitDiscountedAmount?.withoutVat}
                            fontSize={'0.875rem'}
                        />
                        :
                        <></>
                );
            }
        },
        {
            field: 'availability.stockQuantity',
            headerName: translate('spareParts.list.tableHeaders.stockQuantity'),
            flex: isDesktop ? 0.4 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            valueGetter: (_, row) => row.availability.stockQuantity ?? "—"
        },
        {
            field: 'score',
            headerName: translate('webshop.detail.products.score'),
            flex: isDesktop ? 0.7 : undefined, //(0.7)
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <ProgressIcon progress={obj.row.score} themeMode={themeMode} />
                );
            }
        }
    ], [translate, isDesktop, themeMode]);

    const PUBLIC_COLUMNS: GridColDef<ShopBundleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('webshop.detail.products.single'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'grid', gridTemplateColumns: '20% 80%', alignItems: "center", width: '100%' }}>

                    {obj.row.items[0].media[0] ?
                        <img
                            alt={""}
                            src={obj.row.items[0].media[0]?.fileInfo[0]?.url ?? ""}
                            style={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                aspectRatio: "1",
                                objectFit: "cover",
                                border: "1px ridge"
                            }}
                        />
                        :
                        <Box
                            sx={{
                                borderRadius: "15px",
                                width: "45px",
                                height: "45px",
                                border: "1px ridge",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: (theme) => theme.palette.divider
                            }}
                        >
                            <NoPhotographyIcon sx={{ width: "30px", height: "30px" }} />
                        </Box>

                    }

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography variant={'subtitle2'} sx={{ mb: -0.5 }}>
                            {obj.row.items[0].name}
                        </Typography>
                        {obj.row.items[0].family && <Typography variant={'body2'} textOverflow={'ellipsis'} overflow={'hidden'}>
                            <b>{"[" + obj.row.items[0].family.supplier.code + "] - "}</b>{obj.row.items[0].family.supplier.description}
                        </Typography>}
                    </Box>
                </Box>
        },
        {
            field: 'externalId',
            headerName: translate('spareParts.list.tableHeaders.partNr'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'grossAmount',
            headerName: translate('webshop.detail.products.grossAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.price.grossAmount)}
                        currency={obj.row.price.grossAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.price.grossAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'unitDiscountedAmount',
            headerName: translate('webshop.detail.products.discountedAmount'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <></>
                    // <CurrencyAdapter
                    //     value={getPrice(obj.row.price.unitDiscountedAmount)}
                    //     currency={obj.row.price.unitDiscountedAmount.currentCurrency}
                    //     noVatAsterisk={!!obj.row.price.unitDiscountedAmount.withoutVat}
                    //     fontSize={'0.875rem'}
                    // />
                );
            }
        },
        /*         {
                    field: 'unitDiscountAmount',
                    headerName: translate('webshop.detail.products.discountAmount'),
                    flex: isDesktop ? 0.5 : undefined,
                    minWidth: !isDesktop ? 250 : undefined,
                    renderCell: (obj) => {
                        return (
                            <CurrencyAdapter
                                value={getPrice(obj.row.price.unitDiscountAmount)}
                                currency={obj.row.price.unitDiscountAmount.currentCurrency}
                                noVatAsterisk={!!obj.row.price.unitDiscountAmount.withoutVat}
                                fontSize={'0.875rem'}
                            />
                        );
                    }
                }, */
        {
            field: 'availability.status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 0.55 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (obj.row.availability.status !== "InStock" &&
                    <Label
                        color={obj.row.availability.status === "BackOrder" ? 'info' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.${getFirstLowerCase(obj.row.availability.status)}`)}
                    </Label>
                );
            }
        },
        ...((hasPermissions([OrganizationPermissionTypes.WebShop_Buy], organization.roles)) ? [
            {
                field: 'availability.stockQuantity',
                headerName: "",
                flex: isDesktop ? 0.8 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                sortable: false,
                renderCell: (obj) => {
                    return (
                        <AvailabilityIncrementer
                            translate={translate}
                            bundleId={obj.row.bundleId}
                            availability={obj.row.availability}
                            quantitiesRecord={quantitiesRecord}
                            handleChangeQuantity={handleChangeQuantity}
                            getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                        />
                    );
                }
            },
            {
                field: 'addToCart',
                headerName: "",
                flex: isDesktop ? 0.5 : undefined,
                minWidth: !isDesktop ? 250 : undefined,
                sortable: false,
                align: "right",
                renderCell: (obj) => {

                    const quantity: number = quantitiesRecord[obj.row.bundleId] ?? 0;

                    const addedQuantityToCart: number = addedQuantitiesRecord[obj.row.bundleId] ?? 0;

                    return (
                        <AddToCartButton
                            addedQuantityToCart={addedQuantityToCart}
                            bundleId={obj.row.bundleId}
                            handleAddToCart={handleAddToCart}
                            quantity={quantity}
                            translate={translate}
                        />
                    );
                }
            }] : []) as GridColDef<ShopBundleSearchResult>[]
    ], [translate, isDesktop, organization.roles, quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled, addedQuantitiesRecord, handleAddToCart]);
    // ----HANDLE TABLE END---- //

    return (
        <InfiniteScrollGenericList
            list={productList.map((bundle) => ({ ...bundle, id: bundle.bundleId, ...bundle.items[0] }))}
            isLoading={isProductLoading}
            totalCount={productTotalCount}
            defaultFilters={DEFAULT_SHOP_BUNDLE_FILTERS}
            toolbarFiltersList={toolbarFiltersList}
            datagridColumns={isPublic ? PUBLIC_COLUMNS : COLUMNS}
            handleCellClick={isPublic ? handleCellClick : undefined}
            updateCheckField={updateCheckField}
            context={"WebshopProduct"}
            setActualRow={setActualItem}
            customSearchFunc={customSearch}
            resetList={() => { }}
            showVatAdvice
            multipleView={{
                renderGridElement: SingleGridElement,
                extraProps: {
                    quantitiesRecord,
                    addedQuantitiesRecord,
                    handleChangeQuantity,
                    handleAddToCart,
                    getQuantityInBasketPrefilled,
                    webshopId,
                    hasPermissionToBuy
                },
                menuOptions: {
                    actualCardItem,
                    setActualCardItem,
                    openMenu: openCartMenu,
                    handleOpenMenu: handleOpenCartMenu,
                    handleCloseMenu: handleCloseCartMenu
                }
            }}
        />
    );
}

//-----------------------------------------------------------------------------

function SingleGridElement({ key, item: bundle, translate, navigate, extraProps, menuOptions }: SingleGridElementProps<ShopBundleSearchResult>) {

    const { quantitiesRecord, handleChangeQuantity, getQuantityInBasketPrefilled, webshopId, hasPermissionToBuy, addedQuantitiesRecord, handleAddToCart } = extraProps;

    return (
        <Card
            key={key}
            sx={{
                p: 1,
                transition: "0.5s",
                cursor: 'pointer',
                width: "100%",
                minHeight: "350px",
                height: "35vh",
                maxHeight: "435px",
                ':hover': { bgcolor: (theme) => theme.palette.action.selected }
            }}
            onClick={() => {
                if (!window.getSelection()?.toString() && hasPermissionToBuy && !menuOptions?.openMenu)
                    navigate(PATH_DASHBOARD.webshopPublic.productDetail(webshopId, bundle.bundleId));
            }}
        >

            <Box sx={{ height: "72%", display: "flex", alignItems: "center" }}>
                {bundle.items[0].media[0]?.fileInfo[0]?.url ?
                    <img
                        alt={"product"}
                        src={bundle.items[0].media[0].fileInfo[0].url}
                        style={{
                            borderRadius: "15px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            border: "1px ridge",
                            aspectRatio: "1"
                        }}
                    />
                    :
                    <NoPhotographyIcon sx={{ width: '100%', height: '7.5vh', objectFit: 'contain' }} />
                }

                {hasPermissionToBuy &&
                    <IconButton
                        onClick={(event) => {
                            menuOptions?.setActualCardItem(bundle);
                            menuOptions?.handleOpenMenu(event);
                            event.stopPropagation();
                        }}
                        sx={{
                            position: "absolute",
                            p: 1.5, pl: 1.75, pt: 1.75,
                            backgroundColor: (theme) => theme.palette.warning.main,
                            color: (theme) => theme.palette.text.primary,
                            top: "64.5%",
                            right: "5%"
                        }}
                    >
                        <AddShoppingCartIcon width={"24px"} height={"24px"} />
                    </IconButton>
                }

                <CartMenuComponent
                    handleCloseMenu={menuOptions?.handleCloseMenu!}
                    translate={translate}
                    openMenu={menuOptions?.openMenu!}
                    bundle={bundle}
                    quantitiesRecord={quantitiesRecord}
                    handleChangeQuantity={handleChangeQuantity}
                    getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                    actualCardItem={menuOptions?.actualCardItem ?? null}
                    addedQuantitiesRecord={addedQuantitiesRecord}
                    handleAddToCart={handleAddToCart}
                />
            </Box>

            <Box sx={{ mt: 2, ml: 1, display: 'flex', flexDirection: "column", gap: 1.5, height: "auto" }}>
                <Typography variant={"h6"} noWrap>
                    {bundle.items[0].name}
                </Typography>

                <Typography variant='overline' noWrap sx={{ mt: -1.5 }}>
                    <b>{"[" + bundle.items[0].family.supplier.code + "] - "}</b>{bundle.items[0].family.supplier.description}
                </Typography>

                <CurrencyAdapter
                    value={bundle.price.grossAmount.withoutVat ?? ""}
                    currency={bundle.price.grossAmount.currentCurrency}
                    variant={"subtitle1"}
                />
            </Box>

        </Card>
    );
}

type OptionsComponentProps<T> = {
    openMenu: HTMLElement | null,
    handleCloseMenu: () => void,
    translate: (text: unknown, options?: any) => string,
    bundle: ShopBundleSearchResult,
    quantitiesRecord: Record<string, number>,
    addedQuantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    handleAddToCart: (bundleId: string, quantity: number) => Promise<void>
    getQuantityInBasketPrefilled: (bundleId: string) => number,
    actualCardItem: T | null
};

function CartMenuComponent<T extends GridValidRowModel>({
    openMenu,
    handleCloseMenu,
    translate,
    bundle,
    quantitiesRecord,
    addedQuantitiesRecord,
    handleChangeQuantity,
    getQuantityInBasketPrefilled,
    actualCardItem,
    handleAddToCart
}: OptionsComponentProps<T>) {

    const { bundleId, availability } = bundle;

    const quantity: number = quantitiesRecord[bundleId] ?? 0;

    const addedQuantityToCart: number = addedQuantitiesRecord[bundleId] ?? 0;

    return ((!!actualCardItem && bundle.bundleId === actualCardItem.bundleId) &&
        <MenuPopover
            open={openMenu}
            anchorEl={openMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            arrow="right-top"
            sx={{
                mt: -1,
                minWidth: 160,
                p: 2,
                '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    '& svg': { mr: 2, width: 20, height: 20 }
                }
            }}
        >
            <AvailabilityIncrementer
                translate={translate}
                bundleId={bundleId}
                availability={availability}
                quantitiesRecord={quantitiesRecord}
                handleChangeQuantity={handleChangeQuantity}
                getQuantityInBasketPrefilled={getQuantityInBasketPrefilled}
                isCardView
            />

            <Divider sx={{ borderStyle: 'dashed', my: 1 }} />

            <AddToCartButton
                addedQuantityToCart={addedQuantityToCart}
                bundleId={bundleId}
                handleAddToCart={handleAddToCart}
                quantity={quantity}
                translate={translate}
                isCardView
            />
        </MenuPopover>
    );
}

//-----------------------------------------------------------------------------

interface ProgressIconProps {
    progress: number,
    themeMode: ThemeModeValue
}

function ProgressIcon({ progress, themeMode }: ProgressIconProps) {

    const greyValue = themeMode === "dark" ? 700 : 400;

    return (
        <Tooltip title={"Score: " + progress} arrow>
            <Box sx={{ display: "flex" }}>
                <SvgColor
                    src={'/assets/icons/utils/ic_vertical_line.svg'}
                    color={progress >= 20 ? undefined : (theme) => theme.palette.grey[greyValue]}
                />
                <SvgColor
                    src={'/assets/icons/utils/ic_vertical_line.svg'}
                    color={progress >= 40 ? undefined : (theme) => theme.palette.grey[greyValue]}
                    sx={{ ml: -2 }}
                />
                <SvgColor
                    src={'/assets/icons/utils/ic_vertical_line.svg'}
                    color={progress >= 60 ? undefined : (theme) => theme.palette.grey[greyValue]}
                    sx={{ mx: -2 }}
                />
                <SvgColor
                    src={'/assets/icons/utils/ic_vertical_line.svg'}
                    color={progress >= 80 ? undefined : (theme) => theme.palette.grey[greyValue]}
                    sx={{ mr: -2 }}
                />
                <SvgColor
                    src={'/assets/icons/utils/ic_vertical_line.svg'}
                    color={progress >= 100 ? undefined : (theme) => theme.palette.grey[greyValue]}
                />
            </Box>
        </Tooltip>
    );
}

//-----------------------------------------------------------------------------

interface AvailabilityIncrementerProps {
    translate: (text: unknown, options?: any) => string,
    bundleId: string,
    availability: ShopBundleAvailability,
    quantitiesRecord: Record<string, number>,
    handleChangeQuantity: (id: string, action: "increase" | "decrease") => void,
    getQuantityInBasketPrefilled: (bundleId: string) => number,
    isCardView?: boolean
}

function AvailabilityIncrementer({
    translate,
    bundleId,
    availability,
    quantitiesRecord,
    handleChangeQuantity,
    getQuantityInBasketPrefilled,
    isCardView = false
}: AvailabilityIncrementerProps) {

    const { availableQuantity } = availability;

    const quantity: number = quantitiesRecord[bundleId] ?? 0;

    const quantityInCart: number = getQuantityInBasketPrefilled(bundleId);

    const labelComponent: JSX.Element = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: (isCardView ? 1 : 0),
                alignItems: (isCardView ? "flex-end" : "flex-start")
            }}
        >
            <Typography variant={"caption"}>
                {translate(`statuses.available`) + ": " + availableQuantity}
            </Typography>
            {quantityInCart > 0 &&
                <Typography variant={'caption'}>
                    {"(" + translate(`webshop.detail.products.inCart`) + ": " + quantityInCart + ")"}
                </Typography>
            }
        </Box>
    );

    return (
        <Box
            sx={{
                display: (isCardView ? "flex" : "grid"),
                flexDirection: 'column',
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: (isCardView ? "flex-end" : "center")
            }}
        >

            {!isCardView && labelComponent}

            <Box sx={{ display: "flex", height: "100%", alignItems: "center", gap: 10 }}>
                {isCardView &&
                    <Typography variant={"h6"}>
                        {translate(`commons.quantity`)}
                    </Typography>
                }

                <IncrementerButton
                    quantity={quantity}
                    disabledIncrease={quantity === availableQuantity || availableQuantity === 0}
                    disabledDecrease={quantity === 0 || availableQuantity === 0}
                    onIncrease={() => handleChangeQuantity(bundleId, 'increase')}
                    onDecrease={() => handleChangeQuantity(bundleId, 'decrease')}
                    sx={{ py: 1.5, height: (isCardView ? "3vh" : "42%"), my: 0 }}
                />
            </Box>

            {isCardView && labelComponent}

        </Box>
    );
}

interface AddToCartButtonProps {
    quantity: number,
    addedQuantityToCart: number,
    translate: (text: unknown, options?: any) => string,
    handleAddToCart: (bundleId: string, quantity: number) => Promise<void>,
    bundleId: string,
    isCardView?: boolean
}

function AddToCartButton({ quantity, addedQuantityToCart, translate, handleAddToCart, bundleId, isCardView = false }: AddToCartButtonProps) {

    const { isBasketLoading } = useSelector((state: RootState) => state.basket);

    const addedLabel: JSX.Element = (
        <Label
            color={'success'}
            fontSize={"14px"}
            fontWeight={"700"}
            sx={{ borderRadius: "100px", px: 2, py: 2 }}
        >
            <CheckIcon sx={{ mr: 1 }} />
            {addedQuantityToCart + " " + translate(`webshop.detail.products.added`)}
        </Label>
    );

    return (
        <>
            {isCardView ?
                <>
                    {quantity > 0 ?
                        <LoadingButton
                            loading={isBasketLoading}
                            variant={"contained"}
                            color={"warning"}
                            fullWidth
                            onClick={() => handleAddToCart(bundleId, quantity)}
                            disabled={quantity <= 0}
                            startIcon={<AddShoppingCartIcon />}
                            sx={{ borderRadius: '100px', py: 1 }}
                        >
                            {translate('basket.detail.add')}
                        </LoadingButton>
                        :
                        (addedQuantityToCart > 0 && addedLabel)
                    }
                </>
                :
                <>
                    {quantity > 0 ?
                        <LoadingButton
                            loading={isBasketLoading}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => handleAddToCart(bundleId, quantity)}
                            sx={{ borderRadius: "100px", mr: 1 }}
                        >
                            {translate(`webshop.detail.products.addToCart`)}
                        </LoadingButton>
                        :
                        (addedQuantityToCart > 0 && addedLabel)
                    }
                </>
            }
        </>
    );
}