import { getFirstLowerCase } from "src/appUtils/string";
import { cloneDeep } from "lodash";
import { SelectItem } from "src/@types/commons";
import { InfiniteScrollListFilters, GenericListFilters } from "src/@types/list";

export const convertObjToSelectOptions = (list: any, codeField: string, labelField: string): SelectItem[] => {
    return list.map((item: any) => { return { value: item[codeField], label: item[labelField] }; });
};

export const convertArrayToSelectOptions = (
    list: any,
    translate?: (text: unknown, options?: any) => string,
    lokalizeBaseText?: string
): SelectItem[] => {
    return list.map((item: any) => (
        {
            value: item,
            label: translate ? translate(lokalizeBaseText + getFirstLowerCase(item)) : item
        }
    ));
};

function genericManageAmount(values: [number, number]): [number, number] {

    let minAmount: number = values[0];

    let maxAmount: number = values[1];

    if (minAmount > maxAmount) {
        let check = maxAmount;
        maxAmount = minAmount;
        minAmount = check;
    } else if (minAmount === maxAmount) {
        maxAmount = maxAmount + 1;
    }

    return [minAmount, maxAmount];
}

export function manageAmountFilters<Q extends InfiniteScrollListFilters<Z>, Z>(
    minField: keyof Q,
    maxField: keyof Q,
    filters: Q
): Q {

    const [minAmount, maxAmount]: [number, number] = genericManageAmount([filters[minField], filters[maxField]]);

    const newFilters = cloneDeep(filters);

    newFilters[minField] = minAmount as Q[keyof Q];
    newFilters[maxField] = maxAmount as Q[keyof Q];

    return newFilters;
}

export function manageAmountFiltersBasic<Q extends GenericListFilters<Z>, Z>(
    minField: keyof Q,
    maxField: keyof Q,
    filters: Q
): Q {

    const [minAmount, maxAmount]: [number, number] = genericManageAmount([filters[minField], filters[maxField]]);

    const newFilters = cloneDeep(filters);

    newFilters[minField] = minAmount as Q[keyof Q];
    newFilters[maxField] = maxAmount as Q[keyof Q];

    return newFilters;
}
