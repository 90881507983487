import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import useResponsive from "src/hooks/useResponsive";
import MediaList from "src/components/mediaList/MediaList";
import { Order } from "src/@types/orders";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Media } from "src/@types/request";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { detailOrder } from "src/services/ordersServices";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { FileWithSection } from "src/@types/media";
import { fortyAlertCheck, FortyDaysAlert } from "src/components/request-utils/FeesAndNotes";

interface CompleteWarrantyProps {
    request: any,
    order: Order,
    files: FileWithSection[],
    ar: boolean
}

export default function CompleteWarranty({ request, files, ar }: CompleteWarrantyProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    const [order, setOrder] = useState<Order>();

    const { order: ord } = useSelector((state: RootState) => state.orders);

    useEffect(() => {
        if (ar)
            detailOrder(request.items[0].orderId!).then(ord => setOrder(ord.data));
        else
            setOrder(ord!);
    }, []);

    const getFileLabel = useCallback((label: string) => {
        switch (label) {
            case "firstInvoice": return `${translate('request.form.firstInvoice')}`;
            case "secondInvoice": return `${translate('request.form.secondInvoice')}`;
            case "vehicleInfo": return `${translate('request.form.vehicleInfo')}`;
            case "firstRepairInvoice": return `${translate('request.form.invoiceRepair')}`;
            case "secondRepairInvoice": return `${translate('request.form.invoiceSecondRepair')}`;
            case "maddDoc": return `${translate('request.detail.maddDoc')}`;
        }

        return label;
    }, [translate]);

    const getBrandName = useCallback((brandId: string) => {
        if (publicBrandList) return publicBrandList.find((brand) => brand.id === brandId)?.name;

        return brandId;
    }, [publicBrandList]);

    const mediaFromFile = useMemo(() => {

        return {
            media: files.map((file, index) => {
                return {
                    section: getFileLabel(file.section || ""),
                    mediaType: "",
                    fileInfo: [],
                    uploadDateInfo: ar ? request.warranty?.invoices[index]?.date || "" : ""
                } as Media;
            }),
            mediaData: files.map((file, index) => index === 2 ? (getBrandName(request.warranty.vehicle.brand) + " " + request.warranty.vehicle.model) : request.warranty.invoices[(index > 2 ? index - 1 : index)].number)
        };
    }, [files, getFileLabel, getBrandName, request, ar]);

    const showFortyAlert = request.warranty?.invoices[0].date && fortyAlertCheck(new Date(request.warranty.invoices[0].date), new Date());

    return (
        <>
            {showFortyAlert && <FortyDaysAlert />}

            <Box sx={{ p: 2, pt: 0 }}>

                {order && <MoreDetails order={order} />}

                <Divider />

                {!ar && <>
                    <Typography sx={{ p: 3, pb: 4 }} variant="subtitle1">{`${translate('request.anomalies.detail.vehicleInfo')}`}</Typography>
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 3, rowGap: 4,
                            p: 3, pt: 0,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                        }}>
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.brand")}`}
                            value={getBrandName(request.warranty?.vehicle?.brand || "")}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.model")}`}
                            value={request.warranty?.vehicle?.model || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.vin")}`}
                            value={request.warranty?.vehicle?.vin || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.plate")}`}
                            value={request.warranty?.vehicle?.plate || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.registrationDate")}`}
                            value={request.warranty?.vehicle?.registrationDate || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.issueDescription')}`}</Typography>
                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty?.note}</Typography>

                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.diagnosis')}`}</Typography>
                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty?.diagnosis}</Typography>

                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.remedy')}`}</Typography>
                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty?.solution}</Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            columnGap: 3, rowGap: 4, px: 3
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('request.timeRepair')}`}
                            value={request.warranty?.hoursSpent?.toString() || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('request.hourRateRepair')}`}
                            value={request.warranty?.ratePerHour?.withoutVat.toString() || '—'}
                            currentCurrency={request.warranty?.ratePerHour?.currentCurrency}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                    </Box>
                </>}

                <Box sx={{ mt: 4, p: 3, pt: 0, pb: isDesktop ? 3 : 0 }}>
                    <MediaList media={mediaFromFile.media} mediaData={mediaFromFile.mediaData} />
                </Box>

                <Box>

                    {request.file &&
                        Object.keys(request.file).map((key, ind) =>

                            <Box key={ind} sx={{ p: '16px 24px', my: 2, display: 'flex', width: '100%', border: 'solid 1px ', borderRadius: '8px', alignItems: 'center', borderColor: 'var(--components-divider, rgba(145, 158, 171, 0.24))' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignContent: 'center' }}>
                                    <InsertDriveFileIcon />
                                    <Typography variant="subtitle1" sx={{ mr: 'auto' }}>{request.file![key].name}</Typography>
                                </Box>
                                <IconButton sx={{ ml: 'auto', color: 'inherit', alignContent: 'center' }} >
                                    <FileDownloadRoundedIcon />
                                </IconButton>
                            </Box>
                        )

                    }
                </Box>

            </Box>
        </>
    );
}