import { Payments, Percent, AccountBalance, Settings, Newspaper, Receipt } from "@mui/icons-material";
import { Box, Card, Chip, Divider, Typography, useTheme } from "@mui/material";
import { useLocales } from "src/locales";
import { Level } from "src/@types/webshop";
import { DiscountThresholdProgressBar, StatisticBox } from "../newWebshop/WebshopUtilComponents";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { DataGridStyle } from "src/utils/DataGridStyle";
import useTable from "src/appHooks/useTable";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { noData } from "src/components/empty-content/EmptyContent";
import GenericListFooter from "src/utils/list/utils/GenericListFooter";
import { OrderSearchResult } from "src/@types/orders";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import DateZone from "src/appComponents/DateZone";
import { getPrice } from "src/utils/currency";
import useResponsive from "src/hooks/useResponsive";

export default function PublicStatisticsDetail() {

    const theme = useTheme();

    const { translate } = useLocales();

    const multipleOrderthreshold = true;

    const { dense, onChangeDense } = useTable();

    const isDesktop = useResponsive('up', 'md');

    const { orderList } = useSelector((state: RootState) => state.orders);

    const COLUMNS: GridColDef<OrderSearchResult>[] = [
        {
            field: 'prettyName',
            headerName: `${translate('orders.tableHeaders.prettyName')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (param) =>
                <Typography variant="subtitle2">{param.row.prettyName ?? "—"}</Typography>
        },
        {
            field: 'deliveryNoteExternalId',
            headerName: `${translate('orders.tableHeaders.deliveryNote')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (param) => param.row.deliveryNoteExternalId ?
                <Typography variant="subtitle2">{param.row.deliveryNoteExternalId}</Typography>
                :
                param.row.deliveryNoteId ?
                    <Newspaper sx={{ mx: 1.75 }} />
                    :
                    <Typography variant="subtitle2">{"—"}</Typography>

        },
        {
            field: 'invoiceExternalId',
            headerName: `${translate('orders.tableHeaders.invoice')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (param) => param.row.invoiceExternalId ?
                <Typography variant="subtitle2">{param.row.invoiceExternalId}</Typography>
                :
                param.row.invoiceId ?
                    <Receipt sx={{ mx: 1.75 }} />
                    :
                    <Typography variant="subtitle2">{"—"}</Typography>
        },
        {
            field: 'totalQuantity',
            headerName: `${translate('orders.tableHeaders.quantity')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: true,
        },
        {
            field: 'grossAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.grossAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) =>
                <CurrencyAdapter
                    value={getPrice(params.row.grossAmount)}
                    currency={params.row.grossAmount.currentCurrency}
                    noVatAsterisk={!!params.row.grossAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
        },
        {
            field: 'totalDiscountAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.discountAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) =>
                <CurrencyAdapter
                    value={getPrice(params.row.totalDiscountAmount)}
                    currency={params.row.totalDiscountAmount.currentCurrency}
                    noVatAsterisk={!!params.row.totalDiscountAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
        },
        {
            field: 'netAmount.withoutVat',
            headerName: `${translate('orders.tableHeaders.netAmount')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) =>
                <CurrencyAdapter
                    value={getPrice(params.row.netAmount)}
                    currency={params.row.netAmount.currentCurrency}
                    noVatAsterisk={!!params.row.netAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.35 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: true,
            renderCell: (params) =>
                <DateZone
                    date={params.row.createdOn}
                    noSeconds
                    shortMonth
                    fontSize={"0.875rem"}
                />
        }
    ];

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h4">
                    {translate('webshop.detail.statistics.title')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' }/*  isDesktop ? 'row' : 'column' */ }}>
                <StatisticBox
                    Icon={<Payments fontSize="medium" color="success" />}
                    circleColor={theme.palette.success.lighter}
                    label={translate('webshop.detail.statistics.totalGrossPurchased')}
                    value={<Typography variant="h4">78,968 €</Typography>}
                />
                <StatisticBox
                    Icon={<Percent fontSize="medium" color="info" />}
                    circleColor={theme.palette.info.lighter}
                    label={translate('basket.detail.totalDiscount')}
                    value={
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Typography variant="h4">18.380€</Typography>
                            <Chip size="small" label="4%" color="info" variant="soft" />
                        </Box>
                    }
                />
                <StatisticBox
                    Icon={<AccountBalance fontSize="medium" color="warning" />}
                    circleColor={theme.palette.warning.lighter}
                    label={translate('webshop.detail.statistics.totalNetPurchased')}
                    value={<Typography variant="h4">54,968 €</Typography>}
                />
                <StatisticBox
                    Icon={<Settings fontSize="medium" />}
                    circleColor={theme.palette.primary.lighter}
                    label={translate('webshop.detail.statistics.totalQtyPurchased')}
                    value={<Typography variant="h4">2.400</Typography>}
                />
            </Box>

            {multipleOrderthreshold &&
                <Box>

                    <Typography sx={{ my: 3 }} variant="h6">{translate('webshop.detail.statistics.multipleOrderThresholds')}</Typography>
                    <Card sx={{ p: 3 }}>
                        <Box sx={{ width: '100%', padding: 2 }}>

                            <Typography variant="h6">{translate('webshop.detail.statistics.istantThresholdDscount')}</Typography>
                            <Typography variant="h4" fontWeight="bold">
                                1,765 €
                                <Typography component="span" sx={{ fontSize: 14, marginLeft: 1 }}>
                                    +{5000} € {translate("webshop.detail.statistics.toNextThreshold")}
                                </Typography>
                            </Typography>

                            <DiscountThresholdProgressBar currentAmount={currentAmount} thresholds={thresholds} />
                        </Box>
                    </Card>
                </Box>
            }
            <Box>

                <Typography sx={{ my: 3 }} variant="h6">{translate('webshop.detail.statistics.orderList')}</Typography>
                <Card sx={{ p: 3 }}>
                    <DataGridPro
                        rows={orderList}
                        columns={COLUMNS}
                        rowCount={100}
                        sortingMode={"server"}
                        paginationMode={"server"}
                        sx={{
                            ...DataGridStyle,
                            height: dense ? 'auto' : { sm: 632, md: 649 },
                            maxHeight: { sm: 632, md: 649 },
                        }}
                        density={(dense && orderList.length > 0) ? 'compact' : 'standard'}
                        slots={{
                            noRowsOverlay: noData,
                            noResultsOverlay: noData,
                            footer: () => (
                                <>
                                    {orderList.length === 0 && <Divider />}
                                    <GenericListFooter
                                        dense={dense}
                                        onChangeDense={onChangeDense}
                                        totalCount={orderList.length}
                                    />
                                </>
                            )
                        }}
                    />
                </Card>
            </Box>
        </Box>
    );
}

const thresholds: Level[] = [
    { level: 1, fromAmount: 0, toAmount: 999, additionalDiscountPercentage: 0 },
    { level: 2, fromAmount: 1000.5, toAmount: 1199, additionalDiscountPercentage: 4 },
    { level: 3, fromAmount: 2000, toAmount: 2999, additionalDiscountPercentage: 6 },
    { level: 4, fromAmount: 3000, toAmount: 9999, additionalDiscountPercentage: 8 },
    { level: 5, fromAmount: 10000, toAmount: Infinity, additionalDiscountPercentage: 12 },
];

const currentAmount = 3500;

//const nextThreshold = thresholds.find(t => t.amount > currentAmount) || thresholds[thresholds.length - 1];

//const remaining = (nextThreshold.amount - currentAmount).toFixed(2);

