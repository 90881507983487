import { useLocales } from "src/locales";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Container } from "@mui/system";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Box, Button, Card, Chip, Divider, FormControlLabel, MenuItem, Stack, Switch, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import useResponsive from "src/hooks/useResponsive";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import LoadingScreen from "src/appComponents/loading-screen";
import Label, { LabelColor } from "src/components/label";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { containerOperations } from "src/redux/container";
import { CloseShipSliceProps, ContainerFilters, Container as ContainerT, ContainerTemplate, ContainerTemplateSearchResult } from "src/@types/container";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from "@mui/x-data-grid";
import useTable from "src/appHooks/useTable";
import DateZone from "src/appComponents/DateZone";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import TableMoreMenu from "src/appComponents/TableMoreMenu";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { enqueueSnackbar } from "notistack";
import { StatusFilters } from "src/@types/list";

export default function ContainerDetail() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { containerTemplate, filtersInUrl } = useSelector((state: RootState) => state.container);

    const { id } = useParams();

    useEffect(() => {
        if (id && (!containerTemplate || (containerTemplate.id !== id))) {
            dispatch(containerOperations.getContainerTemplate(id));
        }

    }, [id, containerTemplate]);

    const getStatusColor = (status: boolean): LabelColor => {

        if (status)
            return "success" as LabelColor;
        else
            return "error" as LabelColor;
    };

    return (
        <Page title={`${translate('containers.template.detail')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('containers.template.detail')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.containersTemplate.title')}`, href: PATH_DASHBOARD.containers.templates.list },
                        { name: `${containerTemplate?.name}` }
                    ]}
                />

                <Card sx={{ p: 4 }}>

                    {!containerTemplate ? (
                        <LoadingScreen />
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
                                <Stack>
                                    <Box sx={{ display: 'flex', alignItems: "center", gap: 1 }}>
                                        <Typography variant="h4">
                                            {`${translate('containers.detail.title')} ${containerTemplate.name}`}
                                        </Typography>
                                        <Label
                                            color={getStatusColor(containerTemplate.enabled)}
                                            sx={{ textTransform: 'uppercase', ml: 1 }}
                                        >
                                            {`${translate(`statuses.${containerTemplate.enabled ? "enabled" : "disabled"}`)}`}
                                        </Label>
                                        <Label
                                            color={getStatusColor(containerTemplate.active)}
                                            sx={{ textTransform: 'uppercase', ml: 1 }}
                                        >
                                            {`${translate(`statuses.${containerTemplate.active ? "active" : "inactive"}`)}`}
                                        </Label>
                                    </Box>
                                    <Typography variant="body2">
                                        {`${translate(`commons.createdOn`)} `}
                                        <b>{dayjs(containerTemplate.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                    </Typography>
                                </Stack>
                            </Box>

                            <ContainerMoreDetail container={containerTemplate} />

                            <Divider />

                            {id &&
                                <Box>
                                    <Typography variant="h6" sx={{ my: 3 }}>
                                        {`${translate('containers.history')}`}
                                    </Typography>
                                    <IstancesList templateId={id} />
                                </Box>
                            }

                            <Divider />

                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    ml: isDesktop ? 'auto' : 0,
                                    display: 'flex',
                                    gap: 1, mt: 3,
                                    flexDirection: isDesktop ? 'default' : 'column-reverse',
                                }}>

                                <Button
                                    variant="soft"
                                    sx={{ borderRadius: '100px' }}
                                    onClick={() => navigate(PATH_DASHBOARD.containers.templates.list + filtersInUrl, { replace: true })}
                                >
                                    {`${translate('request.goBack')}`}
                                </Button>

                                {/* <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => { setIsOpen(true); }}>
                                    {`${translate('request.messages.confirmArrived')}`}
                                </Button> */}

                            </Box>
                        </>
                    )}

                </Card>

            </Container>
        </Page >
    );
}

interface ContainerMoreDetailProps {
    container: ContainerTemplate | ContainerT
}

export function ContainerMoreDetail({ container }: ContainerMoreDetailProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "lg");

    const { organization } = useUserOrganizationContext();

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 3 }}>
                {`${translate('commons.moreDetail')}`}
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    flexDirection: isDesktop ? "row" : "column",
                    gridTemplateColumns: { sm: 'repeat(2, 1fr)' },
                    columnGap: 3, rowGap: 4, mb: 3
                }}
            >
                <GridLabeledText
                    align={"left"}
                    label={`${translate('commons.warehouse')}`}
                    value={organization.externalId ? (organization.externalId + " " + organization.name) : organization.name}
                    left={2.5}
                    right={9.5}
                    variant="body2"
                />
                <GridLabeledText
                    align={"left"}
                    label={`${translate("containers.detail.createdBy")}`}
                    value={container.createdBy.firstName + " " + container.createdBy.lastName || '—'}
                    left={2.5}
                    right={9.5}
                    variant="body2"
                />
                {!!(container as ContainerT)?.shippedOn &&
                    <GridLabeledText
                        valueAsDate
                        align={"left"}
                        label={`${translate('containers.tableHeaders.shippedOn')}`}
                        left={2.5}
                        right={9.5}
                        variant="body2"
                        shortMonth
                        noSeconds
                        date={new Date((container as ContainerT).shippedOn)}
                    />}
                {!!(container as ContainerT)?.shippedBy &&
                    <GridLabeledText
                        align={"left"}
                        label={`${translate("containers.detail.shippedBy")}`}
                        value={(container as ContainerT).shippedBy.firstName + " " + (container as ContainerT).shippedBy.lastName || '—'}
                        left={2.5}
                        right={9.5}
                        variant="body2"
                    />
                }
                {!!(container as ContainerT)?.closedOn &&
                    <GridLabeledText
                        valueAsDate
                        align={"left"}
                        label={`${translate('containers.tableHeaders.closedOn')}`}
                        left={2.5}
                        right={9.5}
                        variant="body2"
                        shortMonth
                        noSeconds
                        date={new Date((container as ContainerT).closedOn)}
                    />}
                {!!(container as ContainerT)?.closedBy &&
                    <GridLabeledText
                        align={"left"}
                        label={`${translate("containers.detail.closedBy")}`}
                        value={(container as ContainerT).closedBy.firstName + " " + (container as ContainerT).closedBy.lastName || '—'}
                        left={2.5}
                        right={9.5}
                        variant="body2"
                    />
                }
            </Box>
            <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography sx={{ color: 'text.secondary', fontWeight: '400' }}>
                    {`${translate('containers.tableHeaders.contentTypes')}:`}
                </Typography>

                {container.contentTypes.map((val, index) =>
                    <Chip key={index} label={`${translate(`request.${val}`)}`} />)}
            </Box>
            <Box sx={{ mb: 2, display: 'flex', gap: 10, alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography sx={{ color: 'text.secondary', fontWeight: '400' }}>
                    {`${translate('commons.tags')}:`}
                </Typography>
                <Box>
                    {!container.tags?.length && "—"}
                    {container.tags.map((val, index) =>
                        <Chip sx={{ mr: 2 }} key={index} label={val} />)}
                </Box>
            </Box>
        </Box>
    );
}

const BASIC_FILTER_OPTIONS: ContainerFilters = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "createdOn",
    sortAscending: false,
    templateId: ""
};

export function IstancesList({ templateId }: { templateId: string }) {

    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable({
        defaultOrder: (BASIC_FILTER_OPTIONS.sortAscending ? 'desc' : 'asc'),
        defaultOrderBy: BASIC_FILTER_OPTIONS.sortField,
        defaultRowsPerPage: BASIC_FILTER_OPTIONS.pageSize,
        defaultCurrentPage: BASIC_FILTER_OPTIONS.pageIndex
    });

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive("up", "md");

    const { isLoading, istanceList: containersList, istanceTotalCount: totalCount, istancePageSize: pageSize } = useSelector((state: RootState) => state.container);

    const [actualRow, setActualRow] = useState<any>(null);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [filters, setFilters] = useState<ContainerFilters>({ ...BASIC_FILTER_OPTIONS, templateId: templateId });

    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {

        const searchFilters: ContainerFilters = {
            pageIndex: page,
            pageSize: rowsPerPage,
            sortField: orderBy || undefined,
            sortAscending: orderBy ? (order === 'desc' ? true : false) : undefined,
            templateId: templateId
        };

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || firstRender) {
            setFilters(searchFilters as ContainerFilters);
            dispatch(containerOperations.searchContainer({ filters: searchFilters, isIstance: true }));
            setLastUsedLang(currentLang.label);
            if (firstRender) setFirstRender(false);
        }

    }, [page, rowsPerPage, orderBy, order, currentLang, lastUsedLang, filters, setPage, setRowsPerPage, templateId, firstRender]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    const quickFilters: StatusFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'info'
        },
        {
            key: 'Open',
            label: `${translate('statuses.open')}`,
            color: 'success',
        },
        {
            key: 'Closed',
            label: `${translate('statuses.closed')}`,
            color: 'error'
        },
        {
            key: 'Shipped',
            label: `${translate('statuses.shipped')}`,
            color: 'warning'
        }
    ], [translate]);

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") navigate(PATH_DASHBOARD.containers.detail(params.row.id.toString()));
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || containersList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.containers.logs(id));
    }, [navigate]);

    const handleCloseShipContainer = useCallback(async (id: string, action: "Close" | "Ship") => {

        const options: CloseShipSliceProps = {
            id: id,
            tab: "",
            action: action,
            totalCount: totalCount,
            pageSize: pageSize,
            listLen: containersList.length,
            now: new Date().toISOString(),
            filters: filters
        };

        let x = await dispatch(containerOperations.closeShipContainer(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Close" ? 'statuses.closed' : 'statuses.shipped')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [translate, containersList, totalCount, filters, pageSize]);

    const CONTAINER_COLUMNS: GridColDef<ContainerTemplateSearchResult>[] = useMemo(() => [
        {
            field: 'contentTypes',
            headerName: `${translate('containers.tableHeaders.contentTypes')}`,
            flex: isDesktop ? 1.3 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.contentTypes.map((type) => `${translate(`request.${type}`)}`).join(', ')}
                    </Typography>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'closedOn',
            headerName: `${translate('containers.tableHeaders.closedOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.closedOn || "")}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'shippedOn',
            headerName: `${translate('containers.tableHeaders.shippedOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.shippedOn || "")}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('commons.quantity')}`,
            flex: isDesktop ? 0.45 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {`${(obj.row.numberOfItems || 0) + (obj.row.numberOfExtraItems || 0)} ${obj.row.numberOfExtraItems ? `(${obj.row.numberOfItems}+${obj.row.numberOfExtraItems})` : ""}`}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    obj.row.status &&
                    <Label
                        color={quickFilters.find((filter) => filter.key === obj.row.status)!.color}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`statuses.${obj.row.status.toLowerCase()}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleCloseShip={handleCloseShipContainer}
                        handleLogs={handleLogs}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, quickFilters, handleCloseShipContainer, handleLogs]);

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);

    return (
        <DataGrid
            rows={containersList}
            columns={CONTAINER_COLUMNS}
            pagination
            paginationModel={{
                page: page,
                pageSize: rowsPerPage
            }}
            density={(dense && containersList.length > 0) ? 'compact' : 'standard'}
            sortingMode={"server"}
            onSortModelChange={handleSort}
            loading={isLoading}
            disableColumnResize
            slots={{
                noRowsOverlay: noData,
                footer: () => (
                    <Box sx={{
                        position: 'relative',
                        width: { xs: "90vw", md: "auto" },
                    }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                            sx={{
                                overflow: "hidden",
                                "& .MuiTablePagination-input": {
                                    ml: { xs: 0.5, md: "default" },
                                    mr: { xs: 3.5, md: "default" }
                                }
                            }}
                        />
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label={`${translate('commons.dense')}`}
                            sx={{
                                px: { xs: 0, sm: 3 },
                                py: { xs: 0, sm: 1.5 },
                                pb: { xs: 1.5, sm: 0 },
                                mx: 0,
                                top: 0,
                                justifyContent: "center",
                                width: { xs: "90vw", sm: "auto" },
                                position: { sm: 'absolute' }
                            }}
                        />
                    </Box>
                )
            }}
            disableColumnMenu
            pageSizeOptions={[5, 10, 15, 30]}
            disableRowSelectionOnClick
            onCellClick={(param) => {
                if (!window.getSelection()?.toString())
                    handleCellClick(param);
            }}
            sx={{
                ...DataGridStyle,
                cursor: 'pointer',
                height: getHeight(),
                maxHeight: getMaxHeight(),
            }}
        />
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: any,
    currentRow: any,
    handleCloseShip?: (id: string, action: "Close" | "Ship") => Promise<void>,
    handleLogs: (id: string) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleCloseShip, handleLogs }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>

                    {(object.row.createdOn && !object.row.closedOn) &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Close]}>
                            <MenuItem
                                onClick={() => {
                                    if (handleCloseShip) handleCloseShip(object.id, "Close");
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("commons.close")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }

                    {(object.row.closedOn && !object.row.shippedOn) &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Ship]}>
                            <MenuItem
                                onClick={() => {
                                    if (handleCloseShip) handleCloseShip(object.id, "Ship");
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("containers.detail.ship")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_ViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.row.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}