import axios, { AxiosResponse } from "axios";
import { Core, Group } from "./paths";
import { GroupFilters, GroupSearchResult, GroupStatistics, GroupType, NewGroup } from "src/@types/group";
import { UserFilters, UserSearchResult } from "src/@types/user";
import { OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import { LogsItem } from "src/@types/logs";
import { GenericPagedResponse } from "src/@types/list";

const createGroup = (params: NewGroup): Promise<AxiosResponse> => {
    const url = Group.admin.group.create();

    return axios.post(url, params);
};

const updateGroup = (params: NewGroup, id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.edit(id);

    return axios.put(url, params);
};

const detailGroup = (id: string): Promise<AxiosResponse<GroupType>> => {
    const url = Group.admin.group.detail(id);

    return axios.get(url);
};

const searchGroups = (options: GroupFilters): Promise<AxiosResponse<GenericPagedResponse<GroupSearchResult>>> => {
    const url = Group.admin.group.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchGroupMembers = (options: UserFilters): Promise<AxiosResponse<GenericPagedResponse<UserSearchResult>>> => {

    const url = Core.admin.user.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchGroupLogs = (id: string, filters: any): Promise<AxiosResponse<GenericPagedResponse<LogsItem>>> => {
    const url = Group.admin.group.logs(id);

    return axios.get(url, { params: filters });
};

const enableGroup = (id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.enable(id);

    return axios.patch(url);
};

const disableGroup = (id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.disable(id);

    return axios.patch(url);
};

const searchGroupOrganizations = (options: OrganizationFilters): Promise<AxiosResponse<GenericPagedResponse<OrganizationSearchResult>>> => {

    const url = Core.admin.organizations.vendor.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchGroupCustomers = (options: OrganizationFilters): Promise<AxiosResponse<GenericPagedResponse<OrganizationSearchResult>>> => {

    const url = Core.admin.organizations.customer.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const statistics = (options: GroupFilters): Promise<AxiosResponse<GroupStatistics>> => {
    const url = Group.admin.group.statistic();

    return axios.get(url, { params: options });
};

export {
    createGroup,
    updateGroup,
    detailGroup,
    searchGroups,
    searchGroupMembers,
    searchGroupLogs,
    enableGroup,
    disableGroup,
    searchGroupOrganizations,
    searchGroupCustomers,
    statistics
};