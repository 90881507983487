import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Tag } from 'src/@types/commons';
import { OrganizationIdItem, UserFilters, UserGroupsSearchResult, UserManager, UserSearchResult, UserStatistics, UserToCreateEdit } from '../@types/user';
import { Core } from './paths';
import { LogsItem } from 'src/@types/logs';
import { GenericPagedResponse } from 'src/@types/list';

const detail = async (id: string): Promise<AxiosResponse<UserManager>> => {
    const url = Core.admin.user.detail(id);

    var r = await axios.get<UserManager>(url);

    if (r.status === 200) {
        // trasformo l'utente e lo riassegno alla AxiosResponse
        r.data = { ...r.data, displayName: `${r.data.firstName} ${r.data.lastName}` };
    }

    return r;
};

const profile = async (customHeaders?: Record<string, string>): Promise<AxiosResponse<UserManager>> => {
    const url = Core.public.user.profile();

    let config: AxiosRequestConfig<any> = {};
    if (customHeaders) {
        //Crea un oggetto di configurazione con gli header personalizzati
        config = { headers: { ...customHeaders } };
    }

    var r = await axios.get(url, config);

    if (r.status === 200) {
        // trasformo l'utente e lo riassegno alla AxiosResponse
        r.data = { ...r.data, displayName: `${r.data.firstName} ${r.data.lastName}` };
    }

    return r;
};

const create = (user: UserToCreateEdit): Promise<AxiosResponse<UserToCreateEdit>> => {
    const url = Core.admin.user.create();

    return axios.post(url, user);
};

const update = (user: UserToCreateEdit): Promise<AxiosResponse<UserToCreateEdit>> => {
    const url = Core.admin.user.update();

    return axios.put(url, user);
};

const enable = (userId: string): Promise<AxiosResponse> => {
    const url = Core.admin.user.enable(userId);

    return axios.patch(url);
};

const disable = (userId: string): Promise<AxiosResponse> => {
    const url = Core.admin.user.disable(userId);

    return axios.patch(url);
};

const resetPassword = (userId: string): Promise<AxiosResponse> => {
    const url = Core.admin.user.resetPassword(userId);

    return axios.patch(url);
};

const verifyEmail = (email: string): Promise<AxiosResponse> => {
    const url = Core.admin.user.verifyEmail(email);

    return axios.get(url);
};

const verifyUsername = (username: string, provider: string): Promise<AxiosResponse> => {
    const url = Core.admin.user.verifyUsername(username, provider);

    return axios.get(url);
};

const search = (options: UserFilters): Promise<AxiosResponse<GenericPagedResponse<UserSearchResult>>> => {

    const url = Core.admin.user.search();

    return axios.get<GenericPagedResponse<UserSearchResult>>(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const statistics = (options: UserFilters): Promise<AxiosResponse<UserStatistics>> => {
    const url = Core.admin.user.statistic();

    return axios.get(url, { params: options });
};

const powersearch = (options: {
    pageIndex: number,
    pageSize: number,
    all: string,
    enabledCountry?: string | null
})
    : Promise<AxiosResponse<GenericPagedResponse<UserSearchResult>>> => {
    const url = Core.admin.user.search();

    return axios.get(url, {
        params: {
            pageIndex: options.pageIndex.toString(),
            pageSize: options.pageSize.toString(),
            all: options.all,
            enabledCountry: options?.enabledCountry
        }
    });
};

const userLogsSearch = (id: string, filters: any): Promise<AxiosResponse<GenericPagedResponse<LogsItem>>> => {
    const url = Core.admin.user.searchLogs(id);

    return axios.get(url, { params: filters });
};

const assignRoles = (userId: string, roles: string[]) => {
    const url = Core.admin.user.assignRoles(userId);

    return axios.patch(url, { roles: roles });
};

const searchTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Core.admin.user.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        },
        paramsSerializer: {
            indexes: null
        }
    });
};

const associate = (userId: string, organizations: OrganizationIdItem[]): Promise<AxiosResponse> => {
    const url = Core.admin.user.associate(userId);

    return axios.patch(url, { organizationIds: organizations });
};

const getProviders = (onlySignUpProviders: boolean): Promise<AxiosResponse<Record<string, string>>> => {
    const url = Core.admin.user.getProviders();

    return axios.get(url, { params: { onlySignUpProviders } });
};

const searchUserGroups = (groupIds: string[]): Promise<AxiosResponse<GenericPagedResponse<UserGroupsSearchResult>>> => {

    const url = Core.admin.user.groups();

    return axios.get(url, {
        params: { groupIds: groupIds },
        paramsSerializer: { indexes: null }
    });
};

const defaultExport = {
    create,
    update,
    enable,
    disable,
    verifyEmail,
    verifyUsername,
    detail,
    profile,
    search,
    powersearch,
    userLogsSearch,
    resetPassword,
    assignRoles,
    searchTags,
    statistics,
    associate,
    getProviders,
    searchUserGroups
};

export default defaultExport;
