import { createAsyncThunk } from "@reduxjs/toolkit";
import { EnableDisableGroupSliceProps, GroupCategory, GroupFilters, GroupSearchResult, NewGroup } from "src/@types/group";
import * as groupService from '../../services/groupService';
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { startLoading, startMembersLoading, startLogsLoading, enableDisableGroupSL, startOrganizationsLoading, startWhitelistLoading, startBlacklistLoading, setBlackOrgHasFilter, setBlackUserHasFilter, setWhiteOrgHasFilter, setWhiteUserHasFilter } from "./group-slices";
import { UserFilters } from "src/@types/user";
import { OrganizationFilters } from "src/@types/organizations";

const createGroup = createAsyncThunk(
    'group/createGroup',
    async (group: NewGroup, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await groupService.createGroup(group);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateGroup = createAsyncThunk(
    'group/updateGroup',
    async (params: { group: NewGroup, id: string }, { dispatch, rejectWithValue }) => {
        try {
            const { group, id } = params;

            const { data, status } = await groupService.updateGroup(group, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroup = createAsyncThunk(
    'group/getGroup',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.detailGroup(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroups = createAsyncThunk(
    'group/searchGroups',
    async (filters: GroupFilters, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.searchGroups(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupMembers = createAsyncThunk(
    'group/searchGroupMembers',
    async (options: UserFilters, { dispatch, rejectWithValue }) => {
        dispatch(startMembersLoading());
        try {
            const { data } = await groupService.searchGroupMembers(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupWhitelistUsers = createAsyncThunk(
    'group/searchGroupWhitelistUsers',
    async (options: UserFilters, { dispatch, rejectWithValue }) => {
        dispatch(startWhitelistLoading());
        try {
            dispatch(setWhiteUserHasFilter(!!options.all));

            const { data } = await groupService.searchGroupMembers(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupBlacklistUsers = createAsyncThunk(
    'group/searchGroupBlacklistUsers',
    async (options: UserFilters, { dispatch, rejectWithValue }) => {
        dispatch(startBlacklistLoading());
        try {
            dispatch(setBlackUserHasFilter(!!options.all));

            const { data } = await groupService.searchGroupMembers(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroupLogs = createAsyncThunk(
    'group/getGroupLogs',
    async (params: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await groupService.searchGroupLogs(params.id, nullifyEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableDisableGroup = createAsyncThunk(
    'group/enableDisableGroup',
    async (options: EnableDisableGroupSliceProps, { dispatch, rejectWithValue }) => {

        try {

            let newItem: GroupSearchResult | undefined = undefined;

            if (options.totalCount > options.listLen && options.listLen === options.pageSize && options.tab !== "") {
                const { data } = await groupService.searchGroups({ ...options.filters, pageIndex: options.filters.pageIndex + 1 });

                newItem = data.items[0];

                if (!data) {
                    throw new Error('Something went wrong');
                }

            }

            const { data, status } = options.action === 'Enable' ?
                await groupService.enableGroup(options.id) :
                await groupService.disableGroup(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableGroupSL({ ...options, newItem: newItem }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupOrganizations = createAsyncThunk(
    'group/searchGroupOrganizations',
    async (options: OrganizationFilters, { dispatch, rejectWithValue }) => {
        dispatch(startOrganizationsLoading());
        try {
            const { data } = await groupService.searchGroupOrganizations(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupCustomers = createAsyncThunk(
    'group/searchGroupCustomers',
    async (options: OrganizationFilters, { dispatch, rejectWithValue }) => {
        dispatch(startOrganizationsLoading());
        try {
            const { data } = await groupService.searchGroupCustomers(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupWhitelistOrganizations = createAsyncThunk(
    'group/searchGroupWhitelistOrganizations',
    async (params: { options: OrganizationFilters, type: GroupCategory }, { dispatch, rejectWithValue }) => {
        dispatch(startWhitelistLoading());
        try {
            dispatch(setWhiteOrgHasFilter(!!params.options.all));

            const { data } = await (params.type === "Organization" ?
                groupService.searchGroupOrganizations(params.options) :
                groupService.searchGroupCustomers(params.options)
            );

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupBlacklistOrganizations = createAsyncThunk(
    'group/searchGroupBlacklistOrganizations',
    async (params: { options: OrganizationFilters, type: GroupCategory }, { dispatch, rejectWithValue }) => {
        dispatch(startBlacklistLoading());
        try {
            dispatch(setBlackOrgHasFilter(!!params.options.all));

            const { data } = await (params.type === "Organization" ?
                groupService.searchGroupOrganizations(params.options) :
                groupService.searchGroupCustomers(params.options)
            );

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getStatistics = createAsyncThunk(
    'group/getStatistics',
    async (options: GroupFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.statistics(options as GroupFilters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const groupOperations = {
    createGroup,
    updateGroup,
    getGroup,
    searchGroups,
    searchGroupMembers,
    getGroupLogs,
    enableDisableGroup,
    searchGroupOrganizations,
    searchGroupCustomers,
    getStatistics,
    searchGroupWhitelistUsers,
    searchGroupBlacklistUsers,
    searchGroupWhitelistOrganizations,
    searchGroupBlacklistOrganizations
};

export default groupOperations;
