import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, SxProps, Theme, Typography, alpha } from "@mui/material";
import { ProductSpecs, RequestExceededItem, RequestItem } from "src/@types/request";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Inventory, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useCallback, useState } from "react";
import { SparePartSearchResult } from "src/@types/spareParts";
import useResponsive from "src/hooks/useResponsive";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";
import useTenant from "src/appHooks/useTenant";

interface PartsDetailProps {
    items: RequestItem[],
    customFields?: Record<string, string>
    clickToDetail?: boolean,
    missingChanges?: boolean,
    exceededItems?: RequestExceededItem[] | undefined,
    showDepositRef?: boolean,
    icar?: string,
    sap?: string,
    sx?: SxProps<Theme> | undefined
}

export default function PartsDetail({ items, clickToDetail, missingChanges, exceededItems, showDepositRef, icar, sap, sx, customFields }: PartsDetailProps) {

    const { translate, currentLang } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const [expanded, setExpanded] = useState(items.length === 1 || (exceededItems && exceededItems.length === 1));

    const { customFields: customs } = useTenant();

    const getMissingGridTemplateColumns = (showingItems: boolean) => {

        let showIndex = true;

        let showQty = true;

        if (showingItems) {
            if (items.length > 1) {
                [showIndex, showQty] = [true, true];
            } else {
                [showIndex, showQty] = [false, !!(exceededItems && exceededItems.length > 0)];
            }
        } else {
            if (exceededItems && exceededItems.length > 1) {
                [showIndex, showQty] = [true, true];
            } else {
                [showIndex, showQty] = [false, !!missingChanges];
            }
        }

        return isDesktop ?
            `${showIndex ? "3%" : ""} 15% 17% ${showQty ? "7%" : ""} ${missingChanges ? "21%" : ""} ${showDepositRef ? "12%" : ""} ${icar || sap ? "20%" : "35%"} ${icar ? "12.5%" : ""} ${sap ? "12.5%" : ""}` :
            `${showIndex ? "10%" : ""} 45% 45% ${showQty ? "15%" : ""} ${missingChanges ? "30%" : ""} ${showDepositRef ? "35%" : ""} ${icar || sap ? "50%" : "65%"} ${icar ? "40%" : ""} ${sap ? "40%" : ""}`;
    };

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customs, id, currentLang);

    }, [currentLang, customs]);

    return (
        <Box sx={{ "& .MuiAccordionSummary-root": { cursor: (items.length > 1 || (exceededItems && exceededItems.length > 1)) ? 'pointer' : "default !important" }, ...sx }}>
            <Accordion
                disableGutters
                square
                expanded={expanded}
                sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25) }}
            >
                <AccordionSummary
                    id={"spare-sparts-accordion"}
                    expandIcon={(items.length > 1 || (exceededItems && exceededItems.length > 1)) && <ExpandMoreIcon sx={{ pointerEvents: 'auto' }} />}
                    sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), pr: 2 }}
                    onClick={() => { if (items.length > 1 || (exceededItems && exceededItems.length > 1)) setExpanded(prev => !prev); }}
                >
                    <Inventory />
                    <Typography
                        variant="h6"
                        sx={{ color: 'text.primary', fontWeight: '600', ml: 2 }}
                    >
                        {`${translate('request.partListInDetail')}`}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), pt: 0, pb: 1, pr: 1, pl: 4 }}>
                    {missingChanges ? (
                        <>
                            {items.length > 0 &&
                                <>
                                    {(exceededItems && exceededItems.length > 0) &&
                                        <Typography variant="subtitle2" textTransform={"uppercase"} sx={{ ml: 1 }}>{`${translate('request.selectReason.missingParts')}`}</Typography>
                                    }
                                    {
                                        items.map((item, index) =>
                                            <Box
                                                key={item.product.id}
                                                onClick={() => { if (clickToDetail) window.open(PATH_DASHBOARD.spareParts.detail(item.product.id), "_blank"); }}
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: getMissingGridTemplateColumns(true),
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    overflowX: "auto",
                                                    cursor: clickToDetail ? 'pointer' : "default"
                                                }}
                                            >
                                                {items.length > 1 &&
                                                    <Stack sx={{ m: 2, mr: 0 }}>
                                                        <Typography variant="overline">{index + 1}</Typography>
                                                    </Stack>
                                                }

                                                <Stack sx={{ m: 2, ml: 1 }}>
                                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partref')}`}</Typography>
                                                    <Typography variant="body2">{(item.product.externalId ?? item.product.itemExternalId) || ""}</Typography>
                                                </Stack>

                                                <Stack sx={{ m: 2 }}>
                                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partdescription')}`}</Typography>
                                                    <Typography variant="body2">{item.product.name || ""}</Typography>
                                                </Stack>

                                                {(items.length > 1 || (exceededItems && exceededItems.length > 0)) &&
                                                    <Stack sx={{ m: 2 }}>
                                                        <Typography variant="overline">{`${translate('returns.tableHeaders.quantity')}`}</Typography>
                                                        <Typography variant="body2">{item.requiredQuantity || item.approvedQuantity || ""}</Typography>
                                                    </Stack>
                                                }

                                                {(missingChanges && customFields &&
                                                    <Stack sx={{ m: 2 }}>
                                                        <Typography variant="overline">{`${translate('request.form.parcel')}`}</Typography>
                                                        <Typography variant="body2">{customFields!['missing-items-parcel-' + item.itemIndex + "-" + item.product.id] || "—"} </Typography>
                                                    </Stack>
                                                )}

                                                <>
                                                    {showDepositRef &&
                                                        <Stack sx={{ m: 2 }}>
                                                            <Typography variant="overline">{customFieldName('stellantis-europe-spareparts-deposit-number')}</Typography>
                                                            <Typography variant="body2">
                                                                {(item.product.customFields && item.product.customFields['stellantis-europe-spareparts-deposit-number']) || "—"}
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                    <Stack sx={{ m: 2 }}>
                                                        <Typography variant="overline">{`${translate('returns.tableHeaders.partfamily')}`}</Typography>
                                                        <Typography variant="body2"><b>{item.product.family?.code || "—"}</b> - {item.product.family?.name || "—"}</Typography>
                                                    </Stack>
                                                </>
                                            </Box>
                                        )
                                    }
                                </>
                            }
                            {(exceededItems && exceededItems.length > 0) &&
                                <>
                                    {items.length > 0 &&
                                        <>
                                            <Divider sx={{ my: 2 }} />
                                            <Typography variant="subtitle2" textTransform={"uppercase"} sx={{ ml: 1 }}>{`${translate('request.detail.partReceived')}`}</Typography>
                                        </>
                                    }
                                    {
                                        exceededItems.map((item, index) =>
                                            <Box
                                                key={item.product.id}
                                                onClick={() => { if (clickToDetail) window.open(PATH_DASHBOARD.spareParts.detail(item.product.id), "_blank"); }}
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: getMissingGridTemplateColumns(false),
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    overflowX: "auto",
                                                    cursor: clickToDetail ? 'pointer' : "default"
                                                }}
                                            >
                                                {exceededItems.length > 1 &&
                                                    <Stack sx={{ m: 2, mr: 0 }}>
                                                        <Typography variant="overline">{index + 1}</Typography>
                                                    </Stack>
                                                }

                                                <Stack sx={{ m: 2, ml: 1 }}>
                                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partref')}`}</Typography>
                                                    <Typography variant="body2">{item.product.externalId || ""}</Typography>
                                                </Stack>

                                                <Stack sx={{ m: 2 }}>
                                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partdescription')}`}</Typography>
                                                    <Typography variant="body2">{item.product.name || ""}</Typography>
                                                </Stack>

                                                <Stack sx={{ m: 2 }}>
                                                    <Typography variant="overline">{`${translate('returns.tableHeaders.quantity')}`}</Typography>
                                                    <Typography variant="body2">{item.quantity || ""}</Typography>
                                                </Stack>

                                                <>
                                                    {showDepositRef &&
                                                        <Stack sx={{ m: 2 }}>
                                                            <Typography variant="overline">{customFieldName('stellantis-europe-spareparts-deposit-number')}</Typography>
                                                            <Typography variant="body2">
                                                                {(item.product.customFields && item.product.customFields['stellantis-europe-spareparts-deposit-number']) || "—"}
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                    <Stack sx={{ m: 2 }}>
                                                        <Typography variant="overline">{`${translate('returns.tableHeaders.partfamily')}`}</Typography>
                                                        <Typography variant="body2"><b>{item.product.family?.code || "—"}</b> - {item.product.family?.name || "—"}</Typography>
                                                    </Stack>
                                                </>
                                            </Box>
                                        )
                                    }
                                </>
                            }
                        </>
                    ) : (
                        (exceededItems && exceededItems.length > 0 ? exceededItems : items).map((item, index) =>
                            <Box
                                key={item.product.id}
                                onClick={() => { if (clickToDetail) window.open(PATH_DASHBOARD.spareParts.detail(item.product.id), "_blank"); }}
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: getMissingGridTemplateColumns(!(exceededItems && exceededItems.length > 0)),
                                    flexDirection: 'row',
                                    width: '100%',
                                    overflowX: "auto",
                                    cursor: clickToDetail ? 'pointer' : "default"
                                }}
                            >
                                {(items.length > 1 || (exceededItems && exceededItems.length > 1)) &&
                                    <Stack sx={{ m: 2, mr: 0 }}>
                                        <Typography variant="overline">{index + 1}</Typography>
                                    </Stack>
                                }

                                <Stack sx={{ m: 2, ml: 1 }}>
                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partref')}`}</Typography>
                                    <Typography variant="body2">{(item.product.externalId ?? item.product.itemExternalId) || ""}</Typography>
                                </Stack>

                                <Stack sx={{ m: 2 }}>
                                    <Typography variant="overline">{`${translate('returns.tableHeaders.partdescription')}`}</Typography>
                                    <Typography variant="body2">{item.product.name || ""}</Typography>
                                </Stack>

                                {items.length > 1 &&
                                    <Stack sx={{ m: 2 }}>
                                        <Typography variant="overline">{`${translate('returns.tableHeaders.quantity')}`}</Typography>
                                        <Typography variant="body2">{(item as RequestItem).requiredQuantity || (item as RequestItem).approvedQuantity || ""}</Typography>
                                    </Stack>
                                }

                                {(exceededItems && exceededItems.length > 1) &&
                                    <Stack sx={{ m: 2 }}>
                                        <Typography variant="overline">{`${translate('returns.tableHeaders.quantity')}`}</Typography>
                                        <Typography variant="body2">{(item as RequestExceededItem).quantity || ""}</Typography>
                                    </Stack>
                                }

                                <>
                                    {showDepositRef &&
                                        <Stack sx={{ m: 2 }}>
                                            <Typography variant="overline">{customFieldName('stellantis-europe-spareparts-deposit-number')}</Typography>
                                            <Typography variant="body2">
                                                {(item.product.customFields && item.product.customFields['stellantis-europe-spareparts-deposit-number']) || "—"}
                                            </Typography>
                                        </Stack>
                                    }
                                    <Stack sx={{ m: 2 }}>
                                        <Typography variant="overline">{`${translate('returns.tableHeaders.partfamily')}`}</Typography>
                                        <Typography variant="body2"><b>{item.product.family?.code || "—"}</b> - {item.product.family?.name || "—"}</Typography>
                                    </Stack>
                                </>

                                {icar &&
                                    <Stack sx={{ m: 2 }}>
                                        <Typography variant="overline">{`${translate('request.form.icar')}`}</Typography>
                                        <Typography variant="body2">{icar}</Typography>
                                    </Stack>
                                }

                                {sap &&
                                    <Stack sx={{ m: 2 }}>
                                        <Typography variant="overline">{`${translate('request.form.sap')}`}</Typography>
                                        <Typography variant="body2">{sap}</Typography>
                                    </Stack>
                                }
                            </Box>
                        )
                    )}
                </AccordionDetails>
            </Accordion>
        </Box >
    );
}

export const getExceededItems = (items: (SparePartSearchResult & { quantity: number })[]) => {
    return items.length === 0 ? undefined : items.map((item) => {
        return {
            product: {
                id: item.id,
                externalId: item.externalId,
                deprecated: item.deprecated,
                type: "SparePart",
                name: item.name,
                family: {
                    name: item.family.name,
                    code: item.family.code
                },
                category: {
                    name: item.category.name,
                    code: item.category.code
                },
                class: {
                    name: item.class.name,
                    code: item.class.code
                },
                customFields: {}
            } as ProductSpecs,
            quantity: item.quantity
        } as RequestExceededItem;
    });
};