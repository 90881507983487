import { Box, Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import { SidebarListFilters, FilterTypes } from 'src/@types/list';
import { isEqual } from 'lodash';
import { SelectItem } from 'src/@types/commons';
import { CustomFieldsSummaryChip, GenericSummaryChip, MultipleSummaryChip } from './SummaryChips';
import { getSliderFilterNames } from '../sidebar/SidebarSlider';

interface GenericFilterSummaryProps {
    showSummary: boolean,
    defaultFilters: any,
    filterValues: any,
    filterList: SidebarListFilters[],
    onResetFilter: (el: string, value?: any, isRange?: boolean) => void,
    onResetAll: VoidFunction,
    customfieldContext?: string[],
    itemCustomfieldContext?: string[],
    isToolbarShown: boolean
}

export default function GenericFilterSummary({
    showSummary,
    defaultFilters,
    filterValues,
    filterList,
    onResetFilter,
    onResetAll,
    customfieldContext,
    itemCustomfieldContext,
    isToolbarShown
}: GenericFilterSummaryProps) {

    const { translate } = useLocales();

    const summaryComponents = useMemo(() => ({
        Single: (name: string, label: string, type: FilterTypes, nameId?: string, options?: SelectItem[]) => {

            let summaryValue: string = "";

            let showSummaryFilter: boolean;

            if (type === "Slider" || type === "InputRange") {

                const [minLabel, maxLabel] = getSliderFilterNames(name);

                showSummaryFilter = !isEqual(filterValues[minLabel], defaultFilters[minLabel]) || !isEqual(filterValues[maxLabel], defaultFilters[maxLabel]);

                if (showSummaryFilter) {
                    const minString = filterValues[minLabel] ? "min: " + filterValues[minLabel] : "";

                    const maxString = filterValues[maxLabel] ? "max: " + filterValues[maxLabel] : "";

                    summaryValue = minString + ((minString && maxString) ? " - " : "") + maxString;
                }

            } else {

                showSummaryFilter = filterValues[name] && !isEqual(filterValues[name], defaultFilters[name]);

                if (showSummaryFilter) summaryValue = options?.find((option) => option.value === filterValues[name])?.label
                    ??
                    (type === 'Autocomplete' ? (filterValues[name]?.name ?? "") : filterValues[name]);
            }

            if (showSummaryFilter) return (
                <GenericSummaryChip
                    name={name}
                    nameId={nameId}
                    isRange={type === "Slider" || type === "InputRange"}
                    label={label}
                    value={summaryValue}
                    onResetFilter={onResetFilter}
                />
            );
        },
        Multiple: (name: string, label: string, nameId?: string, options?: SelectItem[]) => {
            if (filterValues[name] && filterValues[name].length > 0 && !isEqual(filterValues[name], defaultFilters[name])) return (
                <MultipleSummaryChip
                    name={name}
                    nameId={nameId}
                    label={label}
                    values={filterValues[name]}
                    onResetFilter={onResetFilter}
                    valuesLabels={options}
                />
            );
        },
        CustomFields: (customfieldContext: string[]) => {
            if (filterValues.customFields && Object.entries(filterValues.customFields).length > 0) return (
                <CustomFieldsSummaryChip
                    contextList={customfieldContext}
                    values={filterValues.customFields}
                    onResetFilter={onResetFilter}
                />
            );
        },
        ItemCustomFields: (customfieldContext: string[]) => {
            if (filterValues.itemCustomFields && Object.entries(filterValues.itemCustomFields).length > 0) return (
                <CustomFieldsSummaryChip
                    contextList={customfieldContext}
                    values={filterValues.itemCustomFields}
                    onResetFilter={onResetFilter}
                />
            );
        }
    }), [defaultFilters, filterValues, onResetFilter]);

    return (
        showSummary &&
        <Box
            sx={{
                mt: isToolbarShown ? 0 : 1.5,
                mb: 1.5,
                ml: 2,
                flexGrow: 1,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center'
            }}
        >
            {filterList.map((filter, index) => {

                let JSXFilter: JSX.Element = <></>;

                switch (filter.type) {
                    case 'TextField':
                    case 'Select':
                    case 'Slider':
                    case 'InputRange':
                    case 'SingleSwitch':
                    case 'SwitchGroup':
                    case 'Autocomplete':
                        JSXFilter = summaryComponents.Single(filter.name, filter.label, filter.type, filter.nameId, filter.options) ?? <></>;
                        break;
                    case 'TagsAutocomplete':
                    case 'MultiSelect':
                        JSXFilter = summaryComponents.Multiple(filter.name, filter.label, filter.nameId, filter.options) ?? <></>;
                        break;
                }

                return <Box key={filter.name + "." + index}>{JSXFilter}</Box>;
            })}

            {(customfieldContext && customfieldContext.length > 0) && summaryComponents.CustomFields(customfieldContext)}

            {(itemCustomfieldContext && itemCustomfieldContext.length > 0) && summaryComponents.ItemCustomFields(itemCustomfieldContext)}

            <Button
                color="error"
                size="small"
                onClick={onResetAll}
                startIcon={<Iconify icon={'ic:round-clear-all'} />}
            >
                {translate("commons.clear")}
            </Button>

        </Box>
    );
}

