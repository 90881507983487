import { Avatar, Box, Card, Typography } from '@mui/material';
import { useNavigate, } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label from 'src/components/label';
import { isEqual } from 'lodash';
import { SidebarListFilters, SingleGridElementProps, ToolbarListFilters } from 'src/@types/list';
import { PublicShopFilters, DEFAULT_PUBLIC_SHOP_FILTERS, PublicShopSearchResult } from 'src/@types/webshop';
import { webshopOperations } from 'src/redux/webshop';
import { setWebshopFiltersInUrl } from 'src/redux/webshop/webshop-slices';
import DateZone from 'src/appComponents/DateZone';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList'; import Markdown from 'src/components/markdown';
import { MarkdownStyle } from 'src/sections/@dashboard/inbox/detail/InboxDetail';
import { manageAmountFilters } from 'src/utils/list/utils/functions';

export default function PublicWebshopList() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isWebshopLoading, publicWebshopList, webshopTotalCount, webshopFiltersInUrl, webshopsFromBundle } = useSelector((state: RootState) => state.webshop);

    const [filters, setFilters] = useState<PublicShopFilters>(DEFAULT_PUBLIC_SHOP_FILTERS);

    const statsKeysToDelete: string[] = ["status"];

    const fullKeysToDelete: string[] = ["bundleAll"];

    //---- FUNCTION TO USE IN "UPDATE FILTERS IN URL" ----//
    const updateCheckField = useCallback((field: string, filtersToCheck: PublicShopFilters) =>
        ((filtersToCheck[field] || typeof filtersToCheck[field] === "boolean" || typeof filtersToCheck[field] === "number") && field !== "customer")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_PUBLIC_SHOP_FILTERS[field])
        , []);

    //---- EXTRA FUNCTION TO USE BEFORE SEARCH ----//
    const extraSearchFiltersChecks = useCallback((searchFilters: PublicShopFilters) => {
        if (searchFilters.min && searchFilters.max) {
            if (!searchFilters.dateField) searchFilters.dateField = DEFAULT_PUBLIC_SHOP_FILTERS.dateField;
        } else {
            if (searchFilters.dateField) searchFilters.dateField = undefined;
        }

        return searchFilters;
    }, []);

    //---- FUNCTION TO USE TO ADAPT FILTERS BEFORE UPDATING FILTERS IN URL ----//
    const updateInUrlFiltersChecks = useCallback((filters: PublicShopFilters) => {
        if (filters.minPrice && filters.maxPrice) return manageAmountFilters("minPrice", "maxPrice", filters);

        return filters;
    }, []);

    //---- CUSTOM SEARCH FUNCTION ----//
    const customSearch = useCallback((params: { filters: PublicShopFilters & { sequenceToken: string | null }, check: boolean }) => {
        if (params.filters.bundleAll) {
            let newFilters = { ...params.filters, name: params.filters.bundleAll, size: 100 };

            delete newFilters.bundleAll;

            dispatch(webshopOperations.searchPublicShopsByBundle({ ...params, filters: newFilters, webshopsFromBundle }));
        }
        else dispatch(webshopOperations.searchPublicShops(params));
    }, [webshopsFromBundle]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: SidebarListFilters[] = useMemo(() => [
        {
            name: "name",
            label: translate(`webshop.list.filters.webshopName`),
            type: "TextField"
        },
        {
            name: "partReferenceSection",
            label: translate(`request.partReference`),
            type: "Section"
        },
        {
            name: "partReference",
            label: translate(`webshop.list.filters.partReference`),
            type: "TextField"
        },
        {
            name: "partDescription",
            label: translate(`spareParts.list.tableHeaders.partDesc`),
            type: "TextField"
        },
        {
            name: "supplierFamily",
            label: translate(`spareParts.list.tableHeaders.supplierFamily`),
            type: "TextField"
        },
        {
            name: "price",
            label: translate(`webshop.list.filters.priceList`),
            type: "InputRange"
        }
    ], [translate]);

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarListFilters[] = useMemo(() =>
        [
            { key: 'all', label: translate('role.type.webShop') },
            { key: 'bundleAll', label: translate('webshop.detail.products.single') }
        ], [translate]);

    //---- HANDLE TABLE START ----//
    const [actualRow, setActualRow] = useState<GridCellParams<PublicShopSearchResult> | null>(null);

    const handleCellClick = (params: GridCellParams<PublicShopSearchResult>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.webshopPublic.detail(params.row.id));
        }
    };

    const COLUMNS: GridColDef<PublicShopSearchResult>[] = useMemo(() => [
        {
            field: 'title',
            headerName: translate('commons.title'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
        },
        {
            field: 'startDate',
            headerName: translate('webshop.list.columns.publishing.start'),
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.startDate)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'endDate',
            headerName: translate('webshop.list.columns.publishing.end'),
            flex: isDesktop ? 1.2 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.endDate)}
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'featured',
            headerName: ``,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {

                return obj.row.featured && (
                    <Label
                        color={"warning"}
                        variant={"filled"}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.featured`)}
                    </Label>
                );
            }
        }
    ], [translate, isDesktop]);
    //---- HANDLE TABLE END ----//

    return (
        <InfiniteScrollGenericList
            list={publicWebshopList}
            isLoading={isWebshopLoading}
            totalCount={webshopTotalCount}
            defaultFilters={DEFAULT_PUBLIC_SHOP_FILTERS}
            specificStatsKeysToDelete={statsKeysToDelete}
            specificFullKeysToDelete={fullKeysToDelete}
            datagridColumns={COLUMNS}
            updateCheckField={updateCheckField}
            context={"PublicWebshop"}
            setActualRow={setActualRow}
            handleCellClick={handleCellClick}
            setFiltersCallback={setFilters}
            //search={webshopOperations.searchPublicShops}
            customSearchFunc={customSearch}
            filtersInUrl={webshopFiltersInUrl}
            setFiltersInUrl={setWebshopFiltersInUrl}
            listDescription={translate('webshop.subtitle.public')}
            resetList={() => { }}
            multipleView={{
                renderGridElement: SingleGridElement,
                rowNumber: 2
            }}
            //------- filter section
            datesGeneralFilter={{
                showDates: true
            }}
            extraSearchFiltersChecks={extraSearchFiltersChecks}
            filtersInSidebar={filtersInSidebar}
            updateInUrlFiltersChecks={updateInUrlFiltersChecks}
            toolbarFiltersList={toolbarFiltersList}
        />
    );
}

//-----------------------------------------------------------------------------

function SingleGridElement({ key, item, isDesktop, translate, navigate }: SingleGridElementProps<PublicShopSearchResult>) {

    const getAvatarText = (name: string) => {

        if (name.includes("[")) {
            return name.split("]")[1][1];
        }

        return name[0];
    };

    const avatarMediaUrl = item.media?.find((media) => media.section === "profile")?.fileInfo[0].url ?? "";

    const coverMediaUrl = item.media?.find((media) => media.section === "cover")?.fileInfo[0].url ?? "";

    return (
        <Card
            key={key}
            sx={{
                p: 3,
                transition: "0.5s",
                cursor: 'pointer',
                ':hover': {
                    bgcolor: (theme) => theme.palette.action.selected
                },
                width: "100%",
                height: "260px",
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 1)), url(${coverMediaUrl || "/assets/stellantis-europe/008.webp"})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}
            onClick={() => {
                if (!window.getSelection()?.toString())
                    navigate(PATH_DASHBOARD.webshopPublic.detail(item.id));
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 4.5 }}>
                <Avatar
                    sx={{
                        width: 70,
                        height: 70,
                        bgcolor: (theme) => theme.palette.primary.light,
                        color: (theme) => theme.palette.getContrastText(theme.palette.primary.light),
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: 'common.white'
                    }}
                    src={avatarMediaUrl}
                >
                    {getAvatarText(item.id)}
                </Avatar>

                {item.featured &&
                    <Label
                        color={"warning"}
                        variant={"filled"}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`statuses.featured`)}
                    </Label>
                }

            </Box>

            <Box sx={{ display: 'flex', flexDirection: "column", color: "white" }}>

                {item.endDate &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? 'row' : "column",
                            width: '100%',
                            gap: 0.5
                        }}
                    >
                        <Typography variant="caption">
                            {translate('webshop.detail.expiringOn')}
                        </Typography>
                        <DateZone
                            variant="caption"
                            date={new Date(item.endDate)}
                            noSeconds
                            shortMonth
                        />
                    </Box>
                }

                <Typography variant="h5" sx={{ mt: 1, mb: -1 }}>
                    {item.title}
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2
                    }}
                >
                    <MarkdownStyle>
                        <Markdown children={item.shortDescription ? item.shortDescription : "—"} />
                    </MarkdownStyle>
                </Typography>
            </Box>
        </Card>
    );
}