import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoriesSearchResult, ProductStatistic, SparePart, SparePartSearchResult, Statistic } from "src/@types/spareParts";
import sparePartsOperations from "./spareParts-operations";
import { ErrorResponse } from "src/@types/commons";
import { GenericPagedResponse } from "src/@types/list";

interface SparePartReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isStatisticsLoading: boolean,
    sparePart: SparePart | null,
    isDetailLoading: boolean,
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    sparePartsList: SparePartSearchResult[],
    statistics: Statistic | null,
    logsLoading: boolean,
    sparePartLogs: GenericPagedResponse<any>,
    productStatistics: ProductStatistic,
    sparePartsPowerSearch: SparePartSearchResult[],
    customerSparePartsPowerSearch: SparePartSearchResult[],
    isMySparePartPowersearchLoading: boolean,
    isCustomerSparePartPowersearchLoading: boolean,
    filtersInUrl: string,
    categories: GenericPagedResponse<CategoriesSearchResult>
};

const initialState: SparePartReducer = {
    error: null,
    isLoading: false,
    isDetailLoading: false,
    isStatisticsLoading: false,
    isMySparePartPowersearchLoading: false,
    isCustomerSparePartPowersearchLoading: false,
    sparePartsPowerSearch: [],
    customerSparePartsPowerSearch: [],
    sparePart: null,
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    sparePartsList: [],
    statistics: null,
    logsLoading: false,
    sparePartLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    productStatistics: {
        vehiclesNumbers: 0,
        sparePartsNumbers: 0
    },
    filtersInUrl: "",
    categories: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
};

const SparePartsSlice = createSlice({
    name: 'sparePart',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(sparePartsOperations.getSparePart.fulfilled, (state, action) => {
                state.sparePart = action.payload;
                state.isDetailLoading = false;
            })
            .addCase(sparePartsOperations.getSparePart.rejected, (state, action) => {
                state.sparePart = initialState.sparePart;
                state.isDetailLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.searchSpareParts.fulfilled, (state, action) => {
                const response = action.payload;

                state.sparePartsList = response.items;
                state.pageIndex = response.pageIndex;
                state.pageSize = response.pageSize;
                state.totalCount = response.totalCount;
                state.isLoading = false;
            })
            .addCase(sparePartsOperations.searchSpareParts.rejected, (state, action) => {
                state.sparePartsList = initialState.sparePartsList;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(sparePartsOperations.searchStatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(sparePartsOperations.searchStatistics.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.isLoading = false;
            })
            .addCase(sparePartsOperations.getSparePartLogs.rejected, (state, action) => {
                state.logsLoading = false;
                state.error = action.error.message as string;
                state.sparePartLogs = initialState.sparePartLogs;
            })
            .addCase(sparePartsOperations.getSparePartLogs.fulfilled, (state, action) => {
                state.logsLoading = false;
                state.sparePartLogs = action.payload;
            })
            .addCase(sparePartsOperations.sparePartsPowerSearch.rejected, (state, action) => {
                state.isMySparePartPowersearchLoading = false;
                state.sparePartsPowerSearch = initialState.sparePartsPowerSearch;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.sparePartsPowerSearch.fulfilled, (state, action) => {
                state.isMySparePartPowersearchLoading = false;
                state.sparePartsPowerSearch = action.payload.items;
            })
            .addCase(sparePartsOperations.getProductStatistics.fulfilled, (state, action) => {
                state.isStatisticsLoading = false;
                state.productStatistics = action.payload;
            })
            .addCase(sparePartsOperations.getProductStatistics.rejected, (state, action) => {
                state.isStatisticsLoading = false;
                state.productStatistics = initialState.productStatistics;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.searchRulesCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.categories = initialState.categories;
                state.error = action.error.message as string;
            })
            .addCase(sparePartsOperations.searchRulesCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.isLoading = false;
            });
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startDetailLoading(state) {
            state.isDetailLoading = true;
        },
        resetSparePart(state) {
            state.sparePart = null;
        },
        startLogsLoading(state) {
            state.logsLoading = true;
        },
        startStatisticsLoading(state) {
            state.isStatisticsLoading = true;
        },
        startMyPowerSearchLoading(state) {
            state.isMySparePartPowersearchLoading = true;
        },
        startCustomerPowerSearchLoading(state) {
            state.isCustomerSparePartPowersearchLoading = true;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET SPARE PARTS LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        }
    }
});

export const {
    startLoading,
    resetSparePart,
    startLogsLoading,
    startStatisticsLoading,
    startCustomerPowerSearchLoading,
    startMyPowerSearchLoading,
    startDetailLoading,
    resetPageIndexSize,
    setFiltersInUrl
} = SparePartsSlice.actions;

export default SparePartsSlice.reducer;
