import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_USER_STATISTICS, EnableDisableSliceProps, UserGroupsSearchResult, UserManager, UserSearchResult, UserStatistics } from "src/@types/user";
import usersOperations from "./users-operations";
import { ErrorResponse } from "src/@types/commons";
import { DEFAULT_LOGS_DATA, LogsItem } from "src/@types/logs";
import { GenericPagedResponse } from "src/@types/list";

export type UserState = {
    /* COMMONS */
    error: ErrorResponse,
    isLoading: boolean,
    filtersInUrl: string,
    /* USER LIST */
    userList: UserSearchResult[],
    statistics: UserStatistics,
    isResetPasswordSuccess: boolean,
    totalCount: number,
    pageSize: number,
    pageIndex: number,
    /* USER */
    user: UserManager | null,
    userLogs: GenericPagedResponse<LogsItem>,
    isUserLogsLoading: boolean,
    /* GROUPS LIST */
    groupsList: UserGroupsSearchResult[],
    isGroupsLoading: boolean,
    /* POWERSEARCH */
    matrixFilterUsersSearch: UserSearchResult[],
    isUserSearchLoading: boolean,
    usersPowersearch: UserSearchResult[],
    isUserPowersearchLoading: boolean,
    providers: Record<string, string>
};

const initialState: UserState = {
    /* COMMONS */
    error: null,
    isLoading: false,
    /* USER LIST */
    userList: [],
    statistics: DEFAULT_USER_STATISTICS,
    isResetPasswordSuccess: false,
    totalCount: 0,
    pageIndex: 0,
    pageSize: 10,
    /* USER */
    user: null,
    userLogs: DEFAULT_LOGS_DATA,
    isUserLogsLoading: false,
    /* GROUPS LIST */
    groupsList: [],
    isGroupsLoading: false,
    /* POWERSEARCH */
    matrixFilterUsersSearch: [],
    isUserSearchLoading: false,
    usersPowersearch: [],
    isUserPowersearchLoading: false,
    filtersInUrl: "",
    providers: {}
};

const UsersSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(usersOperations.createUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.createUser.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(usersOperations.getUserById.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoading = false;
            })
            .addCase(usersOperations.getUserById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.user = null;
            })
            .addCase(usersOperations.enableDisableUserOp.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.associateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.associateUser.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(usersOperations.userSetAdminRoles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.userSetAdminRoles.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(usersOperations.searchUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalCount = action.payload.totalCount;
                state.userList = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
            })
            .addCase(usersOperations.searchUsers.rejected, (state, action) => {
                state.userList = initialState.userList;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isLoading = false;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
            })
            .addCase(usersOperations.updateUser.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(usersOperations.updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.searchUsersPowersearch.pending, (state) => {
                state.isUserPowersearchLoading = true;
            })
            .addCase(usersOperations.searchUsersPowersearch.fulfilled, (state, action) => {
                state.isUserPowersearchLoading = false;
                state.usersPowersearch = action.payload.items as UserSearchResult[];
            })
            .addCase(usersOperations.searchUsersPowersearch.rejected, (state, action) => {
                state.isUserPowersearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.getUsersMatrixFilter.fulfilled, (state, action) => {
                state.isUserSearchLoading = false;
                state.matrixFilterUsersSearch = action.payload.items as UserSearchResult[];
            })
            .addCase(usersOperations.getUsersMatrixFilter.rejected, (state, action) => {
                state.isUserSearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(usersOperations.getUserLogs.fulfilled, (state, action) => {
                state.userLogs = action.payload;
                state.isUserLogsLoading = false;
            })
            .addCase(usersOperations.getUserLogs.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isUserSearchLoading = false;
            })
            .addCase(usersOperations.getStatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
            })
            .addCase(usersOperations.getStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.statistics = initialState.statistics;
            })
            .addCase(usersOperations.getProviders.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.providers = initialState.providers;
            })
            .addCase(usersOperations.getProviders.fulfilled, (state, action) => {
                state.providers = action.payload;
            })
            .addCase(usersOperations.searchUserGroups.fulfilled, (state, action) => {
                state.groupsList = action.payload.items;
                state.isGroupsLoading = false;
            })
            .addCase(usersOperations.searchUserGroups.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.groupsList = initialState.groupsList;
                state.isGroupsLoading = false;
            })
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startMatrixFilterUserLoading(state) {
            state.isUserSearchLoading = true;
        },
        startPowerSearchLoading(state) {
            state.isUserPowersearchLoading = true;
        },
        startUserLogsLoading(state) {
            state.isUserLogsLoading = true;
        },
        startGroupsLoading(state) {
            state.isGroupsLoading = true;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        // USER
        clearUser(state) {
            state.user = null;
        },
        resetPasswordSuccess(state) {
            state.isResetPasswordSuccess = true;
            state.isLoading = false;
        },
        removeResetPasswordSuccess(state) {
            state.isResetPasswordSuccess = false;
        },
        //CLEAR LIST
        clearPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableUser(state, action: PayloadAction<EnableDisableSliceProps>) {

            const { id, tab, newItem, action: payloadAction } = action.payload;

            const index = state.userList.findIndex((user) => user.id === id);

            state.userList[index].enabled = payloadAction === "enable";

            if (tab !== "") {
                state.userList.splice(index, 1);

                state.totalCount -= 1;

                if (newItem) state.userList.push(newItem);
                else if (state.userList.length === 0 && state.pageIndex > 0) {
                    state.pageIndex -= 1;
                }
            }

            if (payloadAction === "enable") {
                state.statistics.disabled -= 1;

                state.statistics.enabled += 1;
            } else {
                state.statistics.disabled += 1;

                state.statistics.enabled -= 1;
            }
        },
    }
});

export const {
    startLoading,
    startMatrixFilterUserLoading,
    startPowerSearchLoading,
    startUserLogsLoading,
    clearUser,
    resetPasswordSuccess,
    removeResetPasswordSuccess,
    clearPageIndexSize,
    setFiltersInUrl,
    enableDisableUser,
    startGroupsLoading
} = UsersSlice.actions;

export default UsersSlice.reducer;
