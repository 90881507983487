import { GroupSearchResult } from "src/@types/group";
import { FileWithResizeInfo, ImageInModalType } from "src/@types/media";
import { Media } from "src/@types/request";
import { GroupShop } from "src/@types/webshop";

type FIleModal = ImageInModalType & File;

export function parseGroup(group: GroupShop): GroupSearchResult {

    return {
        groupId: group.groupId,
        displayName: group.groupName,
        enabled: true,
        numberOfOrganizations: group.numberOfOrganizationInGroups,
        numberOfUsers: group.numberOfUserInGroups,
        createdOn: "",
        groupType: "None",
        organizationId: "",
        status: "Active"
    };
}

export function parseFiles<T extends File>(media?: Media[], isCover?: boolean): T[] {

    const files: T[] = media?.filter(i => i.section === (isCover ? "cover" : "profile")).map((image) => {
        const f = new File([], image.mediaId ?? "");

        Object.assign(f, { preview: image.fileInfo[0].url ?? "", aspect: 1 });

        return f as T;
    }) ?? [];

    return files;
}

export function concatFilterImages(coverImages: FIleModal[], ProfileImages: FIleModal[], previousImages: File[]): FileWithResizeInfo[] {

    const parsedImages = coverImages
        .map(i =>
            Object.assign(i, {
                section: 'cover',
                imageResizeParameters: i.crop ? {
                    width: i.originalWidth,
                    height: i.originalHeight,
                    cropInfo: i.crop
                } : null
            }))
        .concat(
            ProfileImages.map(i =>
                Object.assign(i, {
                    section: 'profile',
                    imageResizeParameters: i.crop ? {
                        width: i.originalWidth,
                        height: i.originalHeight,
                        cropInfo: i.crop
                    } : null
                })))
        .filter((image, index) => {

            const prevImage = previousImages[index];

            //for edit, check if is a new image and if it is different from the already uploaded ones
            return image.size > 0 && (!prevImage || prevImage.name !== image.name || prevImage.size !== image.size);
        });

    return parsedImages as FileWithResizeInfo[];

}