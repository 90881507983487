import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoriesFilter, SparePartsFilters } from "src/@types/spareParts";
import { startDetailLoading, startLoading, startLogsLoading, startStatisticsLoading } from "./spareParts-slices";
import sparePartsServices from "src/services/sparePartsServices";

const searchStatistics = createAsyncThunk(
    'spareParts/searchStatistics',
    async (filters: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchStatistics(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchSpareParts = createAsyncThunk(
    'spareParts/searchSpareParts',
    async (filters: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.search(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const sparePartsPowerSearch = createAsyncThunk(
    'spareParts/sparePartsPowerSearch',
    async (filters: SparePartsFilters, { rejectWithValue }) => {
        try {
            const { data } = await sparePartsServices.search(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getSparePart = createAsyncThunk(
    'spareParts/getSparePart',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startDetailLoading());
        try {
            const { data } = await sparePartsServices.detail(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getSparePartLogs = createAsyncThunk(
    'spareParts/getSparePartsLog', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await sparePartsServices.searchLogs(options.id, options.filters);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getProductStatistics = createAsyncThunk(
    'spareParts/getProductStatistic', async (organizationId: string, { rejectWithValue, dispatch }) => {
        dispatch(startStatisticsLoading());
        try {

            const { data } = await sparePartsServices.productStatistic(organizationId);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    });

const searchRulesCategories = createAsyncThunk(
    'spareParts/searchRulesCategories',
    async (options: CategoriesFilter, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchCategories(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        }
        catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const sparePartsOperations = {
    searchSpareParts,
    searchStatistics,
    getSparePart,
    getSparePartLogs,
    getProductStatistics,
    sparePartsPowerSearch,
    searchRulesCategories
};

export default sparePartsOperations;
