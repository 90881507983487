import { useDropzone } from 'react-dropzone';
import { Box, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Iconify from '../iconify';
import AvatarPreview from './preview/AvatarPreview';
import { FileWithPreview } from 'src/@types/media';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import CropRotateIcon from '@mui/icons-material/CropRotate';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '50%',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
}));

const StyledPlaceholder = styled('div')(({ theme }) => ({
  zIndex: 7,
  display: 'flex',
  borderRadius: '50%',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: `calc(100% - 16px)`,
  height: `calc(100% - 16px)`,
  color: theme.palette.text.disabled,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

interface UploadAvatarNewProps {
  file: FileWithPreview[],
  onEdit?: () => void,
  onRemove?: () => void,
  onUploadImage?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  helperText?: React.ReactNode
}

export default function UploadAvatarNew({ file, onEdit, onRemove, onUploadImage, helperText }: UploadAvatarNewProps) {

  const { translate } = useLocales();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: { 'image/*': [] },
    maxSize: 5000000,
    minSize: 102400
  });

  return (
    <>
      {onEdit && (file.length > 0) &&
        <Box sx={{ position: 'absolute', top: 20, right: 25 }}>
          <IconButton
            aria-label="edit"
            size="large"
            sx={{ padding: '5px', cursor: "pointer" }}
            onClick={() => onEdit()}
          >
            <CropRotateIcon />
          </IconButton>
        </Box>
      }

      {onRemove && (file.length > 0) &&
        < Box sx={{ position: 'absolute', top: 20, left: 25 }}>
          <IconButton
            aria-label="edit"
            size="large"
            sx={{ padding: '5px', cursor: "pointer" }}
            onClick={() => onRemove()}
          >
            <DeleteIcon />
          </IconButton>
        </Box >
      }

      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...((file.length > 0) && {
            '&:hover': {
              '& .placeholder': {
                opacity: 1
              },
            },
          })
        }}
      >
        <input type="file" {...getInputProps()} onChange={onUploadImage} />

        {(file.length > 0) && <AvatarPreview file={file[0]} />}

        <StyledPlaceholder
          className="placeholder"
          sx={{
            '&:hover': {
              opacity: 0.72
            },
            ...((file.length > 0) && {
              zIndex: 9,
              opacity: 0,
              color: 'common.white',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64)
            })
          }}
        >
          <Iconify icon="ic:round-add-a-photo" width={24} sx={{ mb: 1 }} />

          <Typography variant="caption">{translate(`uploader.avatar.${file ? 'upload' : 'change'}`)}</Typography>
        </StyledPlaceholder>

      </StyledDropZone>

      {helperText && helperText}
    </>
  );
}
