import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { alpha, Box, Button, Card, LinearProgress, Modal, Tooltip, Typography } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useNavigate } from "react-router";
import { Level } from "src/@types/webshop";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";

interface WebshopStepButtonsProps {
    changeStep?: Dispatch<SetStateAction<number>>,
    confirm?: boolean,
    save?: boolean,
    onSave?: (showModal?: boolean) => void,
    disabled?: boolean,
    loading?: boolean
}

export function WebshopStepButtons({ changeStep, confirm, disabled, loading, onSave, save }: WebshopStepButtonsProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                mt: 1,
                flexDirection: isDesktop ? 'row' : 'column-reverse'
            }}
        >
            <Button variant="soft" sx={{ borderRadius: '100px' }} onClick={() => setIsOpen(true)}>
                {translate('webshop.messages.quit')}
            </Button>

            <QuitModal isOpen={isOpen} toggle={() => setIsOpen(false)} />
            <Box sx={{ ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'defaul' : 'column-reverse' }}>

                {save &&
                    <LoadingButton
                        onClick={() => {
                            if (onSave)
                                onSave(true);
                        }}
                        variant="outlined"
                        sx={{ borderRadius: '100px' }}
                        loading={loading}
                        startIcon={<Save />}
                    >
                        {translate("webshop.form.saveDraft")}
                    </LoadingButton>}

                {/* <SaveTemplateModal isOpen={isSaveOpen} toggle={toggleSaveModal} onAccept={createMatrixtemplate} /> */}
                {!!changeStep &&
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => changeStep(p => p - 1)}
                    >
                        {`${translate('commons.previous')}`}
                    </Button>}

                {!confirm ? (
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '100px' }}

                        onClick={() => {
                            if (changeStep)
                                changeStep(p => p + 1);
                            if (onSave)
                                onSave();
                        }}
                        disabled={disabled}
                    >
                        {`${translate('commons.next')}`}
                    </Button>
                ) : (
                    <LoadingButton
                        variant="contained"
                        sx={{ borderRadius: '100px' }}
                        disabled={disabled}
                        loading={loading}
                        onClick={() => {
                            if (onSave)
                                onSave(true);
                        }}
                    >
                        {`${translate('commons.create')}`}
                    </LoadingButton>
                )}
            </Box>
        </Box>);
}

interface QuitModalProps {
    isOpen: boolean,
    toggle: VoidFunction,
}

function QuitModal({ isOpen, toggle }: QuitModalProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'lg');

    const navigate = useNavigate();

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2
                }}
            >
                <Box sx={{ textAlign: "center", mb: 1 }}>

                    <Typography variant="h3">{`${translate('commons.quit')}`}</Typography>

                    <Typography variant="body1" sx={{ my: 3 }}>
                        {translate('webshop.messages.quitMsg')}
                    </Typography>

                    <Button
                        variant="soft"
                        size={isDesktop ? "small" : "medium"}
                        onClick={toggle}
                        sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>
                    <Button
                        variant="contained"
                        size={isDesktop ? "small" : "medium"}
                        sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                        onClick={() => navigate(PATH_DASHBOARD.webshop.list)}
                    >
                        {translate('webshop.messages.quit')}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}

interface GreyCounterProps {
    label: string
}

export function GreyCounter({ label }: GreyCounterProps) {
    return (
        <Box sx={{
            width: '100%',
            height: "50px",
            borderRadius: '12px',
            bgcolor: (theme) => theme.palette.background.neutral,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
        }}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.disabled }}>{label}</Typography>
        </Box>
    );
}

const normalise = (value: number, max: number, min: number = 0) => ((value - min) * 100) / (max - min);

interface DiscountThresholdProgressBarProps {
    thresholds: Level[],
    currentAmount: number
}

export function DiscountThresholdProgressBar({ currentAmount, thresholds }: DiscountThresholdProgressBarProps) {

    const { currentLang } = useLocales();

    const max = thresholds[thresholds.length - 1].fromAmount;

    const { currency: currentCurrency } = useSettingsContext();

    return (
        <Box sx={{ position: 'relative', my: 3 }}>
            <LinearProgress
                variant="determinate"
                value={normalise(currentAmount, max)}
                sx={{
                    height: 20,
                    borderRadius: 5,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': { backgroundColor: t => t.palette.primary.light, borderRadius: '100px' }
                }}
            />

            {thresholds.map((threshold) => (
                <Tooltip key={threshold.level} title={threshold.additionalDiscountPercentage} placement="top">
                    <Box
                        sx={{

                            position: 'absolute',
                            left: `${Math.min(normalise(threshold.fromAmount, max) + 1, 100)}%`,
                            top: -22,
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <Typography
                            sx={{ mb: 6 }}
                            variant="caption"
                            color="textSecondary"
                        >
                            {threshold.additionalDiscountPercentage + "%"}
                        </Typography>
                        <Box
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: '100%',
                                backgroundColor: (t) => currentAmount >= threshold.fromAmount ? t.palette.primary.dark : t.palette.grey[600],
                                mt: 0.5,
                                mx: 0.5
                            }}
                        />
                        {/*                  <CurrencyAdapter
                            sx={{ mt: 3, mr: 'auto', }}
                            variant="caption"
                            color="textSecondary"
                            currency={CurrencyTypes.EUR}
                            value={threshold.fromAmount}
                        /> */}
                        <Typography variant="caption" color="textSecondary" sx={{ mt: 3, mr: 'auto' }}>
                            {threshold.fromAmount.toLocaleString(currentLang.locale.code,
                                {
                                    style: "currency",
                                    currency: currentCurrency.label,
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 0
                                })
                            }
                        </Typography>
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
}

interface StatisticBoxProps {
    circleColor: string,
    Icon: ReactNode,
    label: string,
    value?: ReactNode
}

export function StatisticBox({ circleColor, Icon, label, value }: StatisticBoxProps) {

    const isDesktop = useResponsive('up', 'md');

    return (
        <Card sx={{
            display: 'flex',
            p: 3,
            width: isDesktop ? '33%' : '100%',
            alignContent: 'center',
            gap: 2,
            flexDirection: { sm: 'row', md: 'column', lg: 'row' }
        }}
        >
            <Box >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="24" cy="24" r="24" fill={circleColor} />
                    <foreignObject x="12" y="12" width="24" height="24">
                        {Icon}
                    </foreignObject>
                </svg>
            </Box>
            <Box sx={{ display: "flex", flexDirection: 'column', mr: 3 }}>
                <Typography fontSize={{ md: '0.765rem' }} variant="subtitle2">{label}</Typography>
                {value}
            </Box>

        </Card>
    );
}

