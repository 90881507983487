import { Container, Step, StepLabel, Stepper } from "@mui/material";
import { cloneDeep } from "lodash";
import { useReducer, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GroupSearchResult } from "src/@types/group";
import { BlackWhiteList, DEFAULT_NEW_SHOP, NewShop, Shop } from "src/@types/webshop";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";
import { webshopOperations } from "src/redux/webshop";
import { PATH_DASHBOARD } from "src/routes/paths";
import CustomerStep from "./CustomerStep";
import DetailStep from "./DetailStep";
import DiscountModelStep from "./DiscountModelStep";
import StockAvailabilityStep from "./StockAvailabilityStep";
import TermsConditionStep from "./TermsConditionStep";
import { handleUpload } from "src/appUtils/UploadHandler";
import LoadingScreen from "src/appComponents/loading-screen";
import { FileWithPreview, FileWithSection, ImageInModalType } from "src/@types/media";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { CurrencyTypes } from "src/@types/vehicle";
import nullifyEmptyKeys from "src/utils/nullifyEmptyKeys";
import { parseGroup, parseFiles, concatFilterImages } from "./webshopFormUtils";

interface WebshopWizardProps {
    webshop?: Shop
}

type Action = {
    value: Partial<NewShop>
    type: 'update' | 'reset'
};

function reduce(state: NewShop, payload: Action) {
    switch (payload.type) {
        case 'update':
            return { ...state, ...payload.value };
        case 'reset':
            return { ...DEFAULT_NEW_SHOP, ...payload.value };
        default:
            return state;
    }
}

const STEPS = [
    "webshop.form.step.details",
    "webshop.form.step.customers",
    "webshop.form.step.discountModels",
    "webshop.form.step.stockAvailability",
    "webshop.form.step.terms",
    "webshop.form.step.complete",
];

function isOlderThan75Years(isoDateString: string | null): boolean {
    const inputDate = new Date(isoDateString ? isoDateString : new Date());

    const now = new Date();

    const seventyFiveYearsAgo = new Date();

    seventyFiveYearsAgo.setFullYear(now.getFullYear() - 75);

    return inputDate < seventyFiveYearsAgo;
}

export default function WebshopWizard({ webshop }: WebshopWizardProps) {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');

    const { currency } = useSettingsContext();

    const { rates } = useCurrency();

    const [step, setStep] = useState(0);

    const { id, webshopId } = useParams();

    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(id ?? webshopId);

    const [shop, setShop] = useReducer(reduce, (webshop as NewShop | undefined) ?? DEFAULT_NEW_SHOP, (arg) => {

        //fix first yup check
        const clone = cloneDeep(arg);

        if (isEdit) {
            clone.longDescription.forEach((v) => v.normalText = v.text);
            clone.shortDescription.forEach((v) => v.normalText = v.text);

            clone.shopAccess.groupIds = webshop?.shopAccess.groups.map(g => g.groupId) ?? [];

            clone.maxBackorderQuantity = webshop?.backorderQuantity ?? null;

            clone.permanent = !isOlderThan75Years(webshop?.endDate ?? null);
        }

        return clone;
    });

    const [groupList, setGroupList] = useState<GroupSearchResult[]>(() => {
        if (isEdit && webshop) {
            return webshop.shopAccess.groups.map((group) => parseGroup(group)) as GroupSearchResult[];
        }

        return [];
    });

    const previousImages = useRef<File[]>([]);

    const [profileCover, setProfileCover] = useState<(ImageInModalType & File)[]>(() => parseFiles<ImageInModalType & File>(webshop?.media, false));

    const [profilePreview, setProfilePreview] = useState<FileWithPreview[]>(() => parseFiles<FileWithPreview>(webshop?.media));

    const [coverImages, setCoverImages] = useState<(ImageInModalType & File)[]>(() => parseFiles<(ImageInModalType & File)>(webshop?.media, true));

    const [coverImagesPreview, setCoverImagesPreview] = useState<FileWithPreview[]>(() => parseFiles<FileWithPreview>(webshop?.media, true));

    const [productFile, setProductFile] = useState<FileWithSection[]>([]);

    const [imageUploadLoading, setImageUploadLoading] = useState(false);

    const submit = async (partialShop?: Partial<NewShop>, showModal?: boolean) => {

        let out = cloneDeep({ ...shop, ...(partialShop ?? {}) }) as Record<string, any>;

        if (partialShop)
            setShop({ type: 'update', value: partialShop });

        let idShop = isEdit ?? "";

        //set section and cropinfo 
        const images = concatFilterImages(coverImages, profileCover, previousImages.current);

        //replace empty string with null
        out = nullifyEmptyKeys(out);

        //send in eur
        if ((out as NewShop).discountModel?.multiLevel?.thresholds) {
            (out as NewShop).discountModel?.multiLevel?.thresholds.forEach((level) => {

                level.toAmount = +convert(level.toAmount, currency.label, CurrencyTypes.EUR, rates).toFixed(2);
                level.fromAmount = +convert(level.fromAmount, currency.label, CurrencyTypes.EUR, rates).toFixed(2);
            });
        }

        try {

            if (!isEdit) {
                idShop = (await dispatch(webshopOperations.createShop(out as NewShop)).unwrap()).id;
                setIsEdit(idShop);
                navigate(PATH_DASHBOARD.webshop.draft(idShop));
            }
            else {
                await dispatch(webshopOperations.updateShop({ webShop: out as NewShop, id: isEdit })).unwrap();
            }

            if (images.length > 0 && step === 0) {
                if (showModal)
                    setImageUploadLoading(true);
                await handleUpload(images, idShop, "Shop", (v) => setImageUploadLoading(v));
                previousImages.current = images;
            }

            if (productFile.length > 0 && step === 3) {
                await handleUpload(productFile, idShop, 'Shop', undefined, 'Bundle');
            }

            if (step === 4 || showModal)
                dispatch(setSuccessMessage({ text: translate('webshop.messages.webshopSuccess'), returnTo: PATH_DASHBOARD.webshop.list }));
        }
        catch {
            setImageUploadLoading(false);
        }
    };

    const handleUploaBundle = async (showModal?: boolean) => {
        if (productFile.length > 0 && step === 3 && isEdit)
            handleUpload(productFile, isEdit, 'Shop', undefined, 'Bundle');

        if (showModal)
            submit(undefined, showModal);
    };

    const handleLists = (lists: {
        groupList: GroupSearchResult[],
        blackList: BlackWhiteList[],
        whiteList: BlackWhiteList[]
    }, showModal?: boolean) => {
        setGroupList(lists.groupList);

        const shopAccess = { shopAccess: { blackList: lists.blackList, whiteList: lists.whiteList, groupIds: lists.groupList.map(v => v.groupId) } };

        submit(shopAccess, showModal);
    };

    const imagesCarouselProps = {
        carousel: coverImages,
        setCarousel: setCoverImages,
        carouselPreview: coverImagesPreview,
        setCarouselPreview: setCoverImagesPreview,
        profileCover: profileCover,
        setProfileCover: setProfileCover,
        profilePreview: profilePreview,
        setProfilePreview: setProfilePreview
    };

    const STEPS_COMPONENT = [
        <DetailStep
            key={0}
            webshopStatus={webshop?.status}
            changeStep={(n) => setStep(n)}
            onSubmit={submit}
            state={shop}
            {...imagesCarouselProps}
        />,
        <CustomerStep
            key={1}
            changeStep={setStep}
            onSubmit={handleLists}
            groupList={groupList}
            blackWhiteLists={{ black: shop?.shopAccess.blackList ?? [], white: shop?.shopAccess.whiteList ?? [] }}
        />,
        <DiscountModelStep
            key={2}
            changeStep={setStep}
            onSubmit={submit}
            state={shop}
        />,
        <StockAvailabilityStep
            key={3}
            changeStep={setStep}
            onFileChange={(f) => setProductFile(f)}
            stock={productFile}
            onSubmit={handleUploaBundle}
        />,
        <TermsConditionStep
            key={4}
            state={shop}
            changeStep={setStep}
            onSubmit={submit}
            onChange={setShop}
        />
    ];

    return (
        <>
            {imageUploadLoading ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={!isEdit ? translate("menu.management.webshop.admin.new") : translate("menu.management.webshop.admin.edit")}
                        links={[
                            { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                            { name: translate("menu.management.webshop.admin.title"), href: PATH_DASHBOARD.webshop.list },
                            { name: isEdit ? translate(`commons.edit`) : translate(`commons.new`) },
                        ]}
                    />
                    <Stepper
                        sx={{ mb: 2, px: isDesktop ? 5 : 2, }}
                        activeStep={step}
                        alternativeLabel
                    >
                        {STEPS.map((st) => (
                            <Step key={st}>
                                <StepLabel>{translate(st)}</StepLabel>
                            </Step>
                        ))}

                    </Stepper>

                    {STEPS_COMPONENT[step]}

                </Container>}
        </>
    );
}